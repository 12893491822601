import { WeedGridCellArg } from "@/atoms/weedsAtoms";
import { ColumnFilterOption } from "@/components/filtered-search-box/filter-search-box-types";
import {
	GridSizeEnum,
	WeedAlertEnum,
	WeedsVegetationClassDto,
} from "@/lib/gen/eis";

export const WEED_GRID_CELL_FILTER_OPTION_GRID_SIZE: ColumnFilterOption<WeedGridCellArg> =
	{
		key: "gridSize",
		label: "Grid Size",
		icon: "grid_toggle",
		options: Object.values(GridSizeEnum).map((value) => ({
			value,
		})),
		type: "enum",
		keywords: ["result"],
	};
export const WEED_GRID_CELL_FILTER_OPTION_WEED_ALERT: ColumnFilterOption<WeedGridCellArg> =
	{
		key: "weedAlert",
		label: "Weed Alert",
		icon: "weeds",
		options: Object.values(WeedAlertEnum).map((value) => ({
			value,
		})),
		type: "enum",
	};

export function getWeedGridCellDynamicFilterVegClassCover(
	vegClass: WeedsVegetationClassDto,
): ColumnFilterOption<WeedGridCellArg> {
	return {
		key: "vegClassCoverQuery",
		subKey: vegClass.id,
		label: `${vegClass.name} %`,
		icon: "circle_filled",
		iconColor: vegClass.displayColour ?? undefined,
		type: "percentage",
		group: "Classification Covers",
	};
}

export const WEED_GRID_CELL_FILTER_OPTIONS: ColumnFilterOption<WeedGridCellArg>[] =
	[
		WEED_GRID_CELL_FILTER_OPTION_GRID_SIZE,
		WEED_GRID_CELL_FILTER_OPTION_WEED_ALERT,
	];
