import client from "@kubb/plugin-client/client";
import type { RiparianSystemGetRiparianSpatialQueryResponse, RiparianSystemGetRiparianSpatialQueryParams } from "../../ts/RiparianSystemGetRiparianSpatial.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/RiparianSystem/spatial}
 */
export async function riparianSystemGetRiparianSpatial(params?: RiparianSystemGetRiparianSpatialQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<RiparianSystemGetRiparianSpatialQueryResponse, Error, unknown>({ method: "GET", url: `/api/RiparianSystem/spatial`, params, ...config });
    return res.data;
}