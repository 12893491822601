import client from "@kubb/plugin-client/client";
import type { TopsoilStockpilesGetGeometriesQueryResponse, TopsoilStockpilesGetGeometriesQueryParams } from "../../ts/TopsoilStockpilesGetGeometries.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/TopsoilStockpiles/stockpileGeometries}
 */
export async function topsoilStockpilesGetGeometries(params?: TopsoilStockpilesGetGeometriesQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<TopsoilStockpilesGetGeometriesQueryResponse, Error, unknown>({ method: "GET", url: `/api/TopsoilStockpiles/stockpileGeometries`, params, ...config });
    return res.data;
}