import client from "@kubb/plugin-client/client";
import type { SiteInspectionsGetSiteInspectionIdsMutationRequest, SiteInspectionsGetSiteInspectionIdsMutationResponse } from "../../ts/SiteInspectionsGetSiteInspectionIds.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/SiteInspections/getids}
 */
export async function siteInspectionsGetSiteInspectionIds(data: SiteInspectionsGetSiteInspectionIdsMutationRequest, config: Partial<RequestConfig<SiteInspectionsGetSiteInspectionIdsMutationRequest>> = {}) {
    const res = await client<SiteInspectionsGetSiteInspectionIdsMutationResponse, Error, SiteInspectionsGetSiteInspectionIdsMutationRequest>({ method: "POST", url: `/api/SiteInspections/getids`, data, ...config });
    return res.data;
}