import { useAtomValue } from "jotai";
import { lazy, Suspense, useMemo } from "react";

import { Perm_Weeds_Std, UserPermissionsAtom } from "@/atoms/authAtoms";
import { CurrentViewAtom } from "@/atoms/eisViewAtoms";
import { SelectedWeedGridCellIdAtom } from "@/atoms/weedsAtoms";
import {
	GetCardComponent,
	LazyCardComponent,
} from "@/components/card-components";

const WeedsSummaryCard = {
	component: lazy(() => import("@/components/Weeds/WeedsSummaryCard")),
	permission: Perm_Weeds_Std,
} as const satisfies LazyCardComponent;

export const LazyWeedsSummaryCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(WeedsSummaryCard, userPerms);
		if (comp) {
			if (currentView?.name === "Weeds") return comp;
		}
		return null;
	}, [currentView?.name, userPerms]);

	return (
		<Suspense fallback={null}>
			{Component && <Component id={"WeedsSummaryCard"} />}
		</Suspense>
	);
};

const WeedsGridCellVegCoversCard = {
	component: lazy(
		() => import("@/components/Weeds/WeedsGridCellVegCoversCard"),
	),
	permission: Perm_Weeds_Std,
} as const satisfies LazyCardComponent;

export const LazyWeedsGridCellVegCoversCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);
	const selectedWeedGridCellId = useAtomValue(SelectedWeedGridCellIdAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(WeedsGridCellVegCoversCard, userPerms);
		if (comp) {
			if (currentView?.name === "Weeds" && selectedWeedGridCellId)
				return comp;
		}
		return null;
	}, [currentView?.name, selectedWeedGridCellId, userPerms]);

	return (
		<Suspense fallback={null}>
			{Component && <Component id={"WeedsGridCellVegCoversCard"} />}
		</Suspense>
	);
};

const WeedsGridCellSummaryCard = {
	component: lazy(
		() => import("@/components/Weeds/WeedsGridCellSummaryCard"),
	),
	permission: Perm_Weeds_Std,
} as const satisfies LazyCardComponent;

export const LazyWeedsGridCellSummaryCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);
	const selectedWeedGridCellId = useAtomValue(SelectedWeedGridCellIdAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(WeedsGridCellSummaryCard, userPerms);
		if (comp) {
			if (currentView?.name === "Weeds" && selectedWeedGridCellId)
				return comp;
		}
		return null;
	}, [currentView?.name, selectedWeedGridCellId, userPerms]);

	return (
		<Suspense fallback={null}>
			{Component && <Component id={"WeedsGridCellSummaryCard"} />}
		</Suspense>
	);
};
