import { atom } from "jotai";
import { atomEffect } from "jotai-effect";
import { atomWithQuery } from "jotai-tanstack-query";

import { ColumnFilter } from "@/components/filtered-search-box/filter-search-box-types";
import {
	Geometry,
	GetRiparianSurveyComparisonQuery,
	RiparianMetricDataTypeEnum,
	RiparianSurveyComparisonOrderByEnum,
	RiparianSurveyDto,
	riparianSurveyGetRiparianSpatialSurveyComparison,
	riparianSurveyGetRiparianSurveysWithPagination,
	RiparianSurveyOrderByEnum,
	riparianSystemGetRiparianSpatial,
	riparianSystemGetRiparianSystemMetrics,
	riparianSystemGetRiparianSystemZonesByExtent,
	RiparianSystemMetricListDto,
} from "@/lib/gen/eis";

import {
	Perm_Riparian_Std,
	UserHasPermissionAtom,
	UserHasPermissionsAtom,
} from "./authAtoms";
import { SelectAndZoomToFeatureAtom } from "./map/mapEventAtoms";
import { SelectedMineSiteIdAtom } from "./miningAtoms";

export const AutoSelectMetricAtomEffect = atomEffect((get, set) => {
	const selectedMetric = get(SelectedHealthMetricAtom);
	const structureOrCondition = get(StructureOrConditionSelectionAtom);
	const { data: metrics } = get(HealthMetricsAtom);

	const relevantMetrics = metrics?.filter(
		(m) => m.riparianMetricDataType === structureOrCondition,
	);
	if (relevantMetrics && relevantMetrics.length > 0) {
		if (selectedMetric) {
			const validMetric = relevantMetrics.find(
				(m) => m.metricId === selectedMetric.metricId,
			);
			if (validMetric) return; // We already have a valid metric selected
		}
		const nameMatched = relevantMetrics?.find(
			(m) => m.displayName === selectedMetric?.displayName,
		); // Match on name if we are switching between condition and structure
		set(SelectedHealthMetricAtom, nameMatched ?? relevantMetrics[0]);
	}
});

export const HealthMetricsAtom = atomWithQuery((get) => {
	const mineSiteId = get(SelectedMineSiteIdAtom);
	const userPerms = get(UserHasPermissionsAtom);
	return {
		queryKey: ["healthMetrics", mineSiteId],
		queryFn: async () => {
			return await riparianSystemGetRiparianSystemMetrics({
				MineSiteId: mineSiteId,
			});
		},
		enabled: !!mineSiteId && userPerms[Perm_Riparian_Std],
	};
});

export const HealthZonesQueryAtom = atomWithQuery((get) => {
	const userPerms = get(UserHasPermissionsAtom);
	return {
		queryKey: ["healthZones"],
		queryFn: async () => {
			return await riparianSystemGetRiparianSystemZonesByExtent({});
		},
		enabled: userPerms[Perm_Riparian_Std],
	};
});

export const SelectedHealthMetricAtom = atom<
	RiparianSystemMetricListDto | undefined
>(undefined);

export const StructureOrConditionSelectionAtom = atom(
	RiparianMetricDataTypeEnum.Percent,
);

export const SortByValueOrChangeAtom = atom(
	RiparianSurveyComparisonOrderByEnum.MetricSurveyT1,
);

export const SelectedHealthZoneIdAtom = atom<string | undefined>(undefined);

export type HealthGridCellComparison = {
	locationId: string;
	locationName: string;
	geometry: Geometry;
	survey1Result: number | undefined;
	survey2Result: number | undefined;
	colourHexString: string | undefined;
};

export const HealthGridCellsUpdateAtomEffect = atomEffect((get, set) => {
	const { data: gridCellGeometries } = get(_healthGridCellsQueryAtom);
	const { data: gridCellComparisons } = get(
		HealthGridCellsColourComparisonQueryAtom,
	);
	if (gridCellComparisons?.grids != null) {
		const gridComparisons = gridCellComparisons.grids;
		const results = gridCellGeometries?.flatMap((g) => {
			if (!g.locationName || !g.geometry) return [];
			const comparison = gridComparisons[g.locationId];
			if (
				!comparison ||
				comparison.survey1Result == null ||
				!comparison.colourHexString
			)
				return [];

			return [
				{
					locationId: g.locationId,
					locationName: g.locationName,
					geometry: g.geometry,
					survey1Result: comparison.survey1Result,
					survey2Result: comparison.survey2Result ?? undefined,
					colourHexString: comparison.colourHexString,
				},
			];
		});
		set(HealthGridCellsComparisonAtom, results ?? []);
	} else {
		set(HealthGridCellsComparisonAtom, []);
		set(SelectedHealthGridCellIdAtom, undefined);
	}
});

export const HealthGridCellsComparisonAtom = atom<HealthGridCellComparison[]>(
	[],
);

const _healthGridCellsQueryAtom = atomWithQuery((get) => {
	const mineSiteId = get(SelectedMineSiteIdAtom);
	const userPerms = get(UserHasPermissionsAtom);

	return {
		queryKey: ["healthGridCells", mineSiteId],
		queryFn: async () => {
			return await riparianSystemGetRiparianSpatial({
				MineSiteId: mineSiteId,
			});
		},
		enabled: !!mineSiteId && userPerms[Perm_Riparian_Std],
	};
});

export const HealthGridCellsColourComparisonQueryAtom = atomWithQuery((get) => {
	const survey1 = get(HealthSurvey1Atom);
	const survey2 = get(HealthSurvey2Atom);
	const metric = get(SelectedHealthMetricAtom);
	const userPerms = get(UserHasPermissionsAtom);

	return {
		queryKey: ["healthGridCells", metric?.metricId, survey1, survey2],
		queryFn: async () => {
			return await riparianSurveyGetRiparianSpatialSurveyComparison({
				MetricId: metric?.metricId,
				RiparianSurvey1Id: survey1?.id,
				RiparianSurvey2Id: survey2?.id,
			});
		},
		enabled:
			userPerms[Perm_Riparian_Std] && !!metric?.metricId && !!survey1,
	};
});

export const SelectedHealthGridCellIdAtom = atom<string | undefined>(undefined);

const _healthSurveysAtom = atom<RiparianSurveyDto[] | undefined>(undefined);
export const HealthSurveysAtom = atom(
	(get) => {
		const surveys = get(_healthSurveysAtom);
		return surveys;
	},
	async (get, set) => {
		const mineSiteId = get(SelectedMineSiteIdAtom);
		const comparisonMode = get(HealthComparisonModeAtom);
		const userHasPermission = get(UserHasPermissionAtom(Perm_Riparian_Std));

		if (mineSiteId && userHasPermission) {
			const surveys =
				await riparianSurveyGetRiparianSurveysWithPagination({
					MineSiteId: mineSiteId,
					PageSize: -1,
					IsRemoteSensingSurvey: true,
					OrderBy: RiparianSurveyOrderByEnum.RemoteSensingDate,
					Descending: true,
				});

			set(_healthSurveysAtom, surveys.items ?? []);

			if (surveys?.items && surveys.items.length === 0) {
				set(HealthSurvey1Atom, undefined);
				set(HealthSurvey2Atom, undefined);
			}

			if (surveys?.items && surveys.items.length > 0) {
				set(HealthSurvey1Atom, surveys.items[0]);
			}
			if (comparisonMode && surveys?.items && surveys.items.length > 1) {
				set(HealthSurvey2Atom, surveys.items[1]);
			}
			if (comparisonMode && surveys?.items && surveys.items.length <= 1) {
				set(HealthComparisonModeAtom, false);
			}
		} else {
			set(_healthSurveysAtom, []);
		}
	},
);

export const HealthSurvey1Atom = atom<RiparianSurveyDto | undefined>(undefined);
export const HealthSurvey2Atom = atom<RiparianSurveyDto | undefined>(undefined);

const _healthComparisonModeAtom = atom<boolean>(true);
export const HealthComparisonModeAtom = atom(
	(get) => {
		const comparisonMode = get(_healthComparisonModeAtom);
		return comparisonMode;
	},
	async (_get, set, value: boolean) => {
		set(_healthComparisonModeAtom, value);
		if (value === false) {
			set(HealthSurvey2Atom, undefined);
		}
	},
);

export const GoToRiparianZone = atom(null, async (get, set, id: string) => {
	const healthZonesQuery = get(HealthZonesQueryAtom);

	const zone = healthZonesQuery.data?.find((rz) => rz.id === id);
	if (zone == null) return;
	set(SelectedHealthZoneIdAtom, zone.id);
	set(SelectAndZoomToFeatureAtom, id, "healthzone");
});

export const GoToRiparianGrid = atom(null, async (get, set, id: string) => {
	const healthGridCellQuery = get(_healthGridCellsQueryAtom);

	const gridCell = healthGridCellQuery.data?.find(
		(rz) => rz.locationId === id,
	);
	if (gridCell == null) return;
	set(SelectedHealthGridCellIdAtom, gridCell.locationId);
	set(SelectAndZoomToFeatureAtom, id, "healthgrid");
});

export type HealthGridArg = keyof GetRiparianSurveyComparisonQuery;
export const HealthGridFiltersAtom = atom<ColumnFilter<HealthGridArg>[]>([]);

export type HealthZoneArg = keyof GetRiparianSurveyComparisonQuery;
export const HealthZoneFiltersAtom = atom<ColumnFilter<HealthZoneArg>[]>([]);
