import { useAtomValue, useSetAtom } from "jotai";
import { useCallback } from "react";

import { DrawerTabEnum } from "@/atoms/bottomDrawerAtoms.ts";
import {
	poppedOutTabsAtom,
	removePoppedOutTabAtom,
} from "@/atoms/poppedOutAtoms";
import RainTempGraphCard from "@/components/BottomDrawer/Climate/RainTempGraphCard/RainTempGraph.tsx";
import ErosionFeaturesTableCard from "@/components/BottomDrawer/Erosion/ErosionFeaturesTableCard";
import HealthGridTable from "@/components/BottomDrawer/Health/HealthGridTable";
import HealthVegGraphCard from "@/components/BottomDrawer/Health/HealthVegGraph/health-veg-graph";
import HealthZoneTable from "@/components/BottomDrawer/Health/HealthZoneTable";
import AnaloguePolygonsTableCard from "@/components/BottomDrawer/Monitoring/AnaloguePolygonsTableCard";
import MonitoringScheduleTableCard from "@/components/BottomDrawer/Monitoring/MonitoringScheduleTableCard";
import ObservationSamplingSpeciesTableCard from "@/components/BottomDrawer/Monitoring/ObservationSamplingSpeciesTableCard";
import RehabPolygonsTableCard from "@/components/BottomDrawer/Monitoring/RehabPolygonsTableCard";
import RehabPolyVegGraphCard from "@/components/BottomDrawer/Monitoring/RehabPolyVegGraphCard";
import SamplingSiteObservationsTableCard from "@/components/BottomDrawer/Monitoring/SamplingSiteObservationsTableCard";
import SamplingSiteTableCard from "@/components/BottomDrawer/Monitoring/SamplingSiteTableCard";
import SiteInspectionTableCard from "@/components/BottomDrawer/Monitoring/SiteInspectionTableCard";
import { SeedApplicationAllocateDrawer } from "@/components/BottomDrawer/Seeds/seed-application/allocate-stage";
import { SeedApplicationPlanDrawer } from "@/components/BottomDrawer/Seeds/seed-application/plan-stage";
import SeedApplicationCard from "@/components/BottomDrawer/Seeds/seed-application-list/seed-application-table";
import SeedInventoryCard from "@/components/BottomDrawer/Seeds/SeedInventoryCard/seed-inventory-table";
import WeedApplicationsTableCard from "@/components/BottomDrawer/Weeds/WeedApplicationsTableCard";
import WeedGridCellTableCard from "@/components/BottomDrawer/Weeds/WeedGridCellTableCard";
import PopoutWindow from "@/components/PoppedOutWindows/popout-window.tsx";

interface WindowConfig {
	width: number;
	height: number;
	title: string;
}

// Configuration for each window type
const WINDOW_CONFIGS = {
	[DrawerTabEnum.REHAB_POLYS]: {
		width: 1200,
		height: 800,
		title: "Rehabilitation Polygons",
	},
	[DrawerTabEnum.ANALOGUE_POLYS]: {
		width: 1200,
		height: 800,
		title: "Analogue Polygons",
	},
	[DrawerTabEnum.SITE_INSPECTIONS]: {
		width: 1200,
		height: 800,
		title: "Site Inspections",
	},
	[DrawerTabEnum.SAMPLING_SITES]: {
		width: 1200,
		height: 800,
		title: "Sampling Sites",
	},
	[DrawerTabEnum.SAMPLING_SITES_OBSERVATIONS]: {
		width: 1200,
		height: 800,
		title: "Sampling Site Observations",
	},
	[DrawerTabEnum.OBSERVATION_SAMPLING_SPECIES]: {
		width: 1200,
		height: 800,
		title: "Observation Sampling Species",
	},
	[DrawerTabEnum.MONITORING_SCHEDULE]: {
		width: 1000,
		height: 600,
		title: "Monitoring Schedule",
	},
	[DrawerTabEnum.RAIN_AND_TEMP]: {
		width: 1000,
		height: 600,
		title: "Rain and Temperature",
	},
	[DrawerTabEnum.EROSION_FEATURE]: {
		width: 1200,
		height: 800,
		title: "Erosion Features",
	},
	[DrawerTabEnum.VEGETATION_COVER]: {
		width: 1000,
		height: 600,
		title: "Vegetation Cover",
	},
	[DrawerTabEnum.SEED_INVENTORY]: {
		width: 1200,
		height: 800,
		title: "Seed Inventory",
	},
	[DrawerTabEnum.SEED_APP]: {
		width: 1200,
		height: 800,
		title: "Seed Applications",
	},
	[DrawerTabEnum.SEED_APP_PLAN]: {
		width: 1200,
		height: 800,
		title: "Seed Application Plan",
	},
	[DrawerTabEnum.SEED_APP_ALLOCATE]: {
		width: 1200,
		height: 800,
		title: "Seed Application Allocate Seeds",
	},
	[DrawerTabEnum.HEALTH_COMPARISON_TABLE]: {
		width: 1200,
		height: 800,
		title: "Health Comparison",
	},
	[DrawerTabEnum.HEALTH_ZONE_COMPARISON_TABLE]: {
		width: 1200,
		height: 800,
		title: "Health Zone Comparison",
	},
	[DrawerTabEnum.HEALTH_VEGETATION_COVER]: {
		width: 1200,
		height: 800,
		title: "Plant Health Vegetation Cover",
	},
	[DrawerTabEnum.WEED_GRID_TABLE]: {
		width: 1200,
		height: 800,
		title: "Weed Grid",
	},
	[DrawerTabEnum.WEED_APPLICATION_TABLE]: {
		width: 1200,
		height: 800,
		title: "Weed Applications",
	},
	[DrawerTabEnum.WEED_VEGETATION_COVER]: {
		width: 1200,
		height: 800,
		title: "Weed Vegetation Cover",
	},
	[DrawerTabEnum.TOPSOIL]: {
		width: 1200,
		height: 800,
		title: "Topsoil",
	},
} as const satisfies Record<DrawerTabEnum, WindowConfig>;

export const GetComponentForTab = (tabType: DrawerTabEnum) => {
	switch (tabType) {
		case DrawerTabEnum.REHAB_POLYS:
			return <RehabPolygonsTableCard />;
		case DrawerTabEnum.ANALOGUE_POLYS:
			return <AnaloguePolygonsTableCard />;
		case DrawerTabEnum.SITE_INSPECTIONS:
			return <SiteInspectionTableCard />;
		case DrawerTabEnum.SAMPLING_SITES:
			return <SamplingSiteTableCard />;
		case DrawerTabEnum.SAMPLING_SITES_OBSERVATIONS:
			return <SamplingSiteObservationsTableCard />;
		case DrawerTabEnum.OBSERVATION_SAMPLING_SPECIES:
			return <ObservationSamplingSpeciesTableCard />;
		case DrawerTabEnum.MONITORING_SCHEDULE:
			return <MonitoringScheduleTableCard />;
		case DrawerTabEnum.RAIN_AND_TEMP:
			return <RainTempGraphCard />;
		case DrawerTabEnum.EROSION_FEATURE:
			return <ErosionFeaturesTableCard />;
		case DrawerTabEnum.VEGETATION_COVER:
			return <RehabPolyVegGraphCard />;
		case DrawerTabEnum.SEED_INVENTORY:
			return <SeedInventoryCard />;
		case DrawerTabEnum.SEED_APP:
			return <SeedApplicationCard />;
		case DrawerTabEnum.SEED_APP_PLAN:
			return <SeedApplicationPlanDrawer />;
		case DrawerTabEnum.SEED_APP_ALLOCATE:
			return <SeedApplicationAllocateDrawer />;
		case DrawerTabEnum.HEALTH_COMPARISON_TABLE:
			return <HealthGridTable />;
		case DrawerTabEnum.HEALTH_ZONE_COMPARISON_TABLE:
			return <HealthZoneTable />;
		case DrawerTabEnum.HEALTH_VEGETATION_COVER:
			return <HealthVegGraphCard />;
		case DrawerTabEnum.WEED_GRID_TABLE:
			return <WeedGridCellTableCard />;
		case DrawerTabEnum.WEED_APPLICATION_TABLE:
			return <WeedApplicationsTableCard />;
		default:
			return null;
	}
};

const PoppedOutWindows: React.FC = () => {
	const poppedOutTabs = useAtomValue(poppedOutTabsAtom);
	const removeTab = useSetAtom(removePoppedOutTabAtom);

	const handleClose = useCallback(
		(tabType: DrawerTabEnum) => {
			removeTab(tabType);
		},
		[removeTab],
	);

	return (
		<>
			{Array.from(poppedOutTabs).map((tabType) => {
				const config = WINDOW_CONFIGS[tabType];
				return (
					<PopoutWindow
						key={tabType}
						onClose={() => handleClose(tabType)}
						width={config.width}
						height={config.height}
						title={config.title}
						minHeight={600}
						minWidth={800}
					>
						{GetComponentForTab(tabType)}
					</PopoutWindow>
				);
			})}
		</>
	);
};

export default PoppedOutWindows;
