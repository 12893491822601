import client from "@kubb/plugin-client/client";
import type { RehabilitationPolygonsGetPaginatedMutationRequest, RehabilitationPolygonsGetPaginatedMutationResponse } from "../../ts/RehabilitationPolygonsGetPaginated.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/RehabilitationPolygons/paginated}
 */
export async function rehabilitationPolygonsGetPaginated(data: RehabilitationPolygonsGetPaginatedMutationRequest, config: Partial<RequestConfig<RehabilitationPolygonsGetPaginatedMutationRequest>> = {}) {
    const res = await client<RehabilitationPolygonsGetPaginatedMutationResponse, Error, RehabilitationPolygonsGetPaginatedMutationRequest>({ method: "POST", url: `/api/RehabilitationPolygons/paginated`, data, ...config });
    return res.data;
}