import { AxiosError } from "axios";
import { AlertCircle } from "lucide-react";
import { useMemo } from "react";

import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { ScrollArea } from "@/components/ui/scroll-area";

type ErrorObject = {
	title: string;
	detail: string;
	errors?: Record<string, string>;
};

const getErrorList = (error: Error | null | undefined) => {
	let title = "An unknown error has occured.";
	let errors: string[] = [];
	if (!(error instanceof AxiosError)) {
		return {
			title,
			errors,
		};
	}
	if (!error.response?.data) {
		return {
			title,
			errors,
		};
	}
	const obj = error.response.data as ErrorObject;
	if (obj.detail) {
		errors = obj.detail.split(",");
	} else if (obj.errors) {
		errors = Object.values(obj.errors);
	}
	if (obj.title) {
		title = obj.title;
	}
	return {
		title,
		errors,
	};
};

export const FormErrors = ({ error }: { error: Error | null | undefined }) => {
	const errors = useMemo(() => {
		return getErrorList(error);
	}, [error]);

	if (!error) return null;

	return (
		<Alert variant="destructive">
			<AlertCircle className="size-4" />
			<AlertTitle>{errors.title}</AlertTitle>
			<AlertDescription className="flex max-h-16 overflow-hidden">
				<ScrollArea>
					<ul>
						{errors.errors.map((e) => (
							<li className="" key={e}>
								{e}
							</li>
						))}
					</ul>
				</ScrollArea>
			</AlertDescription>
		</Alert>
	);
};
