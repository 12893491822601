import { Accordion } from "@/components/ui/accordion";

import {
	AddSpeciesButton,
	LifeformAccordionItem,
	TargetLifeformAndCoverHeader,
} from "./lifeform-accordion-item";
import { LifeformPlanTable } from "./lifeform-plan-table";
import { LifeformArray } from "./lifeforms";

export const SeedApplicationPlanDrawer = () => {
	return (
		<div className="flex max-h-full flex-1 flex-col gap-1 overflow-hidden p-3 pr-0">
			<Accordion
				type="multiple"
				className="flex flex-col gap-2 overflow-y-scroll pr-1"
				defaultValue={LifeformArray}
			>
				{LifeformArray.map((lf) => (
					<LifeformAccordionItem
						key={lf}
						lf={lf}
						headerChildren={
							<TargetLifeformAndCoverHeader lf={lf} />
						}
						buttonChildren={<AddSpeciesButton lf={lf} />}
						className="p-2"
					>
						<LifeformPlanTable lf={lf} />
					</LifeformAccordionItem>
				))}
			</Accordion>
		</div>
	);
};
