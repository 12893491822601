import { LifeformEnum } from "@/lib/gen/eis";

type LifeformClassesType = {
	text: string;
	border: string;
	thickBorder: string;
	background: string;
	childBackground: string;
};

const TreeClasses = {
	text: "text-lifeform-tree",
	border: "border-lifeform-tree/20",
	thickBorder: "border-lifeform-tree",
	background: "bg-module-monitoring-primary/5",
	childBackground: "bg-module-monitoring-primary/[0.01]",
} as const satisfies LifeformClassesType;

const ShrubClasses = {
	text: "text-lifeform-shrub",
	border: "border-lifeform-shrub/20",
	thickBorder: "border-lifeform-shrub",
	background: "bg-lifeform-shrub/5",
	childBackground: "bg-lifeform-shrub/[0.01]",
} as const satisfies LifeformClassesType;

const HummockClasses = {
	text: "text-lifeform-hummock",
	border: "border-lifeform-hummock/20",
	thickBorder: "border-lifeform-hummock",
	background: "bg-lifeform-hummock/5",
	childBackground: "bg-lifeform-hummock/[0.01]",
} as const satisfies LifeformClassesType;

const TussockClasses = {
	text: "text-lifeform-tussock",
	border: "border-lifeform-tussock/20",
	thickBorder: "border-lifeform-tussock",
	background: "bg-lifeform-tussock/5",
	childBackground: "bg-lifeform-tussock/[0.01]",
} as const satisfies LifeformClassesType;

const HerbClasses = {
	text: "text-lifeform-herb",
	border: "border-lifeform-herb/20",
	thickBorder: "border-lifeform-herb",
	background: "bg-lifeform-herb/5",
	childBackground: "bg-lifeform-herb/[0.01]",
} as const satisfies LifeformClassesType;

const SedgeClasses = {
	text: "text-lifeform-sedge",
	border: "border-lifeform-sedge/20",
	thickBorder: "border-lifeform-sedge",
	background: "bg-lifeform-sedge/5",
	childBackground: "bg-lifeform-sedge/[0.01]",
} as const satisfies LifeformClassesType;

export const LifeformClasses = {
	Tree: TreeClasses,
	Shrub: ShrubClasses,
	"Hummock Grass": HummockClasses,
	"Other Grass": TussockClasses,
	Herb: HerbClasses,
	Sedge: SedgeClasses,
} as const satisfies Record<LifeformEnum, LifeformClassesType>;

export const LifeformArray = [
	LifeformEnum.Tree,
	LifeformEnum.Shrub,
	LifeformEnum.HummockGrass,
	LifeformEnum.OtherGrass,
	LifeformEnum.Herb,
	// LifeformEnum.Sedge,
] satisfies LifeformEnum[];
