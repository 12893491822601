import client from "@kubb/plugin-client/client";
import type { FloraSpeciesGetFloraSpeciesWithPaginationMutationRequest, FloraSpeciesGetFloraSpeciesWithPaginationMutationResponse } from "../../ts/FloraSpeciesGetFloraSpeciesWithPagination.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/FloraSpecies/paginated}
 */
export async function floraSpeciesGetFloraSpeciesWithPagination(data: FloraSpeciesGetFloraSpeciesWithPaginationMutationRequest, config: Partial<RequestConfig<FloraSpeciesGetFloraSpeciesWithPaginationMutationRequest>> = {}) {
    const res = await client<FloraSpeciesGetFloraSpeciesWithPaginationMutationResponse, Error, FloraSpeciesGetFloraSpeciesWithPaginationMutationRequest>({ method: "POST", url: `/api/FloraSpecies/paginated`, data, ...config });
    return res.data;
}