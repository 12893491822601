import client from "@kubb/plugin-client/client";
import type { RiparianSystemGetRiparianSystemMetricsQueryResponse, RiparianSystemGetRiparianSystemMetricsQueryParams } from "../../ts/RiparianSystemGetRiparianSystemMetrics.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/RiparianSystem/systemmetrics}
 */
export async function riparianSystemGetRiparianSystemMetrics(params?: RiparianSystemGetRiparianSystemMetricsQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<RiparianSystemGetRiparianSystemMetricsQueryResponse, Error, unknown>({ method: "GET", url: `/api/RiparianSystem/systemmetrics`, params, ...config });
    return res.data;
}