import client from "@kubb/plugin-client/client";
import type { MiningRegionsUpdateMutationRequest, MiningRegionsUpdateMutationResponse, MiningRegionsUpdatePathParams } from "../../ts/MiningRegionsUpdate.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/MiningRegions/:id}
 */
export async function miningRegionsUpdate(id: MiningRegionsUpdatePathParams["id"], data: MiningRegionsUpdateMutationRequest, config: Partial<RequestConfig<MiningRegionsUpdateMutationRequest>> = {}) {
    const res = await client<MiningRegionsUpdateMutationResponse, Error, MiningRegionsUpdateMutationRequest>({ method: "PUT", url: `/api/MiningRegions/${id}`, data, ...config });
    return res.data;
}