import client from "@kubb/plugin-client/client";
import type { WeedsSurveysUploadWeedPatchesMutationRequest, WeedsSurveysUploadWeedPatchesMutationResponse } from "../../ts/WeedsSurveysUploadWeedPatches.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/WeedsSurveys/patches}
 */
export async function weedsSurveysUploadWeedPatches(data?: WeedsSurveysUploadWeedPatchesMutationRequest, config: Partial<RequestConfig<WeedsSurveysUploadWeedPatchesMutationRequest>> = {}) {
    const formData = new FormData();
    if (data) {
        Object.keys(data).forEach((key) => {
            const value = data[key as keyof typeof data];
            if (typeof key === "string" && (typeof value === "string" || value instanceof Blob)) {
                formData.append(key, value);
            }
        });
    }
    const res = await client<WeedsSurveysUploadWeedPatchesMutationResponse, Error, WeedsSurveysUploadWeedPatchesMutationRequest>({ method: "POST", url: `/api/WeedsSurveys/patches`, data: formData, headers: { "Content-Type": "multipart/form-data", ...config.headers }, ...config });
    return res.data;
}