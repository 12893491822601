export enum MonitoringScheduleOrderByEnum {
    "Name" = "Name",
    "RehabilitatedDate" = "RehabilitatedDate",
    "Landform" = "Landform",
    "MineSite" = "MineSite",
    "MiningRegion" = "MiningRegion",
    "MonitoringScheduleMethod" = "MonitoringScheduleMethod",
    "Tag" = "Tag",
    "Criteria" = "Criteria",
    "Method" = "Method",
    "ErosionFeatures" = "ErosionFeatures",
    "ErosionMaxDepth" = "ErosionMaxDepth",
    "ErosionTotalArea" = "ErosionTotalArea",
    "ErosionTotalVolume" = "ErosionTotalVolume",
    "VegClassCoverDynamicColumn" = "VegClassCoverDynamicColumn",
    "CriterionResultDynamicColumn" = "CriterionResultDynamicColumn"
}

 export type MonitoringScheduleOrderBy = MonitoringScheduleOrderByEnum;