import client from "@kubb/plugin-client/client";
import type { SeedApplicationsGetSeedApplicationsSummaryQueryResponse, SeedApplicationsGetSeedApplicationsSummaryQueryParams } from "../../ts/SeedApplicationsGetSeedApplicationsSummary.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/Inventory/Seeds/Applications/summary}
 */
export async function seedApplicationsGetSeedApplicationsSummary(params?: SeedApplicationsGetSeedApplicationsSummaryQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<SeedApplicationsGetSeedApplicationsSummaryQueryResponse, Error, unknown>({ method: "GET", url: `/api/Inventory/Seeds/Applications/summary`, params, ...config });
    return res.data;
}