import { useAtom, useAtomValue } from "jotai";
import { lazy, Suspense, useState } from "react";

import { UserAtom } from "@/atoms/authAtoms";
import { CurrentViewAtom } from "@/atoms/eisViewAtoms";
import { HealthGridCellsUpdateAtomEffect } from "@/atoms/healthAtoms";
import { ImagerViewerImagesAtom } from "@/atoms/ImageViewerAtoms";
import { SetMapLayerStatesAtomEffect } from "@/atoms/map/layerVisibilityAtoms";
import { SelectedMineSiteIdAtom } from "@/atoms/miningAtoms";
import { MapZoomWeedGridSizeAtomEffect } from "@/atoms/weedsAtoms.ts";
import { CardPanelLeft } from "@/components/card-panel-left";
import { CardPanelRight } from "@/components/card-panel-right";
import { ScrollArea } from "@/components/ui/scroll-area";
import { TabsContent } from "@/components/ui/tabs";
import { cn } from "@/lib/utils.ts";

// Lazy load components
const BottomDrawer = lazy(
	() => import("@/components/BottomDrawer/bottom-drawer.tsx"),
);
const ConfigPage = lazy(() => import("@/components/ConfigCard"));
const MapOpenLayers = lazy(() => import("@/components/MapOpenLayers"));
const TimeSeriesSlider = lazy(() => import("@/components/TimeSeriesSlider"));
const RiparianTimeSlider = lazy(
	() => import("@/components/TimeSeriesSlider/RiparianTimeSlider"),
);
const WeedsTimeSlider = lazy(
	() => import("@/components/TimeSeriesSlider/WeedsTimeSlider"),
);
const TytonImageViewer = lazy(() => import("@/components/ui/imageViewer"));

export function MapView() {
	const currentView = useAtomValue(CurrentViewAtom);
	const mineSite = useAtomValue(SelectedMineSiteIdAtom);
	const user = useAtomValue(UserAtom);
	const images = useAtomValue(ImagerViewerImagesAtom);

	useAtom(SetMapLayerStatesAtomEffect);
	useAtom(HealthGridCellsUpdateAtomEffect);
	useAtom(MapZoomWeedGridSizeAtomEffect);

	const [colZIndex, setColZIndex] = useState<
		"" | "left" | "bottomdrawer" | "right"
	>("");

	return !user ? null : (
		<>
			<TabsContent value="mapView" className="mt-0 size-full">
				<Suspense fallback={null}>
					<MapOpenLayers />
				</Suspense>
				<div className="flex h-[calc(100vh-40px)] w-full justify-between gap-2 p-4 align-top">
					<div
						className={cn(
							"z-20 flex h-fit min-w-[280px] max-w-[280px] flex-col gap-2",
							colZIndex === "left" && "z-30",
						)}
						onMouseEnter={() => {
							setColZIndex("left");
						}}
					>
						<CardPanelLeft />
					</div>
					<div className="flex h-full w-[calc(100%-592px)] flex-col justify-between">
						<div className="z-10">
							{(currentView?.name === "Monitoring" ||
								currentView?.name === "Erosion") && (
								<Suspense fallback={null}>
									<TimeSeriesSlider />
								</Suspense>
							)}
							{!!mineSite && currentView?.name === "Weeds" && (
								<Suspense fallback={null}>
									<WeedsTimeSlider />
								</Suspense>
							)}
							{!!mineSite && currentView?.name === "Health" && (
								<Suspense fallback={null}>
									<RiparianTimeSlider />
								</Suspense>
							)}
						</div>
						<Suspense fallback={null}>
							<BottomDrawer
								className={
									colZIndex === "bottomdrawer"
										? "z-[21]"
										: "z-20"
								}
								onMouseEnter={() => {
									setColZIndex("bottomdrawer");
								}}
							/>
						</Suspense>
					</div>
					<ScrollArea
						className={cn(
							"z-20 flex h-fit max-h-full rounded bg-core-secondary-background hover:z-30",
							colZIndex === "right" && "z-30",
						)}
						onMouseEnter={() => {
							setColZIndex("right");
						}}
					>
						<CardPanelRight />
					</ScrollArea>
					{images && (
						<Suspense fallback={null}>
							<TytonImageViewer />
						</Suspense>
					)}
				</div>
			</TabsContent>
			<TabsContent value="config" className="mt-0">
				<Suspense fallback={null}>
					<ConfigPage />
				</Suspense>
			</TabsContent>
		</>
	);
}
