import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

import {
	ModuleChild,
	ModuleGroup,
} from "@/components/ConfigCard/ConfigDefinitions";
import { MineSiteSummaryDto } from "@/lib/gen/eis";

const _darkModeAtom = atomWithStorage("darkMode", false);
export const DarkModeAtom = atom(
	(get) => {
		return get(_darkModeAtom);
	},
	(get, set, value: boolean) => {
		if (get(_darkModeAtom) === value) {
			return;
		}

		set(_darkModeAtom, value);
	},
);

export const SelectedMineSiteAtom = atom<MineSiteSummaryDto | undefined>(
	undefined,
);
export const SelectedModuleGroupAtom = atom<ModuleGroup | undefined>(undefined);
export const SelectedSubModuleGroupAtom = atom<ModuleGroup | undefined>(
	undefined,
);
export const SelectedModuleChildAtom = atom<ModuleChild | undefined>(undefined);
