import { useInfiniteQuery } from "@tanstack/react-query";
import { ColumnDef, ColumnSort } from "@tanstack/react-table";
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect, useMemo, useState } from "react";

import { ActiveTabAtom, DrawerTabEnum } from "@/atoms/bottomDrawerAtoms";
import { SelectAndZoomToFeatureAtom } from "@/atoms/map/mapEventAtoms";
import {
	SelectedMineSiteAtom,
	SelectedMiningRegionAtom,
} from "@/atoms/miningAtoms";
import { SelectedSamplingSiteAtom } from "@/atoms/ongroundAtoms";
import {
	RehabPolyGeometriesAtom,
	SamplingSiteFiltersAtom,
	SamplingSiteObservationFiltersAtom,
	SelectedRehabPolygonAtom,
} from "@/atoms/rehabPolyAtoms";
import { BottomDrawDataTable } from "@/components/bottom-draw-data-table/bottom-draw-data-table";
import {
	DataTableCustomCell,
	DataTableNumberCell,
	DataTableSmallTextCell,
} from "@/components/data-table/data-table-cells";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header";
import { DataTableLocationPinCell } from "@/components/data-table/data-table-location-pin";
import { parseFilterAguments } from "@/components/filtered-search-box/parse-filter-arguments";
import { useInWindow } from "@/components/PoppedOutWindows";
import { Button } from "@/components/ui/button";
import { Tooltip } from "@/components/ui/tooltip";
import { autoSetFilter } from "@/helpers/filter-helper";
import { useFilters } from "@/helpers/filter-hook";
import {
	ComparatorEnum,
	SamplingSiteOrderBy,
	SamplingSitePagedDto,
	samplingSitesGetSamplingSitesPaginated,
} from "@/lib/gen/eis";
import {
	SAMPLING_SITE_FILTER_OPTION_MINE_SITE,
	SAMPLING_SITE_FILTER_OPTION_MINING_REGION_NAME,
	SAMPLING_SITE_FILTER_OPTION_REHAB_POLYGON_NAME,
	SAMPLING_SITE_FILTER_OPTIONS,
} from "@/types/filters/sampling-site-filters";

const SamplingSiteTableCard = () => {
	const selectFeature = useSetAtom(SelectAndZoomToFeatureAtom);
	const rehabPolys = useAtomValue(RehabPolyGeometriesAtom);
	const setActiveTab = useSetAtom(ActiveTabAtom);
	const [samplingSiteFilters, setSamplingSiteFilters] = useFilters(
		SamplingSiteFiltersAtom,
	);
	const setSamplingSiteObservationFilters = useSetAtom(
		SamplingSiteObservationFiltersAtom,
	);
	const setSamplingSite = useSetAtom(SelectedSamplingSiteAtom);

	const samplingSites = rehabPolys.flatMap((rp) => rp.quadrats ?? []);

	const [columnSort, setColumnSort] = useState<ColumnSort>({
		id: "SamplingSiteName",
		desc: false,
	});

	// Auto-set mine site filter
	const inWindow = useInWindow();
	const mineSite = useAtomValue(SelectedMineSiteAtom);
	useEffect(() => {
		if (inWindow) return;
		autoSetFilter(
			setSamplingSiteFilters,
			SAMPLING_SITE_FILTER_OPTION_MINE_SITE,
			mineSite?.mineSiteName,
		);
	}, [inWindow, mineSite?.mineSiteName, setSamplingSiteFilters]);
	const region = useAtomValue(SelectedMiningRegionAtom);
	useEffect(() => {
		if (inWindow) return;
		autoSetFilter(
			setSamplingSiteFilters,
			SAMPLING_SITE_FILTER_OPTION_MINING_REGION_NAME,
			region?.name,
		);
	}, [inWindow, region?.name, setSamplingSiteFilters]);

	// Auto-set rehab polygon filter on click polygon
	const selectedRehabPolygon = useAtomValue(SelectedRehabPolygonAtom);
	useEffect(() => {
		if (inWindow) return;
		autoSetFilter(
			setSamplingSiteFilters,
			SAMPLING_SITE_FILTER_OPTION_REHAB_POLYGON_NAME,
			selectedRehabPolygon?.name,
		);
	}, [inWindow, selectedRehabPolygon, setSamplingSiteFilters]);

	const {
		data,
		isLoading,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useInfiniteQuery({
		queryKey: ["SamplingSites", columnSort, samplingSiteFilters],
		queryFn: async ({ pageParam }) => {
			return samplingSitesGetSamplingSitesPaginated({
				pageNumber: pageParam,
				pageSize: 25,
				orderBy: columnSort.id as SamplingSiteOrderBy,
				descending: columnSort.desc,
				...parseFilterAguments(samplingSiteFilters),
			});
		},
		getNextPageParam: (lastPage) =>
			lastPage.pageIndex === lastPage.totalPages
				? lastPage.pageIndex
				: lastPage.pageIndex + 1,
		initialPageParam: 1,
	});
	const rows = useMemo(() => {
		return data
			? Object.values(data.pages.flatMap((p) => p.items ?? []))
			: [];
	}, [data]);
	const totalRows = useMemo(() => {
		if (!data || data.pages.length <= 0) return 0;
		return data.pages[0].totalCount;
	}, [data]);

	const columns = useMemo<ColumnDef<SamplingSitePagedDto, unknown>[]>(() => {
		return [
			{
				accessorKey: "id",
				enableGlobalFilter: true,
				header: () => <div />,
				cell: ({ row }) => {
					const ss = samplingSites.find(
						(rp) => rp.id === row.original.id,
					);
					return (
						<DataTableLocationPinCell
							disabled={ss == null}
							GoToLocation={() => {
								if (ss == null) return;
								setSamplingSite(ss);
								selectFeature(ss.id, "samplingsite");
							}}
						/>
					);
				},
				enableSorting: false,
				enableResizing: false,
				minSize: 26,
				maxSize: 26,
				meta: {
					title: "Location",
				},
			},
			{
				accessorKey: "samplingSiteName",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Name"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableCustomCell>
						<Tooltip tip={row.original.name}>
							<Button
								onClick={() => {
									setSamplingSiteObservationFilters([
										{
											filter: {
												key: "samplingSiteId",
												label: "Sampling Site",
												icon: "quadrat",
												type: "guid",
											},
											values: [
												{
													value: row.original.id,
													label:
														row.original.name ??
														row.original.id,
												},
											],
											comparator: ComparatorEnum.EQUALS,
										},
									]);
									setActiveTab({
										module: "Monitoring",
										name: DrawerTabEnum.SAMPLING_SITES_OBSERVATIONS,
									});
								}}
								variant="link"
								className="truncate px-0"
							>
								<span className="truncate">
									{row.original?.name}
								</span>
							</Button>
						</Tooltip>
					</DataTableCustomCell>
				),
				enableSorting: true,
				size: 80,
				meta: {
					title: "Name",
				},
			},
			{
				accessorKey: "mineSiteName",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Site"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell
						value={row.original?.mineSiteName ?? "-"}
					/>
				),
				enableSorting: true,
				size: 100,
				meta: {
					title: "Site",
				},
			},
			{
				accessorKey: "polygonName",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Polygon"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell
						value={row.original.polygonName ?? "-"}
					/>
				),
				enableSorting: true,
				size: 100,
				meta: {
					title: "Polygon",
				},
			},
			{
				accessorKey: "dateEstablished",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Date Established"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell
						value={new Date(
							row.original?.dateEstablished,
						).toLocaleDateString("en-AU", {
							year: "numeric",
							month: "short",
							day: "numeric",
						})}
					/>
				),
				enableSorting: true,
				size: 100,
				meta: {
					title: "Date Established",
				},
			},
			{
				accessorKey: "samplingMethod",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Sampling Method"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell
						value={row.original?.samplingMethod}
					/>
				),
				enableSorting: true,
				size: 100,
				meta: {
					title: "Sampling Method",
				},
			},
			{
				accessorKey: "lastSampled",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Last Sampled"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell
						value={
							row.original?.lastSampled
								? new Date(
										row.original?.lastSampled,
									).toLocaleDateString("en-AU", {
										year: "numeric",
										month: "short",
										day: "numeric",
									})
								: "-"
						}
					/>
				),
				enableSorting: true,
				size: 100,
				meta: {
					title: "Last Sampled",
				},
			},
			{
				accessorKey: "type",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Type"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell value={row.original?.type} />
				),
				enableSorting: true,
				size: 120,
			},
			{
				accessorKey: "slopeAspect",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Slope Aspect"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell
						value={row.original?.slopeAspect ?? "-"}
					/>
				),
				enableSorting: true,
				size: 80,
				meta: {
					title: "Slope Aspect",
				},
			},
			{
				accessorKey: "area",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Area (m²)"
						columnSortSetter={setColumnSort}
						rightAlign
					/>
				),
				cell: ({ row }) => (
					<DataTableNumberCell value={row.original?.area} />
				),
				enableSorting: true,
				size: 80,
			},
			{
				accessorKey: "samplingSiteLandformType",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Landform"
						columnSortSetter={setColumnSort}
					/>
				),
				cell: ({ row }) => (
					<DataTableSmallTextCell
						value={row.original?.samplingSiteLandformType ?? "-"}
					/>
				),
				enableSorting: true,
				size: 80,
				meta: {
					title: "Landform",
				},
			},
		];
	}, [
		samplingSites,
		selectFeature,
		setActiveTab,
		setSamplingSite,
		setSamplingSiteObservationFilters,
	]);

	return (
		<BottomDrawDataTable
			columns={columns ?? []}
			data={rows}
			totalRows={totalRows}
			title="Sampling Sites"
			moduleType="monitoring"
			icon="quadrat"
			isLoading={isLoading || columns == null || columns?.length <= 0}
			fetch={{
				isFetching,
				isFetchingNextPage,
				fetchNextPage,
				hasNextPage,
			}}
			exportContext="samplingsites"
			filteredSearchBox={{
				options: SAMPLING_SITE_FILTER_OPTIONS,
				filters: samplingSiteFilters,
				setFilters: setSamplingSiteFilters,
			}}
			tabType={DrawerTabEnum.SAMPLING_SITES}
		/>
	);
};

export default SamplingSiteTableCard;
