import client from "@kubb/plugin-client/client";
import type { RehabilitationPolygonsGetVegHistoryDatesQueryResponse, RehabilitationPolygonsGetVegHistoryDatesPathParams } from "../../ts/RehabilitationPolygonsGetVegHistoryDates.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/RehabilitationPolygons/:id/veghistorydates}
 */
export async function rehabilitationPolygonsGetVegHistoryDates(id: RehabilitationPolygonsGetVegHistoryDatesPathParams["id"], config: Partial<RequestConfig> = {}) {
    const res = await client<RehabilitationPolygonsGetVegHistoryDatesQueryResponse, Error, unknown>({ method: "GET", url: `/api/RehabilitationPolygons/${id}/veghistorydates`, ...config });
    return res.data;
}