import client from "@kubb/plugin-client/client";
import type { SeedApplicationsUpdateBatchAllocationMutationRequest, SeedApplicationsUpdateBatchAllocationMutationResponse } from "../../ts/SeedApplicationsUpdateBatchAllocation.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/Inventory/Seeds/Applications/batchAllocation}
 */
export async function seedApplicationsUpdateBatchAllocation(data: SeedApplicationsUpdateBatchAllocationMutationRequest, config: Partial<RequestConfig<SeedApplicationsUpdateBatchAllocationMutationRequest>> = {}) {
    const res = await client<SeedApplicationsUpdateBatchAllocationMutationResponse, Error, SeedApplicationsUpdateBatchAllocationMutationRequest>({ method: "PUT", url: `/api/Inventory/Seeds/Applications/batchAllocation`, data, ...config });
    return res.data;
}