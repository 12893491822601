import client from "@kubb/plugin-client/client";
import type { DataManagementCreateMutationRequest, DataManagementCreateMutationResponse } from "../../ts/DataManagementCreate.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/DataManagement/changemodulestatus}
 */
export async function dataManagementCreate(data: DataManagementCreateMutationRequest, config: Partial<RequestConfig<DataManagementCreateMutationRequest>> = {}) {
    const res = await client<DataManagementCreateMutationResponse, Error, DataManagementCreateMutationRequest>({ method: "POST", url: `/api/DataManagement/changemodulestatus`, data, ...config });
    return res.data;
}