import { QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import { ColumnDef } from "@tanstack/react-table";

import {
	CriteriaResultTableCell,
	RehabPolygonLocationCell,
	RehabPolygonNameCell,
} from "@/components/BottomDrawer/Monitoring/RehabPolygonsTableCard/rehab-polygon-table-cells";
import {
	DataTableCustomCell,
	DataTableLargeTextCell,
	DataTableNumberCell,
	DataTableSmallTextCell,
} from "@/components/data-table/data-table-cells";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header";
import { EisColumnSort } from "@/components/data-table/table-types";
import Icon from "@/components/icons/icon";
import { Tooltip } from "@/components/ui/tooltip";
import {
	CompletionCriterionResultEnum,
	MonitoringScheduleDto,
	MonitoringScheduleOrderByEnum,
} from "@/lib/gen/eis";

const METHOD_TOOLTIPS = {
	Install:
		"This polygon has a species richness criteria, but does not have a sampling site.",
	"Re-monitor":
		"This polygon is passing all remote criteria, a sampling site is required to confirm adequate species richness.",
	Inspect:
		"This polygon is failing remote sensing criteria or erosion criteria. Suggest a site inspection.",
} as const;

export const getMonitoringColumns = (
	setColumnSort: React.Dispatch<React.SetStateAction<EisColumnSort>>,
): ColumnDef<MonitoringScheduleDto, unknown>[] => [
	{
		accessorKey: "id",
		enableGlobalFilter: true,
		header: () => <div />,
		cell: ({ row }) => (
			<RehabPolygonLocationCell id={row.original.rehabPolygonId} />
		),
		enableSorting: false,
		enableResizing: false,
		minSize: 26,
		maxSize: 26,
		meta: {
			title: "Location",
		},
	},
	{
		accessorKey: "name",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Polygon"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<RehabPolygonNameCell
				id={row.original.rehabPolygonId}
				name={row.original.rehabPolygonName ?? "-"}
			/>
		),
		enableSorting: true,
		size: 120,
	},
	{
		accessorKey: "tag",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Tag"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<DataTableSmallTextCell
				value={row.original?.rehabPolgonTag ?? ""}
			/>
		),
		enableSorting: true,
		size: 100,
	},
	{
		accessorKey: "miningRegion",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Region"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<DataTableSmallTextCell
				value={row.original?.miningRegionName ?? "-"}
			/>
		),
		enableSorting: true,
		size: 100,
		meta: {
			title: "Region",
			defaultHidden: true,
		},
	},
	{
		accessorKey: "mineSite",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Site"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<DataTableSmallTextCell value={row.original?.mineSiteName ?? ""} />
		),
		enableSorting: true,
		size: 100,
		meta: {
			title: "Site",
		},
	},
	{
		accessorKey: "monitoringScheduleMethod",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Method"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<DataTableCustomCell className="items-center gap-1">
				<Tooltip
					tip={
						METHOD_TOOLTIPS[row.original?.monitoringScheduleMethod]
					}
				>
					<QuestionMarkCircledIcon className="min-w-4 text-gray-500" />
				</Tooltip>
				<span className="truncate">
					{row.original?.monitoringScheduleMethod}
				</span>
			</DataTableCustomCell>
		),
		enableSorting: true,
		enableHiding: true,
		size: 120,
		meta: {
			title: "Method",
		},
	},
	{
		accessorKey: "erosionFeatures",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Erosion Features"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<DataTableNumberCell
				isInteger
				value={row.original?.erosionFeatures}
			/>
		),
		enableSorting: true,
		size: 100,
		meta: {
			title: "Erosion Features",
			defaultHidden: true,
		},
	},
	{
		accessorKey: "erosionMaxDepth",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Erosion Max. Depth (m)"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<DataTableNumberCell value={row.original?.erosionMaxDepth} />
		),
		enableSorting: true,
		size: 100,
		meta: {
			title: "Erosion Max. Depth",
			defaultHidden: true,
		},
	},
	{
		accessorKey: "erosionTotalArea",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Erosion Total Area (m²)"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<DataTableNumberCell value={row.original?.erosionTotalArea} />
		),
		enableSorting: true,
		size: 100,
		meta: {
			title: "Erosion Total Area",
			defaultHidden: true,
		},
	},
	{
		accessorKey: "erosionTotalVolume",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Erosion Total Volume (m³)"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<DataTableNumberCell value={row.original?.erosionTotalVolume} />
		),
		enableSorting: true,
		size: 100,
		meta: {
			title: "Erosion Total Volume",
			defaultHidden: true,
		},
	},
];

export const getMonitoringColumnsOther = (
	setColumnSort: React.Dispatch<React.SetStateAction<EisColumnSort>>,
): ColumnDef<MonitoringScheduleDto, unknown>[] => [
	{
		accessorKey: "rehabilitatedDate",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Rehabilitation Date"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<DataTableSmallTextCell
				value={new Date(
					row.original?.rehabilitatedDate,
				).toLocaleDateString("en-AU", {
					year: "numeric",
					month: "short",
					day: "numeric",
				})}
			/>
		),
		enableSorting: true,
		size: 150,
		meta: {
			title: "Rehabilitation Date",
			columnGroup: "Details",
		},
	},
	{
		accessorKey: "landform",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Landform"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<DataTableSmallTextCell value={row.original?.landform} />
		),
		enableSorting: true,
		enableHiding: true,
		size: 90,
		meta: {
			columnGroup: "Details",
		},
	},
	{
		accessorKey: "area",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Area (ha)"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<DataTableNumberCell
				value={Number(row.original?.area ?? 0) / 10000}
			/>
		),
		enableSorting: true,
		enableHiding: true,
		size: 90,
		meta: {
			columnGroup: "Details",
		},
	},
	{
		accessorKey: "quadrats",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Sampling Sites"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<DataTableLargeTextCell
				value={row.original?.quadratNames?.join(", ")}
			/>
		),
		enableSorting: false,
		enableHiding: true,
		size: 140,
		meta: {
			title: "Sampling Sites",
			columnGroup: "Details",
		},
	},
];

export const getMonitoringRehabPolygonCriteriaResultColumn = (
	critName: string,
	setColumnSort: React.Dispatch<React.SetStateAction<EisColumnSort>>,
): ColumnDef<MonitoringScheduleDto, unknown> => ({
	accessorKey: critName,
	header: ({ column }) => (
		<DataTableColumnHeader
			column={column}
			columnId={critName}
			title={critName ?? ""}
			columnSortSetter={setColumnSort}
			isDynamicColumn={true}
			orderById={
				MonitoringScheduleOrderByEnum.CriterionResultDynamicColumn
			}
		/>
	),
	cell: ({ row }) => {
		const result = row.original.criterionResults?.find(
			(x) => x.criterionName === critName,
		);
		return (
			<div className="w-full">
				{result?.result &&
					result.result !== CompletionCriterionResultEnum.Passed && (
						<CriteriaResultTableCell result={result} />
					)}
				{result?.result &&
					result.result === CompletionCriterionResultEnum.Passed && (
						<DataTableCustomCell className="flex items-center justify-center">
							<Icon
								size={15}
								className="text-card-passing"
								name="check"
							/>
						</DataTableCustomCell>
					)}
				{!result?.result && (
					<DataTableSmallTextCell
						className="w-full text-center"
						value="Unassessed"
					/>
				)}
			</div>
		);
	},
	enableSorting: true,
	size: 120,
	minSize: 120,
	meta: {
		title: critName,
		columnGroup: "Completion Criteria",
	},
});
