import client from "@kubb/plugin-client/client";
import type { SeedApplicationsUpdateSeedApplicationSpeciesTargetMutationRequest, SeedApplicationsUpdateSeedApplicationSpeciesTargetMutationResponse } from "../../ts/SeedApplicationsUpdateSeedApplicationSpeciesTarget.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/Inventory/Seeds/Applications/seedApplicationSpeciesTarget}
 */
export async function seedApplicationsUpdateSeedApplicationSpeciesTarget(data: SeedApplicationsUpdateSeedApplicationSpeciesTargetMutationRequest, config: Partial<RequestConfig<SeedApplicationsUpdateSeedApplicationSpeciesTargetMutationRequest>> = {}) {
    const res = await client<SeedApplicationsUpdateSeedApplicationSpeciesTargetMutationResponse, Error, SeedApplicationsUpdateSeedApplicationSpeciesTargetMutationRequest>({ method: "PUT", url: `/api/Inventory/Seeds/Applications/seedApplicationSpeciesTarget`, data, ...config });
    return res.data;
}