export enum SamplingSiteLandformTypeEnum {
    "Flat" = "Flat",
    "Crest" = "Crest",
    "Slope" = "Slope",
    "Moonscape" = "Moonscape",
    "Clear" = "Clear",
    "Sewage" = "Sewage",
    "Other" = "Other",
    "NVCP" = "NVCP"
}

 export type SamplingSiteLandformType = SamplingSiteLandformTypeEnum;