import client from "@kubb/plugin-client/client";
import type { FilesGetRehabImageQueryQueryResponse, FilesGetRehabImageQueryQueryParams } from "../../ts/FilesGetRehabImageQuery.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/Files/rehabimage}
 */
export async function filesGetRehabImageQuery(params?: FilesGetRehabImageQueryQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<FilesGetRehabImageQueryQueryResponse, Error, unknown>({ method: "GET", url: `/api/Files/rehabimage`, params, ...config });
    return res.data;
}