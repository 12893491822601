import client from "@kubb/plugin-client/client";
import type { WeatherStationsGetWeatherStationsQueryResponse, WeatherStationsGetWeatherStationsQueryParams } from "../../ts/WeatherStationsGetWeatherStations.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/WeatherStations}
 */
export async function weatherStationsGetWeatherStations(params?: WeatherStationsGetWeatherStationsQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<WeatherStationsGetWeatherStationsQueryResponse, Error, unknown>({ method: "GET", url: `/api/WeatherStations`, params, ...config });
    return res.data;
}