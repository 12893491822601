import { atom } from "jotai";
import { atomWithQuery } from "jotai-tanstack-query";

import { AnaloguePolyFiltersAtom } from "@/atoms/analoguePolyAtoms";
import {
	Perm_Erosion_Std,
	Perm_Rehab_Std,
	Perm_Riparian_Std,
	Perm_Topsoil_Std,
	Perm_Weeds_Std,
	UserHasPermissionsAtom,
} from "@/atoms/authAtoms";
import { ErosionFeaturesFiltersAtom } from "@/atoms/erosionAtoms";
import { HealthSurvey1Atom } from "@/atoms/healthAtoms";
import { MapExtentAtom } from "@/atoms/mapAtoms";
import {
	SelectedMineSiteAtom,
	SelectedMineSiteIdAtom,
} from "@/atoms/miningAtoms";
import {
	RehabPolyFiltersAtom,
	SamplingSiteFiltersAtom,
} from "@/atoms/rehabPolyAtoms";
import { SiteInspectionFiltersAtom } from "@/atoms/siteInspectionAtoms";
import { TimeSeriesDateAtom } from "@/atoms/timeseriesAtoms";
import {
	SelectedWeedVegetationClassAtom,
	WeedGridSizeAtom,
	WeedsSurvey1Atom,
	WeedsSurvey2Atom,
} from "@/atoms/weedsAtoms";
import { parseFilterAguments } from "@/components/filtered-search-box/parse-filter-arguments";
import {
	analoguePolygonsGetAnaloguePolygonIdsQuery,
	analoguePolygonsGetAnaloguePolygons,
	bareAreasGetBareAreasByExtent,
	erosionGetErosionFeatureGeometries,
	erosionGetErosionFeautureIdsQuery,
	individualTreesGetIndividualTreeGeometries,
	rehabilitationPolygonsGetRehabilitationPolygonIds,
	rehabilitationPolygonsGetRehabilitationPolygonsByExtent,
	riparianSystemGetRiparianSystemZonesByExtent,
	samplingSitesGetSamplingSiteIds,
	siteInspectionsGetSiteInspectionIds,
	topsoilStockpilesGetGeometries,
	WeedsLocationTypeEnum,
	weedsSurveysGetWeedsSurveySpatialComparison,
} from "@/lib/gen/eis";

import {
	AnaloguePolygonsVisibility,
	BareAreasVisibility,
	ErosionVectorVisiblity,
	HealthZoneVisibility,
	RehabPolygonsVisibility,
	SamplingSitesVisibility,
	SiteInspectionVisibility,
	TopsoilStockpileVisibility,
	WeedGridVisibility,
} from "./layerVisibilityAtoms";

export const BareAreasQueryAtom = atomWithQuery((get) => {
	const userHasPerms = get(UserHasPermissionsAtom);
	const isPermitted = userHasPerms[Perm_Rehab_Std];
	const mineSiteId = get(SelectedMineSiteIdAtom);
	const timeSeriesDate = get(TimeSeriesDateAtom);
	const bareAreasVisibility = get(BareAreasVisibility);

	return {
		queryKey: ["bareAreas", mineSiteId, timeSeriesDate],
		queryFn: async () =>
			bareAreasGetBareAreasByExtent({
				MineSiteId: mineSiteId ?? "",
				TimeSeriesDate: timeSeriesDate.toString(),
			}),
		enabled:
			isPermitted &&
			!!mineSiteId &&
			!!timeSeriesDate &&
			!!bareAreasVisibility,
	};
});

export const WeedGridCellsComparisonQueryAtom = atomWithQuery((get) => {
	const userHasPerms = get(UserHasPermissionsAtom);
	const isPermitted = userHasPerms[Perm_Weeds_Std];
	const weedsSurvey1 = get(WeedsSurvey1Atom);
	const weedsSurvey2 = get(WeedsSurvey2Atom);
	const weedVegClass = get(SelectedWeedVegetationClassAtom);
	const weedGridSize = get(WeedGridSizeAtom);
	const weedGridVisibility = get(WeedGridVisibility);
	const extent = get(MapExtentAtom) ?? [0, 0, 0, 0];
	const buffer = 0.03;

	return {
		queryKey: [
			"weedGridComparison",
			weedsSurvey1?.id,
			weedsSurvey2?.id,
			weedVegClass?.id,
			weedGridSize,
			extent,
		],
		queryFn: async () =>
			weedsSurveysGetWeedsSurveySpatialComparison({
				WeedSurvey1Id: weedsSurvey1?.id ?? "",
				WeedSurvey2Id: weedsSurvey2?.id,
				GridSize: weedGridSize,
				WeedVegetationClassId: weedVegClass?.id,
				WeedsLocationType: WeedsLocationTypeEnum.GridCell,
				XMin: extent[0] - buffer,
				YMin: extent[1] - buffer,
				XMax: extent[2] + buffer,
				YMax: extent[3] + buffer,
			}),
		enabled:
			isPermitted &&
			!!weedsSurvey1?.id &&
			!!weedVegClass &&
			!!weedGridVisibility,
	};
});

const shouldGetRehabPolygonsAtom = atom((get) => {
	return (
		get(RehabPolygonsVisibility) ||
		get(SiteInspectionVisibility) ||
		get(SamplingSitesVisibility)
	);
});

export const RehabPolygonBBoxQueryAtom = atomWithQuery((get) => {
	const userHasPerms = get(UserHasPermissionsAtom);
	const isPermitted = userHasPerms[Perm_Rehab_Std];
	const timeSeriesDate = get(TimeSeriesDateAtom);
	const enable = get(shouldGetRehabPolygonsAtom);

	return {
		queryKey: ["rehabPolyExtentGeometries", timeSeriesDate.toString()],
		queryFn: async () =>
			rehabilitationPolygonsGetRehabilitationPolygonsByExtent({
				timeSeriesDate: timeSeriesDate.toISOString(),
				getPolygonOverallOutcomes: false,
				usePolygonFilters: false,
				returnFullGeometries: false,
			}),
		enabled: isPermitted && enable,
		staleTime: Infinity,
	};
});

export const RehabPolygonGeometriesQueryAtom = atomWithQuery((get) => {
	const userHasPerms = get(UserHasPermissionsAtom);
	const isPermitted = userHasPerms[Perm_Rehab_Std];
	const timeSeriesDate = get(TimeSeriesDateAtom);
	const mineSiteId = get(SelectedMineSiteIdAtom);

	return {
		queryKey: [
			"rehabPolyGeometries",
			timeSeriesDate.toString(),
			mineSiteId,
		],
		queryFn: async () =>
			rehabilitationPolygonsGetRehabilitationPolygonsByExtent({
				timeSeriesDate: timeSeriesDate.toISOString(),
				getPolygonOverallOutcomes: false,
				usePolygonFilters: false,
				returnFullGeometries: true,
				mineSiteId: mineSiteId,
			}),
		enabled: isPermitted && get(shouldGetRehabPolygonsAtom) && !!mineSiteId,
		staleTime: Infinity,
	};
});

export const RehabPolygonIdsFilteredQueryAtom = atomWithQuery((get) => {
	const userHasPerms = get(UserHasPermissionsAtom);
	const isPermitted = userHasPerms[Perm_Rehab_Std];
	const timeSeriesDate = get(TimeSeriesDateAtom);
	const rehabPolygonsVisibility = get(RehabPolygonsVisibility);
	const siteInspectionVisibility = get(SiteInspectionVisibility);
	const samplingSitesVisibility = get(SamplingSitesVisibility);
	const filters = get(RehabPolyFiltersAtom);

	return {
		queryKey: ["rehabPolyIdsFiltered", timeSeriesDate.toString(), filters],
		queryFn: async () =>
			rehabilitationPolygonsGetRehabilitationPolygonIds({
				timeSeriesDate: timeSeriesDate.toISOString(),
				usePolygonFilters: true,
				...parseFilterAguments(filters),
			}),
		enabled:
			isPermitted &&
			(!!rehabPolygonsVisibility ||
				!!siteInspectionVisibility ||
				!!samplingSitesVisibility) &&
			filters.length > 0,
	};
});
export const SiteInspectionIdsFilteredQueryAtom = atomWithQuery((get) => {
	const userHasPerms = get(UserHasPermissionsAtom);
	const isPermitted = userHasPerms[Perm_Rehab_Std];
	const visibility = get(SiteInspectionVisibility);
	const filters = get(SiteInspectionFiltersAtom);

	return {
		queryKey: ["siteInspectionIdsFiltered", filters],
		queryFn: async () =>
			siteInspectionsGetSiteInspectionIds({
				usePolygonFilters: true,
				...parseFilterAguments(filters),
			}),
		enabled: isPermitted && visibility && filters.length > 0,
	};
});
export const SamplingSiteIdsFilteredQueryAtom = atomWithQuery((get) => {
	const userHasPerms = get(UserHasPermissionsAtom);
	const isPermitted = userHasPerms[Perm_Rehab_Std];
	const visibility = get(SamplingSitesVisibility);
	const filters = get(SamplingSiteFiltersAtom);

	return {
		queryKey: ["samplingSiteIdsFiltered", filters],
		queryFn: async () =>
			samplingSitesGetSamplingSiteIds({
				usePolygonFilters: true,
				...parseFilterAguments(filters),
			}),
		enabled: isPermitted && visibility && filters.length > 0,
	};
});

export const AnaloguePolygonQueryAtom = atomWithQuery((get) => {
	const userHasPerms = get(UserHasPermissionsAtom);
	const isPermitted = userHasPerms[Perm_Rehab_Std];
	const timeSeriesDate = get(TimeSeriesDateAtom);
	const analoguePolygonsVisibility = get(AnaloguePolygonsVisibility);

	return {
		queryKey: ["analoguePolys", timeSeriesDate.toISOString()],
		queryFn: async () =>
			analoguePolygonsGetAnaloguePolygons({
				TimeSeriesDate: timeSeriesDate.toISOString(),
			}),
		enabled: isPermitted && !!analoguePolygonsVisibility,
	};
});
export const AnaloguePolygonIdsFilteredQueryAtom = atomWithQuery((get) => {
	const userHasPerms = get(UserHasPermissionsAtom);
	const isPermitted = userHasPerms[Perm_Rehab_Std];
	const timeSeriesDate = get(TimeSeriesDateAtom);
	const analoguePolygonsVisibility = get(AnaloguePolygonsVisibility);
	const filters = get(AnaloguePolyFiltersAtom);

	return {
		queryKey: ["analogueIdsFiltered", timeSeriesDate.toString(), filters],
		queryFn: async () =>
			analoguePolygonsGetAnaloguePolygonIdsQuery({
				timeSeriesDate: timeSeriesDate.toISOString(),
				usePolygonFilters: true,
				...parseFilterAguments(filters),
			}),
		enabled:
			isPermitted && !!analoguePolygonsVisibility && filters.length > 0,
	};
});

export const ErosionGeometryQueryAtom = atomWithQuery((get) => {
	const userHasPerms = get(UserHasPermissionsAtom);
	const isPermitted = userHasPerms[Perm_Erosion_Std];
	const mineSiteId = get(SelectedMineSiteIdAtom);
	const timeSeriesDate = get(TimeSeriesDateAtom);
	const erosionVectorVisibility = get(ErosionVectorVisiblity);

	return {
		queryKey: [
			"erosionGetErosionFeatureGeometries",
			mineSiteId,
			timeSeriesDate.toISOString(),
			true,
		],
		queryFn: async () =>
			erosionGetErosionFeatureGeometries({
				MineSiteId: mineSiteId,
				TimeSeriesDate: timeSeriesDate.toISOString(),
				ReturnFullGeometries: true,
			}),
		enabled:
			isPermitted &&
			!!erosionVectorVisibility &&
			!!timeSeriesDate &&
			!!mineSiteId,
		staleTime: Infinity,
	};
});
export const ErosionExtentGeometryQueryAtom = atomWithQuery((get) => {
	const userHasPerms = get(UserHasPermissionsAtom);
	const isPermitted = userHasPerms[Perm_Erosion_Std];
	const mineSiteId = get(SelectedMineSiteIdAtom);
	const timeSeriesDate = get(TimeSeriesDateAtom);
	const erosionVectorVisibility = get(ErosionVectorVisiblity);

	return {
		queryKey: [
			"erosionGetErosionFeatureGeometries",
			mineSiteId,
			timeSeriesDate.toISOString(),
			false,
		],
		queryFn: async () =>
			erosionGetErosionFeatureGeometries({
				MineSiteId: mineSiteId,
				TimeSeriesDate: timeSeriesDate.toISOString(),
				ReturnFullGeometries: false,
			}),
		enabled: isPermitted && !!erosionVectorVisibility && !!timeSeriesDate,
		staleTime: Infinity,
	};
});
export const ErosionFeatureIdsFilteredQueryAtom = atomWithQuery((get) => {
	const userHasPerms = get(UserHasPermissionsAtom);
	const isPermitted = userHasPerms[Perm_Erosion_Std];
	const timeSeriesDate = get(TimeSeriesDateAtom);
	const erosionVectorVisibility = get(ErosionVectorVisiblity);
	const filters = get(ErosionFeaturesFiltersAtom);

	return {
		queryKey: [
			"erosionFeatureIdsFiltered",
			timeSeriesDate.toISOString(),
			filters,
		],
		queryFn: async () =>
			erosionGetErosionFeautureIdsQuery({
				usePolygonFilters: true,
				...parseFilterAguments(filters),
			}),
		enabled: isPermitted && !!erosionVectorVisibility && filters.length > 0,
	};
});

export const HealthZonesQueryAtom = atomWithQuery((get) => {
	const userHasPerms = get(UserHasPermissionsAtom);
	const isPermitted = userHasPerms[Perm_Riparian_Std];
	const mineSiteId = get(SelectedMineSiteIdAtom);
	const healthZoneVisibility = get(HealthZoneVisibility);

	return {
		queryKey: ["riparianZones"],
		queryFn: async () =>
			riparianSystemGetRiparianSystemZonesByExtent({
				MineSiteId: [mineSiteId!],
			}),
		enabled: isPermitted && !!mineSiteId && !!healthZoneVisibility,
	};
});

export const TopsoilStockpileQueryAtom = atomWithQuery((get) => {
	const userHasPerms = get(UserHasPermissionsAtom);
	const isPermitted = userHasPerms[Perm_Topsoil_Std];
	const mineSite = get(SelectedMineSiteAtom);
	const topsoilStockpileVisibility = get(TopsoilStockpileVisibility);

	return {
		queryKey: ["topsoilStockpiles", mineSite?.mineSiteId],
		queryFn: async () =>
			topsoilStockpilesGetGeometries({
				MineSiteId: mineSite?.mineSiteId,
			}),
		enabled:
			isPermitted &&
			!!mineSite?.mineSiteId &&
			!!topsoilStockpileVisibility,
	};
});

export const IndividualTreeQueryAtom = atomWithQuery((get) => {
	const mineSiteId = get(SelectedMineSiteIdAtom);
	const survey1 = get(HealthSurvey1Atom);
	const userPerms = get(UserHasPermissionsAtom);

	return {
		queryKey: ["individualTrees", mineSiteId, survey1?.remoteSensingDate],
		queryFn: async () => {
			return await individualTreesGetIndividualTreeGeometries({
				mineSiteId: mineSiteId ?? "",
				timeSeriesDate: survey1?.remoteSensingDate ?? "",
			});
		},
		enabled:
			userPerms[Perm_Riparian_Std] &&
			!!survey1?.remoteSensingDate &&
			!!mineSiteId,
	};
});
