import client from "@kubb/plugin-client/client";
import type { WeedsSurveysGetWeedsSurveysWithPaginationQueryResponse, WeedsSurveysGetWeedsSurveysWithPaginationQueryParams } from "../../ts/WeedsSurveysGetWeedsSurveysWithPagination.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/WeedsSurveys}
 */
export async function weedsSurveysGetWeedsSurveysWithPagination(params?: WeedsSurveysGetWeedsSurveysWithPaginationQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<WeedsSurveysGetWeedsSurveysWithPaginationQueryResponse, Error, unknown>({ method: "GET", url: `/api/WeedsSurveys`, params, ...config });
    return res.data;
}