import client from "@kubb/plugin-client/client";
import type { SeedBatchesGetInventorySummaryQueryResponse, SeedBatchesGetInventorySummaryQueryParams } from "../../ts/SeedBatchesGetInventorySummary.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/Inventory/Seeds/Batches/summary}
 */
export async function seedBatchesGetInventorySummary(params?: SeedBatchesGetInventorySummaryQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<SeedBatchesGetInventorySummaryQueryResponse, Error, unknown>({ method: "GET", url: `/api/Inventory/Seeds/Batches/summary`, params, ...config });
    return res.data;
}