import { useInfiniteQuery } from "@tanstack/react-query";
import { RowSelectionState } from "@tanstack/react-table";
import { useAtomValue } from "jotai";
import { useEffect, useMemo, useState } from "react";

import { DrawerTabEnum } from "@/atoms/bottomDrawerAtoms";
import {
	HealthMetricsAtom,
	HealthSurvey1Atom,
	HealthSurvey2Atom,
	HealthZoneArg,
	HealthZoneFiltersAtom,
	SelectedHealthZoneIdAtom,
} from "@/atoms/healthAtoms";
import { BottomDrawDataTable } from "@/components/bottom-draw-data-table/bottom-draw-data-table";
import { getLocationMetricResultComparisonColumn } from "@/components/BottomDrawer/Health/HealthGridTable/health-grid-columns";
import { EisColumnSort } from "@/components/data-table/table-types";
import { ColumnFilterOption } from "@/components/filtered-search-box/filter-search-box-types";
import { parseFilterAguments } from "@/components/filtered-search-box/parse-filter-arguments";
import { useFilters } from "@/helpers/filter-hook";
import {
	RiparianLocationTypeEnum,
	RiparianMetricDataTypeEnum,
	RiparianSurveyComparisonOrderBy,
	riparianSurveyGetRiparianSurveyComparison,
} from "@/lib/gen/eis";
import { getHealthGridDynamicFilterMetricValue } from "@/types/filters/health-grid-filters";

import { HealthZoneColumns } from "./health-zone-columns";

const HealthZoneTable = () => {
	const survey1 = useAtomValue(HealthSurvey1Atom);
	const survey2 = useAtomValue(HealthSurvey2Atom);
	const selectedHealthZoneId = useAtomValue(SelectedHealthZoneIdAtom);

	const [columnSort, setColumnSort] = useState<EisColumnSort>({
		id: "Name",
		desc: false,
	});
	const [dynamicFilters, setDynamicFilters] = useState<
		ColumnFilterOption<HealthZoneArg>[]
	>([]);
	const [healthZoneFilters, setHealthZoneFilters] = useFilters(
		HealthZoneFiltersAtom,
	);

	const rowSelection = useMemo(
		() =>
			(selectedHealthZoneId != null
				? { [selectedHealthZoneId]: true }
				: {}) as RowSelectionState,
		[selectedHealthZoneId],
	);

	const {
		data,
		isLoading,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
	} = useInfiniteQuery({
		queryKey: ["HealthZoneComparison", columnSort, healthZoneFilters],
		queryFn: async ({ pageParam }) => {
			return riparianSurveyGetRiparianSurveyComparison({
				riparianSurvey1Id: survey1?.id ?? "",
				riparianSurvey2Id: survey2?.id,
				riparianLocationType: RiparianLocationTypeEnum.Zone,
				includeNoCoverageMetrics: false,
				pageNumber: pageParam,
				pageSize: 25,
				orderBy: (columnSort.orderById ??
					columnSort.id) as RiparianSurveyComparisonOrderBy,
				orderByDynamicColumn: columnSort.isDynamicColumn
					? columnSort.id
					: undefined,
				descending: columnSort.desc,
				...parseFilterAguments(healthZoneFilters),
			});
		},
		enabled: !!survey1?.id,
		getNextPageParam: (lastPage) =>
			lastPage.pageIndex === lastPage.totalPages
				? lastPage.pageIndex
				: lastPage.pageIndex + 1,
		initialPageParam: 1,
	});
	const rows = useMemo(() => {
		return data
			? Object.values(data.pages.flatMap((p) => p.items ?? []))
			: [];
	}, [data]);

	const totalRows = useMemo(() => {
		if (!data || data.pages.length <= 0) return 0;
		return data.pages[0].totalCount;
	}, [data]);

	const initialColumns = useMemo(() => {
		return HealthZoneColumns({ setColumnSort });
	}, [setColumnSort]);

	const metrics = useAtomValue(HealthMetricsAtom);
	const columns = useMemo(
		() => [
			...initialColumns,
			...(metrics?.data
				? metrics.data
						.filter(
							(m) =>
								m.riparianMetricDataType !==
								RiparianMetricDataTypeEnum.SquareMetres,
						)
						.sort(
							(a, b) =>
								(a.displayPosition ?? 999) -
								(b.displayPosition ?? 999),
						)
						.map((m) =>
							getLocationMetricResultComparisonColumn(
								m.displayName ?? "",
								m.riparianMetricDataType,
								setColumnSort,
							),
						)
				: []),
		],
		[initialColumns, metrics.data],
	);

	useEffect(() => {
		setDynamicFilters([
			...(metrics?.data
				? metrics.data
						.filter(
							(m) =>
								m.riparianMetricDataType !==
								RiparianMetricDataTypeEnum.SquareMetres,
						)
						.sort(
							(a, b) =>
								(a.displayPosition ?? 999) -
								(b.displayPosition ?? 999),
						)
						.map((m) => getHealthGridDynamicFilterMetricValue(m))
				: []),
		]);
	}, [metrics.data]);

	return (
		<BottomDrawDataTable
			columns={columns}
			data={rows}
			totalRows={totalRows}
			getRowId={(row) => row.locationId}
			rowSelection={rowSelection}
			title="Health Zones"
			moduleType="health"
			icon="health"
			isLoading={isLoading}
			fetch={{
				isFetching,
				isFetchingNextPage,
				fetchNextPage,
				hasNextPage,
			}}
			// TODO: Export
			filteredSearchBox={{
				options: [...dynamicFilters],
				filters: healthZoneFilters,
				setFilters: setHealthZoneFilters,
			}}
			tabType={DrawerTabEnum.HEALTH_ZONE_COMPARISON_TABLE}
		/>
	);
};

export default HealthZoneTable;
