import { ColumnDef, ColumnSort } from "@tanstack/react-table";

import {
	DataTableDateCell,
	DataTableNumberCell,
	DataTableSmallTextCell,
} from "@/components/data-table/data-table-cells";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header";
import {
	PolygonOverallResultEnum,
	RehabilitationPolygonPagedDto,
	RehabPolysOrderByEnum,
	VegClassCoversDto,
} from "@/lib/gen/eis";

import {
	CompletionStatusCell,
	CriteriaResultTableCell,
	RehabPolygonLocationCell,
	RehabPolygonNameCell,
} from "./rehab-polygon-table-cells";

export const getRehabPolygonColumns = (
	setColumnSort: React.Dispatch<React.SetStateAction<ColumnSort>>,
): ColumnDef<RehabilitationPolygonPagedDto, unknown>[] => [
	{
		accessorKey: "id",
		enableGlobalFilter: true,
		header: () => <div />,
		cell: ({ row }) => <RehabPolygonLocationCell id={row.original.id} />,
		enableSorting: false,
		enableResizing: false,
		minSize: 26,
		maxSize: 26,
		meta: {
			title: "Location",
		},
	},
	{
		accessorKey: "name",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Polygon"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<RehabPolygonNameCell
				id={row.original.id}
				name={row.original.name ?? "-"}
			/>
		),
		enableSorting: true,
		size: 100,
		meta: {
			title: "Polygon",
		},
	},
	{
		accessorKey: "tag",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Tag"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<DataTableSmallTextCell value={row.original?.tagString ?? ""} />
		),
		enableSorting: true,
		size: 100,
	},
	{
		accessorKey: "miningRegion",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Region"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<DataTableSmallTextCell value={row.original?.miningRegion ?? "-"} />
		),
		enableSorting: true,
		size: 100,
		meta: {
			title: "Region",
		},
	},
	{
		accessorKey: "mineSite",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Site"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<DataTableSmallTextCell value={row.original?.mineSite ?? ""} />
		),
		enableSorting: true,
		size: 100,
		meta: {
			title: "Site",
		},
	},
	{
		accessorKey: "completionStatus",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Criteria Outcome"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<CompletionStatusCell
				completionStatus={
					row.original?.completionStatus ??
					PolygonOverallResultEnum.Unassessed
				}
			/>
		),
		enableSorting: true,
		size: 120,
		meta: {
			title: "Completion Status",
		},
	},
	{
		accessorKey: "rehabilitatedDate",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Rehabilitation Date"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<DataTableDateCell value={row.original?.rehabilitatedDate} />
		),
		enableSorting: true,
		size: 150,
		meta: {
			title: "Rehabilitation Date",
		},
	},
	{
		accessorKey: "landform",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Landform"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<DataTableSmallTextCell value={row.original?.landform} />
		),
		enableSorting: true,
		size: 90,
	},
	{
		accessorKey: "area",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Area (ha)"
				columnSortSetter={setColumnSort}
				rightAlign
			/>
		),
		cell: ({ row }) => (
			<DataTableNumberCell
				value={Number(row.original?.area ?? 0) / 10000}
			/>
		),
		enableSorting: true,
		size: 90,
	},
	{
		accessorKey: "quadrats",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Sampling Sites"
				columnSortSetter={setColumnSort}
				rightAlign
			/>
		),
		cell: ({ row }) => (
			<DataTableNumberCell isInteger value={row.original?.quadrats} />
		),
		enableSorting: true,
		size: 80,
		meta: {
			title: "Sampling Sites",
		},
	},
	{
		accessorKey: "siteInspections",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Site Inspections"
				columnSortSetter={setColumnSort}
				rightAlign
			/>
		),
		cell: ({ row }) => (
			<DataTableNumberCell
				isInteger
				value={row.original?.siteInspections}
			/>
		),
		enableSorting: true,
		size: 80,
		meta: {
			title: "Site Inspections",
		},
	},
	{
		accessorKey: "erosionFeatures",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Erosion Features"
				columnSortSetter={setColumnSort}
				rightAlign
			/>
		),
		cell: ({ row }) => (
			<DataTableNumberCell
				isInteger
				value={row.original?.erosionFeatures}
			/>
		),
		enableSorting: true,
		size: 100,
		meta: {
			title: "Erosion Features",
		},
	},
	{
		accessorKey: "erosionMaxDepth",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Erosion Max. Depth (m)"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<DataTableNumberCell value={row.original?.erosionMaxDepth} />
		),
		enableSorting: true,
		size: 100,
		meta: {
			title: "Erosion Max. Depth",
		},
	},
	{
		accessorKey: "erosionTotalArea",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Erosion Total Area (m²)"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<DataTableNumberCell value={row.original?.erosionTotalArea} />
		),
		enableSorting: true,
		size: 100,
		meta: {
			title: "Erosion Total Area",
		},
	},
	{
		accessorKey: "erosionTotalVolume",
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title="Erosion Total Volume (m³)"
				columnSortSetter={setColumnSort}
			/>
		),
		cell: ({ row }) => (
			<DataTableNumberCell value={row.original?.erosionTotalVolume} />
		),
		enableSorting: true,
		size: 100,
		meta: {
			title: "Erosion Total Volume",
		},
	},
];

export const getRehabPolygonCriteriaResultColumn = (
	critName: string,
	setColumnSort: React.Dispatch<React.SetStateAction<ColumnSort>>,
): ColumnDef<RehabilitationPolygonPagedDto, unknown> => ({
	accessorKey: critName,
	header: ({ column }) => (
		<DataTableColumnHeader
			column={column}
			columnId={critName}
			title={critName ?? ""}
			columnSortSetter={setColumnSort}
			isDynamicColumn={true}
			orderById={RehabPolysOrderByEnum.CriterionResultDynamicColumn}
		/>
	),
	cell: ({ row }) => (
		<CriteriaResultTableCell
			result={row.original.criterionResults?.find(
				(x) => x.criterionName === critName,
			)}
		/>
	),
	enableSorting: true,
	size: 120,
	minSize: 120,
	meta: {
		title: critName,
		columnGroup: "Completion Criteria",
	},
});

export function getRehabPolygonVegCoverColumn<
	T extends {
		vegClassCovers?: VegClassCoversDto[] | null;
	} = RehabilitationPolygonPagedDto,
>(
	vegetationClassName: string,
	setColumnSort: React.Dispatch<React.SetStateAction<ColumnSort>>,
	defaultHidden?: boolean,
): ColumnDef<T, unknown> {
	return {
		accessorKey: vegetationClassName,
		header: ({ column }) => (
			<DataTableColumnHeader
				column={column}
				title={`${vegetationClassName} %`}
				columnSortSetter={setColumnSort}
				isDynamicColumn={true}
				orderById={RehabPolysOrderByEnum.VegClassCoverDynamicColumn}
				rightAlign
			/>
		),
		cell: ({ row }) => (
			<DataTableNumberCell
				value={Number(
					row.original.vegClassCovers?.find(
						(x) => x.vegetationClassName === vegetationClassName,
					)?.percentageCover ?? 0,
				)}
				numStyle="percent"
			/>
		),
		enableSorting: true,
		size: 80,
		meta: {
			title: `${vegetationClassName} %`,
			columnGroup: "Classification Covers",
			defaultHidden,
		},
	};
}
