import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useAuth } from "oidc-react";
import { Fragment, useState } from "react";

import {
	Perm_ModuleMgmt_Admin,
	Perm_UserMgmt_Admin,
	UserAtom,
	UserPermissionsAtom,
} from "@/atoms/authAtoms";
import { DarkModeAtom } from "@/atoms/configAtoms";
import { ClientLogoQueryAtom, CurrentViewAtom } from "@/atoms/eisViewAtoms";
import { HealthMetricsAtom } from "@/atoms/healthAtoms";
import { WeedsSurveysAtom } from "@/atoms/weedsAtoms";
import ModuleManagementDialog from "@/components/ConfigCard/ModuleManagementCard";
import UserSettingsDialog from "@/components/ConfigCard/UserSettingsDialog";
import { DialogsAtom } from "@/components/dialogs/dialogs.atom";
import { IconName } from "@/components/icons";
import Icon from "@/components/icons/icon";
import {
	Menubar,
	MenubarCheckboxItem,
	MenubarContent,
	MenubarItem,
	MenubarMenu,
	MenubarTrigger,
} from "@/components/ui/menubar";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { API_URL } from "@/env_variables";
import { cn } from "@/lib/utils";

import HealthNavBarControls from "./healthNavBarControls";
import WeedNavBarControls from "./weedNavBarControls";

const Navbar: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
	children,
}) => {
	const openDialog = useSetAtom(DialogsAtom);
	const [userPermissions] = useAtom(UserPermissionsAtom);
	const [user] = useAtom(UserAtom);
	const auth = useAuth();
	const [darkMode, setDarkMode] = useAtom(DarkModeAtom);
	const [active, setActive] = useState("mapView");
	const currentView = useAtomValue(CurrentViewAtom);
	const { data: clientLogo } = useAtomValue(ClientLogoQueryAtom);
	const weedSurveys = useAtomValue(WeedsSurveysAtom);
	const { data: metrics } = useAtomValue(HealthMetricsAtom);

	const tabs: {
		id: string;
		name: string;
		icon: IconName;
		enabled: boolean;
	}[] = [
		{ id: "home", name: "Home", icon: "house", enabled: false },
		{ id: "mapView", name: "Dashboard", icon: "world", enabled: true },
		{
			id: "smarts",
			name: "Smarts",
			icon: "smart_analytics",
			enabled: false,
		},
		{
			id: "config",
			name: "Configuration",
			icon: "config",
			enabled: true,
		},
	];

	return (
		<Tabs
			defaultValue="mapView"
			className="flex h-screen w-full flex-col"
			onValueChange={setActive}
		>
			<div className="z-10 flex h-10 border-b border-misc-tyton-green-primary bg-core-primary-background align-middle">
				<div className="flex flex-1">
					<Icon
						name={darkMode ? "tyton_eis_yellow" : "tyton_eis"}
						className="m-2"
					/>

					<TabsList className="h-10 pb-0">
						{tabs.map(
							(t) =>
								(t.id !== "config" ||
									userPermissions?.some(
										(p) => p === Perm_UserMgmt_Admin,
									)) && (
									<Fragment key={t.id}>
										<TabsTrigger
											value={t.id}
											className="pb-0"
											disabled={!t.enabled}
										>
											<div className="flex flex-col">
												<div className="flex h-7 items-center gap-1">
													<Icon
														name={t.icon}
														className="text-core-link-text"
													/>
													<span>{t.name}</span>
												</div>
												<div
													className={cn(
														"h-1 rounded-t",
														active === t.id
															? "bg-core-link-text"
															: "",
													)}
												></div>
											</div>
										</TabsTrigger>
									</Fragment>
								),
						)}
					</TabsList>
				</div>
				<div className="flex-1">
					{currentView?.name === "Health" &&
						metrics &&
						metrics?.length > 0 && <HealthNavBarControls />}

					{currentView?.name === "Weeds" &&
						weedSurveys &&
						weedSurveys.length > 0 && <WeedNavBarControls />}
				</div>
				<div className="ml-auto mr-2 flex items-center gap-3">
					{clientLogo && (
						<img
							key={clientLogo.fullImageFileName}
							className="size-full max-h-6"
							alt={clientLogo.fullImageFileName ?? "client logo"}
							src={`data:image/jpeg;base64,${clientLogo.fullImageBytes}`}
						/>
					)}
					{!clientLogo && <Icon name="organisation" />}
					<Menubar className="flex gap-3">
						<MenubarMenu>
							<MenubarTrigger
								className="size-6 rounded-full pl-px text-center text-sm font-semibold uppercase text-white"
								style={{
									backgroundColor:
										clientLogo?.colour ??
										"hsl(var(--core-primary-button-fill))",
								}}
							>
								{user?.profile.name?.slice(0, 1)}
							</MenubarTrigger>
							<MenubarContent>
								<MenubarCheckboxItem
									checked={darkMode}
									onCheckedChange={(e) => {
										setDarkMode(e);
									}}
								>
									Dark Mode
								</MenubarCheckboxItem>
								{userPermissions?.some(
									(p) => p === Perm_UserMgmt_Admin,
								) && (
									<MenubarItem asChild>
										<a
											href={
												API_URL +
												"/Identity/Account/SendInvite?returnUrl=" +
												window.location.href
											}
										>
											Send User Invite
										</a>
									</MenubarItem>
								)}
								{userPermissions?.some(
									(p) => p === Perm_UserMgmt_Admin,
								) && (
									<MenubarItem asChild>
										<a
											href={
												API_URL +
												"/Identity/Account/ViewUsers?returnUrl=" +
												window.location.href
											}
										>
											Manage Users
										</a>
									</MenubarItem>
								)}
								{userPermissions?.some(
									(p) => p === Perm_ModuleMgmt_Admin,
								) && (
									<MenubarItem
										onClick={() =>
											openDialog(ModuleManagementDialog)
										}
									>
										Module Management
									</MenubarItem>
								)}

								{userPermissions?.some(
									(p) => p === Perm_ModuleMgmt_Admin,
								) && (
									<MenubarItem
										onClick={() =>
											openDialog(UserSettingsDialog)
										}
									>
										User Settings
									</MenubarItem>
								)}

								<MenubarItem
									onClick={() => {
										auth.signOutRedirect();
									}}
								>
									Logout
								</MenubarItem>
							</MenubarContent>
						</MenubarMenu>
					</Menubar>
				</div>
			</div>
			{children}
		</Tabs>
	);
};

export default Navbar;
