import client from "@kubb/plugin-client/client";
import type { SeedApplicationsAddSpeciesToSeedApplicationMutationRequest, SeedApplicationsAddSpeciesToSeedApplicationMutationResponse, SeedApplicationsAddSpeciesToSeedApplicationPathParams } from "../../ts/SeedApplicationsAddSpeciesToSeedApplication.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/Inventory/Seeds/Applications/:id/addspecies}
 */
export async function seedApplicationsAddSpeciesToSeedApplication(id: SeedApplicationsAddSpeciesToSeedApplicationPathParams["id"], data: SeedApplicationsAddSpeciesToSeedApplicationMutationRequest, config: Partial<RequestConfig<SeedApplicationsAddSpeciesToSeedApplicationMutationRequest>> = {}) {
    const res = await client<SeedApplicationsAddSpeciesToSeedApplicationMutationResponse, Error, SeedApplicationsAddSpeciesToSeedApplicationMutationRequest>({ method: "POST", url: `/api/Inventory/Seeds/Applications/${id}/addspecies`, data, ...config });
    return res.data;
}