import { HealthGridArg } from "@/atoms/healthAtoms";
import { ColumnFilterOption } from "@/components/filtered-search-box/filter-search-box-types";
import {
	PredicateFilterTypeEnum,
	RiparianMetricDataTypeEnum,
	RiparianSystemMetricListDto,
} from "@/lib/gen/eis";
import { getTypeAheadResults } from "@/services/predicateBuilder.service";

export const HEALTH_GRID_FILTER_OPTION_ZONE: ColumnFilterOption<HealthGridArg> =
	{
		key: "zoneName",
		label: "Zone",
		icon: "zone",
		options: (searchTerm?: string) =>
			getTypeAheadResults(
				PredicateFilterTypeEnum.healthZoneName,
				searchTerm,
			),
		keywords: ["name"],
	};

export function getHealthGridDynamicFilterMetricValue(
	metric: RiparianSystemMetricListDto,
): ColumnFilterOption<HealthGridArg> {
	return {
		key: "metricValueFiltersDtos",
		subKey: metric.metricId,
		label:
			metric.riparianMetricDataType === RiparianMetricDataTypeEnum.MSAVI
				? `${metric.displayName} MSAVI`
				: metric.riparianMetricDataType ===
					  RiparianMetricDataTypeEnum.Percent
					? `${metric.displayName} %`
					: `${metric.displayName}`,
		icon: "circle_filled",
		iconColor: metric.displayColour ?? undefined,
		type:
			metric.riparianMetricDataType === RiparianMetricDataTypeEnum.MSAVI
				? "number"
				: "percentage",
		group: "Metric Values",
	};
}

export const HEALTH_GRID_FILTER_OPTIONS: ColumnFilterOption<HealthGridArg>[] = [
	HEALTH_GRID_FILTER_OPTION_ZONE,
];
