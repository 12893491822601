import { atom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";
import { atomEffect } from "jotai-effect";
import { atomWithHash } from "jotai-location";

import { HashKeys } from "@/lib/hash-keys";

import { CurrentViewAtom, TytonEisModuleType } from "./eisViewAtoms";
import { MapViewPaddingAtom, VIEWPORT_PADDING } from "./mapAtoms";
import { RehabPolyFiltersAtom } from "./rehabPolyAtoms";

export interface BottomDrawerTab {
	module: TytonEisModuleType;
	name: DrawerTabEnum;
}

export enum DrawerTabEnum {
	// Monitoring
	REHAB_POLYS = "rehabpolys",
	ANALOGUE_POLYS = "analoguepolys",
	SITE_INSPECTIONS = "siteInspections",
	SAMPLING_SITES = "samplingSites",
	SAMPLING_SITES_OBSERVATIONS = "samplingSitesObservations",
	OBSERVATION_SAMPLING_SPECIES = "observationSamplingSpecies",
	MONITORING_SCHEDULE = "monitoringschedule",
	RAIN_AND_TEMP = "rainAndTemp",
	VEGETATION_COVER = "vegetationcover",
	// Erosion
	EROSION_FEATURE = "erosionFeature",
	// Seed
	SEED_INVENTORY = "seedinventory",
	SEED_APP = "seedapp",
	// Seed Application
	SEED_APP_PLAN = "seedplan",
	SEED_APP_ALLOCATE = "seedallocate",
	// Health
	HEALTH_COMPARISON_TABLE = "healthcomparisontable",
	HEALTH_ZONE_COMPARISON_TABLE = "healthzonecomparisontable",
	HEALTH_VEGETATION_COVER = "healthvegetationcover",
	// Weeds
	WEED_GRID_TABLE = "weedgridtable",
	WEED_APPLICATION_TABLE = "weedapplicationtable",
	WEED_VEGETATION_COVER = "weedvegetationcover",
	// Topsoil
	TOPSOIL = "Topsoil", // placeholder
}

export const DrawerTabModules = {
	[DrawerTabEnum.REHAB_POLYS]: "Monitoring",
	[DrawerTabEnum.ANALOGUE_POLYS]: "Monitoring",
	[DrawerTabEnum.SITE_INSPECTIONS]: "Monitoring",
	[DrawerTabEnum.SAMPLING_SITES]: "Monitoring",
	[DrawerTabEnum.SAMPLING_SITES_OBSERVATIONS]: "Monitoring",
	[DrawerTabEnum.OBSERVATION_SAMPLING_SPECIES]: "Monitoring",
	[DrawerTabEnum.MONITORING_SCHEDULE]: "Monitoring",
	[DrawerTabEnum.RAIN_AND_TEMP]: "Monitoring",
	[DrawerTabEnum.VEGETATION_COVER]: "Monitoring",
	[DrawerTabEnum.EROSION_FEATURE]: "Erosion",
	[DrawerTabEnum.SEED_INVENTORY]: "Seeds",
	[DrawerTabEnum.SEED_APP]: "Seeds",
	[DrawerTabEnum.SEED_APP_PLAN]: "SeedApplication",
	[DrawerTabEnum.SEED_APP_ALLOCATE]: "SeedApplication",
	[DrawerTabEnum.HEALTH_COMPARISON_TABLE]: "Health",
	[DrawerTabEnum.HEALTH_VEGETATION_COVER]: "Health",
	[DrawerTabEnum.HEALTH_ZONE_COMPARISON_TABLE]: "Health",
	[DrawerTabEnum.WEED_GRID_TABLE]: "Weeds",
	[DrawerTabEnum.WEED_APPLICATION_TABLE]: "Weeds",
	[DrawerTabEnum.WEED_VEGETATION_COVER]: "Weeds",
	[DrawerTabEnum.TOPSOIL]: "Topsoil",
} satisfies Record<DrawerTabEnum, TytonEisModuleType>;

export const DefaultDrawerTabs = {
	Monitoring: DrawerTabEnum.REHAB_POLYS,
	Erosion: DrawerTabEnum.EROSION_FEATURE,
	Weeds: DrawerTabEnum.WEED_GRID_TABLE,
	Seeds: DrawerTabEnum.SEED_INVENTORY,
	Topsoil: DrawerTabEnum.TOPSOIL,
	Health: DrawerTabEnum.HEALTH_ZONE_COMPARISON_TABLE,
	SeedApplication: DrawerTabEnum.SEED_APP_PLAN,
} satisfies Record<TytonEisModuleType, DrawerTabEnum>;

export const LastDrawerSizeAtom = atomWithStorage(
	"bottom-drawer-size",
	330, // default height for 5 rows in a table
	createJSONStorage(() => localStorage),
	{ getOnInit: true },
);
const _drawerSizeAtom = atom(0);
export const DrawerSizeAtom = atom(
	(get) => get(_drawerSizeAtom),
	(get, set, setter: number | ((prev: number) => number)) => {
		set(_drawerSizeAtom, setter);
		const size = get(_drawerSizeAtom);
		if (size >= 200) set(LastDrawerSizeAtom, size);
	},
);
export const DrawerIsOpenAtom = atom(
	(get) => {
		const size = get(_drawerSizeAtom);
		return size > 0;
	},
	(get, set, setter: boolean | ((prev: boolean) => boolean)) => {
		const size = get(_drawerSizeAtom);
		let open: boolean;
		if (typeof setter === "function") {
			open = setter(size > 0);
		} else open = setter;
		set(_drawerSizeAtom, open ? get(LastDrawerSizeAtom) : 0);
	},
);

export const DrawerSizePaddingAtomEffect = atomEffect((get, set) => {
	const size = get(DrawerSizeAtom);
	const p = [...VIEWPORT_PADDING];
	set(MapViewPaddingAtom, [p[0], p[1], p[2] + size, p[3]]);
});

const _activeTabAtom = atomWithHash<DrawerTabEnum | undefined>(
	HashKeys.DrawerTab,
	undefined,
);
export const ActiveTabAtom = atom(
	(get): BottomDrawerTab => {
		const module = get(CurrentViewAtom)?.name ?? "Monitoring";
		const tab = get(_activeTabAtom);
		if (tab) {
			return { module, name: tab };
		}
		return { module, name: DefaultDrawerTabs[module] };
	},
	(_get, set, tab: BottomDrawerTab) => {
		set(_activeTabAtom, tab.name);
		set(CurrentViewAtom, tab.module);

		// Clear the rehab poly filters when the table is not active
		if (tab.name !== DrawerTabEnum.REHAB_POLYS) {
			set(RehabPolyFiltersAtom, []);
		}
	},
);
