import client from "@kubb/plugin-client/client";
import type { MineSitesGetTopsoilWeedAlertsQueryResponse, MineSitesGetTopsoilWeedAlertsQueryParams } from "../../ts/MineSitesGetTopsoilWeedAlerts.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/MineSites/topsoilweedalerts}
 */
export async function mineSitesGetTopsoilWeedAlerts(params?: MineSitesGetTopsoilWeedAlertsQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<MineSitesGetTopsoilWeedAlertsQueryResponse, Error, unknown>({ method: "GET", url: `/api/MineSites/topsoilweedalerts`, params, ...config });
    return res.data;
}