export enum SeedApplicationOrderByEnum {
    "Name" = "Name",
    "RehabilitationPolygonName" = "RehabilitationPolygonName",
    "MiningRegion" = "MiningRegion",
    "Minesite" = "Minesite",
    "provenanceZone" = "provenanceZone",
    "NumberOfFloraSpecies" = "NumberOfFloraSpecies",
    "Quantity" = "Quantity",
    "NumberOfSeedBatches" = "NumberOfSeedBatches",
    "StartDate" = "StartDate",
    "EndDate" = "EndDate",
    "DateCreated" = "DateCreated",
    "Status" = "Status",
    "Note" = "Note"
}

 export type SeedApplicationOrderBy = SeedApplicationOrderByEnum;