import client from "@kubb/plugin-client/client";
import type { MineSitesGetRehabSurveyMapUrlsQueryResponse, MineSitesGetRehabSurveyMapUrlsQueryParams } from "../../ts/MineSitesGetRehabSurveyMapUrls.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/MineSites/mapurls}
 */
export async function mineSitesGetRehabSurveyMapUrls(params?: MineSitesGetRehabSurveyMapUrlsQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<MineSitesGetRehabSurveyMapUrlsQueryResponse, Error, unknown>({ method: "GET", url: `/api/MineSites/mapurls`, params, ...config });
    return res.data;
}