import client from "@kubb/plugin-client/client";
import type { MiningRegionsGetAllMiningRegionAndSiteDetailsQueryResponse } from "../../ts/MiningRegionsGetAllMiningRegionAndSiteDetails.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/MiningRegions/GetAllMiningRegionAndSiteDetails}
 */
export async function miningRegionsGetAllMiningRegionAndSiteDetails(config: Partial<RequestConfig> = {}) {
    const res = await client<MiningRegionsGetAllMiningRegionAndSiteDetailsQueryResponse, Error, unknown>({ method: "GET", url: `/api/MiningRegions/GetAllMiningRegionAndSiteDetails`, ...config });
    return res.data;
}