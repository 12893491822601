import client from "@kubb/plugin-client/client";
import type { TopsoilStockpilesGetStockpilesAdjustmentsQueryResponse, TopsoilStockpilesGetStockpilesAdjustmentsQueryParams } from "../../ts/TopsoilStockpilesGetStockpilesAdjustments.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/TopsoilStockpiles/adjustments}
 */
export async function topsoilStockpilesGetStockpilesAdjustments(params?: TopsoilStockpilesGetStockpilesAdjustmentsQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<TopsoilStockpilesGetStockpilesAdjustmentsQueryResponse, Error, unknown>({ method: "GET", url: `/api/TopsoilStockpiles/adjustments`, params, ...config });
    return res.data;
}