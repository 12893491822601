import { atom } from "jotai";
import { atomEffect } from "jotai-effect";
import { Feature } from "ol";
import { Color } from "ol/color";
import { Geometry } from "ol/geom";
import { Options } from "ol/layer/BaseTile";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import { Cluster, TileJSON, XYZ } from "ol/source";
import VectorSource from "ol/source/Vector";
import { Circle, Fill, Stroke, Style, Text } from "ol/style";

import { ApiKeysQueryAtom, MapAtom } from "@/atoms/mapAtoms";

export type LayerSource = {
	source: VectorSource<Feature<Geometry>> | undefined;
};

export const BaseSatelliteLayerAtom = atom((get) => {
	const { data: keys } = get(ApiKeysQueryAtom);
	const mapTilerApiKey = keys?.find((k) => k.keyName === "MAPTILER_KEY");
	if (!mapTilerApiKey) return null;
	return new TileLayer({
		source: new TileJSON({
			url: `https://api.maptiler.com/maps/satellite/tiles.json?key=${mapTilerApiKey.keyValue}`,
			tileSize: 512,
			crossOrigin: "anonymous",
			attributions: [
				'<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a>',
				'<a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
			],
		}),
		cacheSize: 2048,
	});
});
export const BaseSatelliteLayerAtomEffect = atomEffect((get) => {
	const map = get(MapAtom);
	const layer = get(BaseSatelliteLayerAtom);
	if (!layer) return;
	map.getLayers().insertAt(0, layer);

	return () => {
		map.removeLayer(layer);
	};
});

export const CreateImageryXyzLayer = (
	source: XYZ = new XYZ(),
	options?: Options<XYZ>,
	cacheSize: number = 2048,
) => {
	return new TileLayer({ source: source, cacheSize: cacheSize, ...options });
};

const SELECTION_STROKE_COLOUR: Color = [0, 255, 255, 1];

const MINE_SITE_STROKE_COLOUR: Color = [255, 255, 255, 1];
export const MineSiteLayerAtom = atom(
	new VectorLayer({
		source: new VectorSource(),
		style: function (feature) {
			const hover = feature.get("hover") as boolean;
			const selected = feature.get("selected") as boolean;

			return new Style({
				fill: new Fill({
					color: "rgba(255, 255, 255, 0)",
				}),
				stroke: new Stroke({
					color: selected
						? SELECTION_STROKE_COLOUR
						: MINE_SITE_STROKE_COLOUR,
					width: hover || selected ? 4 : 2,
				}),
				zIndex: selected ? 1000 : undefined,
			});
		},
	}),
);

const REHAB_POLY_STROKE_COLOUR: Color = [255, 255, 255, 1];
const REHAB_POLY_STROKE_FILTERED_COLOUR: Color = [0, 0, 0, 0];

export const RehabPolyLayerAtom = atom(
	new VectorLayer({
		source: new VectorSource(),
		minZoom: 14,
		style: function (feature) {
			const hover = feature.get("hover") as boolean;
			const selected = feature.get("selected") as boolean;
			const filtered = feature.get("filtered") as isFiltered;

			return new Style({
				fill: new Fill({
					color: filtered
						? "rgba(0, 0, 0, 0.6)"
						: "rgba(0, 0, 0, 0.0)",
				}),
				stroke: new Stroke({
					color: getColour("rehab", selected, filtered),
					width: hover || selected ? 4 : filtered === false ? 0 : 3,
					lineCap: "butt",
					lineJoin: "miter",
				}),
				zIndex: selected ? 1000 : undefined,
			});
		},
	}),
);

const ANALOGUE_POLY_STROKE_COLOUR: Color = [255, 216, 40, 1];
const ANALOGUE_POLY_STROKE_FILTERED_COLOUR: Color = [255, 216, 40, 0.4];

export const AnaloguePolyLayerAtom = atom(
	new VectorLayer({
		source: new VectorSource(),
		minZoom: 14,
		style: function (feature) {
			const hover = feature.get("hover") as boolean;
			const selected = feature.get("selected") as boolean;
			const filtered = feature.get("filtered") as isFiltered;

			return new Style({
				fill: new Fill({
					color: "rgba(255, 255, 255, 0)",
				}),
				stroke: new Stroke({
					color: getColour("analogues", selected, filtered),
					width: hover || selected ? 4 : 2,
					lineDash: [8, 6],
				}),
				zIndex: selected ? 1000 : undefined,
			});
		},
	}),
);

const CLUSTER_MIN_RADIUS = 20;
const CLUSTER_MAX_RADIUS = 28;

const DefaultPointStyle = (
	geometryStrokeColor: string | Color,
	geometryFillColor: string | Color,
) => {
	return new Style({
		image: new Circle({
			radius: 8,
			stroke: new Stroke({
				color: geometryStrokeColor,
				width: 2,
			}),
			fill: new Fill({
				color: geometryFillColor,
			}),
		}),
	});
};

export const RehabPolyClusterLayerAtom = atom(
	new VectorLayer({
		source: new Cluster({
			source: new VectorSource(),
			minDistance: 50,
			distance: 50,
		}),
		minZoom: 10,
		maxZoom: 14,
		style: function (feature) {
			const count = feature.get("features").length;
			const hover = !!feature.get("hover");
			const radius = Math.max(
				CLUSTER_MIN_RADIUS,
				Math.min(count * 0.1, CLUSTER_MAX_RADIUS),
			);
			const style = DefaultPointStyle([255, 255, 255, 0.8], "#19B17F");
			const circle = style.getImage() as Circle;
			if (hover) {
				circle.setStroke(
					new Stroke({ color: [255, 255, 255, 1], width: 3 }),
				);
			}
			if (count == 1) {
				return style;
			}
			circle.setRadius(radius);
			style.setText(
				new Text({
					text: count.toString(),
					font: "bold 16px sans-serif",
					fill: new Fill({
						color: "#fff",
					}),
				}),
			);
			return style;
		},
	}),
);

const SAMPLING_SITE_STROKE_COLOUR: Color = [0, 255, 0, 1];
const SAMPLING_SITE_STROKE_FILTERED_COLOUR: Color = [0, 255, 0, 0.4];

export const SamplingSiteLayerAtom = atom(
	new VectorLayer({
		source: new VectorSource(),
		minZoom: 14,
		style: function (feature) {
			const hover = feature.get("hover") as boolean;
			const selected = feature.get("selected") as boolean;
			const filtered = feature.get("filtered") as isFiltered;

			return new Style({
				fill: new Fill({
					color: "rgba(0, 255, 0, 0)",
				}),
				stroke: new Stroke({
					color: getColour("samplingSites", selected, filtered),
					width: hover || selected ? 4 : 2,
				}),
				zIndex: selected ? 1000 : undefined,
			});
		},
	}),
);

export const SiteInspectionLayerAtom = atom(
	new VectorLayer({
		source: new VectorSource(),
		minZoom: 15,
		style: function (feature) {
			const hover = feature.get("hover") as boolean;
			const selected = feature.get("selected") as boolean;
			const filtered = feature.get("filtered") as isFiltered;

			return new Style({
				image: new Circle({
					radius: 8,
					fill: new Fill({
						color: `rgba(47, 128, 237, ${filtered ? 0.4 : 1})`,
					}),
					stroke: new Stroke({
						// color: selected
						// 	? SELECTION_STROKE_COLOUR
						// 	: [255, 255, 255, filtered ? 0.4 : 1],'
						color: getColour("siteInspections", selected, filtered),
						width: hover || selected ? 4 : 1,
					}),
				}),
				zIndex: selected ? 1000 : undefined,
			});
		},
	}),
);

const HEALTH_ZONE_STROKE_COLOUR: Color = [255, 255, 255, 1];

export const HealthZoneLayerAtom = atom(
	new VectorLayer({
		source: new VectorSource(),
		style: function (feature) {
			const hover = feature.get("hover") as boolean;
			const selected = feature.get("selected") as boolean;
			return new Style({
				fill: new Fill({
					color: "rgba(255, 255, 255, 0)",
				}),
				stroke: new Stroke({
					// color: selected
					// 	? SELECTION_STROKE_COLOUR
					// 	: HEALTH_ZONE_STROKE_COLOUR,
					color: getColour("healthZones", selected, false),
					width: hover || selected ? 4 : 1,
				}),
				zIndex: selected ? 1000 : undefined,
			});
		},
	}),
);

const HEALTH_GRID_STROKE_COLOUR: Color = [255, 255, 255, 0.2];
const HEALTH_GRID_FILL_OPACITY: string = "9A"; // 60%

export const HealthGridLayerAtom = atom(
	new VectorLayer({
		source: new VectorSource(),
		minZoom: 15,
		style: function (feature) {
			const colour = feature.get("colour");
			const rgbaColour = colour + HEALTH_GRID_FILL_OPACITY;
			const hover = feature.get("hover") as boolean;
			const selected = feature.get("selected") as boolean;

			return new Style({
				fill: new Fill({
					color: rgbaColour,
				}),
				stroke: new Stroke({
					// color: selected
					// 	? SELECTION_STROKE_COLOUR
					// 	: HEALTH_GRID_STROKE_COLOUR,
					color: getColour("healthGrids", selected, false),
					width: hover || selected ? 4 : 1,
				}),
				zIndex: selected ? 1000 : undefined,
			});
		},
	}),
);

const TOPSOIL_STOCKPILE_STROKE_COLOUR: Color = [222, 93, 53, 1];

export const TopsoilStockpileLayerAtom = atom(
	new VectorLayer({
		source: new VectorSource(),
		minZoom: 14,
		style: function (feature) {
			const hover = feature.get("hover") as boolean;
			const selected = feature.get("selected") as boolean;

			const style1 = new Style({
				fill: new Fill({
					color: "rgba(255, 255, 255, 0.01)",
				}),
				stroke: new Stroke({
					color: [255, 255, 255, 1],
					width: hover || selected ? 4 : 2,
				}),
				zIndex: selected ? 1000 : 1,
				geometry: function (feature) {
					return feature.getGeometry();
				},
			});

			const style2 = new Style({
				stroke: new Stroke({
					color: selected
						? SELECTION_STROKE_COLOUR
						: TOPSOIL_STOCKPILE_STROKE_COLOUR,
					//color: getColour("topsoilStockpiles", selected, false),
					width: hover || selected ? 2 : 1,
					lineDash: [8, 6],
				}),
				zIndex: selected ? 1001 : 2,
				geometry: function (feature) {
					return feature.getGeometry();
				},
			});

			return [style1, style2];
		},
	}),
);

const WEED_GRID_STROKE_COLOUR: Color = [255, 255, 255, 0.2];
const WEED_FILL_OPACITY: string = "9A"; // 60%
export const WeedGridLayerAtom = atom(
	new VectorLayer({
		source: new VectorSource(),
		minZoom: 11,
		style: function (feature) {
			const transparent = feature.get("transparent") as boolean;
			const colour = feature.get("colour");
			const rgbaColour = colour + WEED_FILL_OPACITY;
			const hover = feature.get("hover") as boolean;
			const selected = feature.get("selected") as boolean;

			return new Style({
				fill: new Fill({
					color: transparent ? "#00000001" : rgbaColour,
				}),
				stroke: new Stroke({
					// color: selected
					// 	? SELECTION_STROKE_COLOUR
					// 	: WEED_GRID_STROKE_COLOUR,
					color: getColour("weedGrids", selected, false),
					width: hover || selected ? 4 : 1,
				}),
				zIndex: selected ? 1000 : undefined,
			});
		},
	}),
);

const EROSION_VECTOR_STROKE_COLOUR: Color = [255, 255, 255, 1];
const EROSION_VECTOR_STROKE_FILTERED_COLOUR: Color = [255, 255, 255, 0.4];

type isFiltered = boolean | undefined;

export const ErosionVectorLayerAtom = atom(
	new VectorLayer({
		source: new VectorSource(),
		minZoom: 16,
		style: function (feature) {
			const hover = feature.get("hover") as boolean;
			const selected = feature.get("selected") as boolean;
			const filtered = feature.get("filtered") as isFiltered;

			return new Style({
				fill: new Fill({
					color: "rgba(255, 255, 255, 0)",
				}),
				stroke: new Stroke({
					// color: selected
					// 	? SELECTION_STROKE_COLOUR
					// 	: filtered
					// 		? EROSION_VECTOR_STROKE_FILTERED_COLOUR
					// 		: EROSION_VECTOR_STROKE_COLOUR,
					color: getColour("erosionVectors", selected, filtered),
					width: hover || selected ? 4 : 1,
				}),
				zIndex: selected ? 1000 : undefined,
			});
		},
	}),
);

export const ErosionClusterLayerAtom = atom(
	new VectorLayer({
		source: new Cluster({
			source: new VectorSource(),
			minDistance: 50,
			distance: 50,
		}),
		minZoom: 10,
		maxZoom: 16,
		style: function (feature) {
			const count = feature.get("features").length;
			const hover = !!feature.get("hover");
			const radius = Math.max(
				CLUSTER_MIN_RADIUS,
				Math.min(count * 0.1, CLUSTER_MAX_RADIUS),
			);
			const style = DefaultPointStyle([255, 255, 255, 0.8], "#ffa857");
			const circle = style.getImage() as Circle;
			if (hover) {
				circle.setStroke(
					new Stroke({ color: [255, 255, 255, 1], width: 3 }),
				);
			}
			if (count == 1) {
				return style;
			}
			circle.setRadius(radius);
			style.setText(
				new Text({
					text: count.toString(),
					font: "bold 16px sans-serif",
					fill: new Fill({
						color: "#fff",
					}),
				}),
			);
			return style;
		},
	}),
);

const BARE_AREAS_VECTOR_STROKE_COLOUR: Color = [255, 255, 255, 1];

export const BareAreasVectorLayerAtom = atom(
	new VectorLayer({
		source: new VectorSource(),
		minZoom: 14,
		style: function (feature) {
			const hover = feature.get("hover") as boolean;
			const selected = feature.get("selected") as boolean;

			return new Style({
				fill: new Fill({
					color: "rgba(255, 255, 255, 0)",
				}),
				stroke: new Stroke({
					// color: selected
					// 	? SELECTION_STROKE_COLOUR
					// 	: BARE_AREAS_VECTOR_STROKE_COLOUR,
					color: getColour("bareAreas", selected, false),
					width: hover || selected ? 4 : 1,
					lineDash: [8, 4],
				}),
				zIndex: selected ? 1000 : undefined,
			});
		},
	}),
);

const INDIVIDUAL_TREE_STROKE_COLOUR: Color = [255, 255, 255, 0.6];
const INDIVIDUAL_TREE_FILL_COLOR: Color = [128, 0, 128, 0.2];

export const IndividualTreeLayerAtom = atom(
	new VectorLayer({
		source: new VectorSource(),
		minZoom: 14,
		style: function (feature) {
			const hover = feature.get("hover") as boolean;
			const selected = feature.get("selected") as boolean;

			return new Style({
				fill: new Fill({
					color: INDIVIDUAL_TREE_FILL_COLOR,
				}),
				stroke: new Stroke({
					color: getColour("individualTrees", selected, false),
					width: hover || selected ? 4 : 1,
				}),
				zIndex: selected ? 1000 : undefined,
			});
		},
	}),
);

const INCLUDED_STROKE_COLOUR: Color = [255, 255, 255, 1];

type LayerType =
	| "rehab"
	| "siteInspections"
	| "analogues"
	| "samplingSites"
	| "healthZones"
	| "healthGrids"
	| "topsoilStockpiles"
	| "weedGrids"
	| "erosionVectors"
	| "bareAreas"
	| "individualTrees";

type ColorState = "selected" | "included" | "filtered" | "default";

type ColorConfig = {
	[key in ColorState]: Color;
};

type ColorMap = {
	[key in LayerType]: ColorConfig;
};

const COLOUR_MAP: ColorMap = {
	rehab: {
		selected: SELECTION_STROKE_COLOUR,
		included: INCLUDED_STROKE_COLOUR,
		filtered: REHAB_POLY_STROKE_FILTERED_COLOUR,
		default: REHAB_POLY_STROKE_COLOUR,
	},
	siteInspections: {
		selected: SELECTION_STROKE_COLOUR,
		included: INCLUDED_STROKE_COLOUR,
		filtered: REHAB_POLY_STROKE_FILTERED_COLOUR,
		default: REHAB_POLY_STROKE_COLOUR,
	},
	analogues: {
		selected: SELECTION_STROKE_COLOUR,
		included: INCLUDED_STROKE_COLOUR,
		filtered: ANALOGUE_POLY_STROKE_FILTERED_COLOUR,
		default: ANALOGUE_POLY_STROKE_COLOUR,
	},
	samplingSites: {
		selected: SELECTION_STROKE_COLOUR,
		included: INCLUDED_STROKE_COLOUR,
		filtered: SAMPLING_SITE_STROKE_FILTERED_COLOUR,
		default: SAMPLING_SITE_STROKE_COLOUR,
	},
	healthZones: {
		selected: SELECTION_STROKE_COLOUR,
		included: INCLUDED_STROKE_COLOUR,
		filtered: HEALTH_ZONE_STROKE_COLOUR,
		default: HEALTH_ZONE_STROKE_COLOUR,
	},
	healthGrids: {
		selected: SELECTION_STROKE_COLOUR,
		included: INCLUDED_STROKE_COLOUR,
		filtered: HEALTH_GRID_STROKE_COLOUR,
		default: HEALTH_GRID_STROKE_COLOUR,
	},
	topsoilStockpiles: {
		selected: SELECTION_STROKE_COLOUR,
		included: INCLUDED_STROKE_COLOUR,
		filtered: TOPSOIL_STOCKPILE_STROKE_COLOUR,
		default: TOPSOIL_STOCKPILE_STROKE_COLOUR,
	},
	weedGrids: {
		selected: SELECTION_STROKE_COLOUR,
		included: INCLUDED_STROKE_COLOUR,
		filtered: WEED_GRID_STROKE_COLOUR,
		default: WEED_GRID_STROKE_COLOUR,
	},
	erosionVectors: {
		selected: SELECTION_STROKE_COLOUR,
		included: INCLUDED_STROKE_COLOUR,
		filtered: EROSION_VECTOR_STROKE_FILTERED_COLOUR,
		default: EROSION_VECTOR_STROKE_COLOUR,
	},
	bareAreas: {
		selected: SELECTION_STROKE_COLOUR,
		included: INCLUDED_STROKE_COLOUR,
		filtered: BARE_AREAS_VECTOR_STROKE_COLOUR,
		default: BARE_AREAS_VECTOR_STROKE_COLOUR,
	},
	individualTrees: {
		selected: SELECTION_STROKE_COLOUR,
		included: INCLUDED_STROKE_COLOUR,
		filtered: INDIVIDUAL_TREE_STROKE_COLOUR,
		default: INDIVIDUAL_TREE_STROKE_COLOUR,
	},
};

const getColour = (
	type: LayerType,
	selected: boolean,
	filtered: isFiltered,
): Color => {
	if (selected) return COLOUR_MAP[type].selected;
	if (filtered === undefined) return COLOUR_MAP[type].default;
	return COLOUR_MAP[type][filtered ? "filtered" : "included"];
};
