import client from "@kubb/plugin-client/client";
import type { WeedsSurveysGetWeedGridCellCoversQueryResponse, WeedsSurveysGetWeedGridCellCoversQueryParams } from "../../ts/WeedsSurveysGetWeedGridCellCovers.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/WeedsSurveys/gridCellCovers}
 */
export async function weedsSurveysGetWeedGridCellCovers(params?: WeedsSurveysGetWeedGridCellCoversQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<WeedsSurveysGetWeedGridCellCoversQueryResponse, Error, unknown>({ method: "GET", url: `/api/WeedsSurveys/gridCellCovers`, params, ...config });
    return res.data;
}