import { AreaUnits, Units } from "@turf/helpers";
import React from "react";

import { IconName } from "@/components/icons";
import {
	getAreaUnitShorthand,
	getLengthUnitShorthand,
} from "@/helpers/unit-helpers";
import { Comparator, ComparatorEnum, ValueWithLabel } from "@/lib/gen/eis";

export type FilterItemType =
	| "string"
	| "number"
	| "distance"
	| "area"
	| "percentage"
	| "date"
	| "enum"
	| "boolean"
	| "guid";

export type ColumnFilter<T extends string> = {
	filter: ColumnFilterOption<T>;
	values: ValueWithLabel[];
	comparator?: Comparator;
};

export interface ColumnFilterOption<T extends string> {
	/** Generally the name of an argument in a query. */
	key: T;
	/** The key for a map if the `key` is a map of key-values. */
	subKey?: string;
	label?: string;
	/** Optional group name (will show header/separator in suggestion command list) */
	group?: string;
	icon?: IconName;
	iconClassName?: string;
	iconColor?: string;
	options?:
		| ValueWithLabel[]
		| ((searchTerm?: string) => Promise<ValueWithLabel[]>);
	/** (default: string) */
	type?: FilterItemType;
	/** If `type` is distance/area; this defines what the units are in (if not set will default to "metres"). */
	sourceUnits?: {
		distance?: Units;
		area?: AreaUnits;
	};
	/** If `type` is distance/area; this defines what the units should be used on the frontend (if not set will use `sourceUnits`). */
	defaultUnits?: {
		distance?: Units;
		area?: AreaUnits;
	};
	/** Search keywords */
	keywords?: string[];
}

export const COMPARATOR_LABELS: Record<Comparator, string> = {
	EQUALS: "=",
	NOT_EQUALS: "!=",
	GREATER_THAN: ">",
	LESS_THAN: "<",
	GREATER_THAN_OR_EQUAL: ">=",
	LESS_THAN_OR_EQUAL: "<=",
	IN: "in",
	NOT_IN: "not in",
	CONTAINS: "contains",
	DOES_NOT_CONTAIN: "does not contain",
	STARTS_WITH: "starts with",
	DOES_NOT_START_WITH: "does not start with",
	ENDS_WITH: "ends with",
	DOES_NOT_END_WITH: "does not end with",
};

export const parseColumnName = (name: string) =>
	name.replace("editor_", "").replace("_", " ");

export interface FilterItemOption {
	value: string;
	node: React.ReactNode;
}

const COMPARATORS_NUMBERS: Comparator[] = [
	ComparatorEnum.EQUALS,
	ComparatorEnum.NOT_EQUALS,
	ComparatorEnum.GREATER_THAN,
	ComparatorEnum.LESS_THAN,
	ComparatorEnum.GREATER_THAN_OR_EQUAL,
	ComparatorEnum.LESS_THAN_OR_EQUAL,
	ComparatorEnum.IN,
	ComparatorEnum.NOT_IN,
];

const COMPARATORS_STRING: Comparator[] = [
	ComparatorEnum.EQUALS,
	ComparatorEnum.NOT_EQUALS,

	ComparatorEnum.CONTAINS,
	ComparatorEnum.DOES_NOT_CONTAIN,
	ComparatorEnum.STARTS_WITH,
	ComparatorEnum.DOES_NOT_START_WITH,
	ComparatorEnum.ENDS_WITH,
	ComparatorEnum.DOES_NOT_END_WITH,

	ComparatorEnum.IN,
	ComparatorEnum.NOT_IN,
];

const COMPARATORS_DATE: Comparator[] = [
	ComparatorEnum.EQUALS,
	ComparatorEnum.NOT_EQUALS,
	ComparatorEnum.GREATER_THAN,
	ComparatorEnum.LESS_THAN,
	ComparatorEnum.GREATER_THAN_OR_EQUAL,
	ComparatorEnum.LESS_THAN_OR_EQUAL,
];

const COMPARATORS_BASIC: Comparator[] = [
	ComparatorEnum.EQUALS,
	ComparatorEnum.NOT_EQUALS,
	ComparatorEnum.IN,
	ComparatorEnum.NOT_IN,
];

const COMPARATORS_EXCLUDE_SUGGESTIONS: Comparator[] = [
	ComparatorEnum.CONTAINS,
	ComparatorEnum.DOES_NOT_CONTAIN,
	ComparatorEnum.STARTS_WITH,
	ComparatorEnum.DOES_NOT_START_WITH,
	ComparatorEnum.ENDS_WITH,
	ComparatorEnum.DOES_NOT_END_WITH,
];

export const FilterItemComparators: Record<FilterItemType, Comparator[]> = {
	string: COMPARATORS_STRING,
	number: COMPARATORS_NUMBERS,
	distance: COMPARATORS_NUMBERS,
	area: COMPARATORS_NUMBERS,
	percentage: COMPARATORS_NUMBERS,
	date: COMPARATORS_DATE,
	enum: COMPARATORS_BASIC,
	guid: COMPARATORS_BASIC,
	boolean: [ComparatorEnum.EQUALS],
};

export const isComparatorMultiSelect = (comparator?: Comparator) =>
	comparator === "IN" || comparator === "NOT_IN";

export const isFilterItemTypeNumberInput = (type?: FilterItemType) =>
	type === "number" ||
	type === "distance" ||
	type === "area" ||
	type === "percentage";

export function isFilterItemTypeTextInput<T extends string>(
	item: ColumnFilter<T>,
) {
	return (
		(item.filter.type === "string" ||
			isFilterItemTypeNumberInput(item.filter.type) ||
			item.filter.type === "date") &&
		(item.filter.options == null ||
			COMPARATORS_EXCLUDE_SUGGESTIONS.indexOf(
				item.comparator ?? ComparatorEnum.EQUALS,
			) !== -1)
	);
}

export interface ValueRendererProps {
	value: string;
	onChange: (value: string) => void;
}

export function getColumnFilterOptionUnitShorthand<T extends string>(
	option: ColumnFilterOption<T>,
) {
	if (option.type === "area")
		return (
			getAreaUnitShorthand(
				option.defaultUnits?.area ?? option.sourceUnits?.area,
			) ?? "m²"
		);
	else if (option.type === "distance")
		return (
			getLengthUnitShorthand(
				option.defaultUnits?.distance ?? option.sourceUnits?.distance,
			) ?? "m"
		);
	return "";
}

export function setFilterValue<T extends string>(
	filters: ColumnFilter<T>[],
	value: ValueWithLabel,
	multi: boolean = false,
) {
	if (multi) {
		filters[filters.length - 1].values.push(value);
	} else {
		filters[filters.length - 1].values = [value];
	}
	return [...filters];
}
