import client from "@kubb/plugin-client/client";
import type { RiparianSurveyGetRiparianGridAnalysisQueryResponse, RiparianSurveyGetRiparianGridAnalysisQueryParams } from "../../ts/RiparianSurveyGetRiparianGridAnalysis.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/RiparianSurvey/gridanalysis}
 */
export async function riparianSurveyGetRiparianGridAnalysis(params?: RiparianSurveyGetRiparianGridAnalysisQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<RiparianSurveyGetRiparianGridAnalysisQueryResponse, Error, unknown>({ method: "GET", url: `/api/RiparianSurvey/gridanalysis`, params, ...config });
    return res.data;
}