import { atom, ExtractAtomValue } from "jotai";
import { RESET } from "jotai/utils";
import { atomEffect } from "jotai-effect";
import { atomWithHash } from "jotai-location";
import { atomWithMutation, atomWithQuery } from "jotai-tanstack-query";

import {
	CreateSeedApplicationCommand,
	CreateTargetSpeciesDto,
	rehabilitationPolygonsGetVegetationCommunities,
	seedApplicationsCreate,
	seedApplicationsGetSeedApplicationForAllocate,
	seedApplicationsGetSeedApplicationForPlan,
	seedApplicationTemplatesGetSeedApplicationTemplateForPlan,
} from "@/lib/gen/eis";
import { HashKeys } from "@/lib/hash-keys";

import {
	ActiveTabAtom,
	DrawerIsOpenAtom,
	DrawerTabEnum,
} from "./bottomDrawerAtoms";
import { CurrentViewAtom } from "./eisViewAtoms";

const _seedApplicationIdAtom = atomWithHash<string | undefined>(
	HashKeys.SeedAppId,
	undefined,
);
export const SeedApplicationIdAtom = atom(
	(get) => get(_seedApplicationIdAtom),
	(_get, set, id?: string) => {
		if (!id) {
			set(_seedApplicationIdAtom, RESET);
			return;
		}
		set(_seedApplicationIdAtom, id);
		set(CurrentViewAtom, "SeedApplication");
		set(DrawerIsOpenAtom, true);
		set(SeedAppTemplateIndexAtom, 0);
		set(SeedAppTemplateTypeAtom, "veg");
	},
);

export const SeedApplicationForPlanQueryAtom = atomWithQuery((get) => {
	const seedApplicationId = get(SeedApplicationIdAtom);
	return {
		queryKey: GetSeedApplicationQueryKeyBase(
			seedApplicationId,
			"seedApplicationsGetSeedApplicationForPlan",
		),
		queryFn: async () => {
			return seedApplicationsGetSeedApplicationForPlan(
				seedApplicationId!,
			);
		},
		enabled: !!seedApplicationId,
	};
});

/**
 * Query Key that can be used to invalidate all seed app related queries.
 * ie. After editing a value all related queries can be refreshed.
 */
export const GetSeedApplicationQueryKeyBase = (
	seedApplicationId: string | undefined,
	...otherKeys: unknown[]
) => {
	return ["seedApplication", seedApplicationId, ...otherKeys];
};

export const SeedAppTemplateIndexAtom = atom(0);
export const SeedAppTemplateTypeAtom = atom<"veg" | "saved">("veg");

export const SeedApplicationTemplateAtom = atom((get) => {
	const seedApplicationId = get(SeedApplicationIdAtom);
	const index = get(SeedAppTemplateIndexAtom);
	if (seedApplicationId && index == 0) {
		get(_seedApplicationPolygonAtomEffect);
		const query = get(SeedApplicationForPlanQueryAtom);
		return {
			...query,
			data: query.data?.vegetationCommunity,
		};
	}
	const type = get(SeedAppTemplateTypeAtom);
	if (type === "veg") {
		const query = get(SeedApplicationBaselineTemplatesQueryAtom);
		return {
			...query,
			data: query.data?.vegetationCommunities?.[index],
		};
	}
	const query = get(SeedApplicationSavedTemplatesQueryAtom);
	const data = query.data?.[index];
	return {
		...query,
		data: data
			? { ...data.vegetationCommunity, name: data.name }
			: undefined,
	};
});

export const SeedAppPredictedCriteriaAtom = atom((get) => {
	const query = get(SeedApplicationTemplateAtom);
	return {
		...query,
		data: query.data?.criteriaResults,
	};
});

export const SeedAppFloraSpeciesAtom = atom((get) => {
	const query = get(SeedApplicationTemplateAtom);
	return {
		...query,
		data: query.data?.vegetationCommunityFloraSpeciesDtos,
	};
});

export const SeedApplicationPolygonIdAtom = atomWithHash<string | undefined>(
	HashKeys.SeedAppPolygonId,
	undefined,
);
const _seedApplicationPolygonAtomEffect = atomEffect((get, set) => {
	const { data } = get(SeedApplicationForPlanQueryAtom);
	if (data?.rehabilitationPolygonId) {
		set(SeedApplicationPolygonIdAtom, data.rehabilitationPolygonId);
	}
});

export const CreateSeedApplicationAtom = atom(
	null,
	(_get, set, polygonId: string) => {
		set(SeedApplicationIdAtom, undefined);
		set(SeedApplicationPolygonIdAtom, polygonId);
		set(CurrentViewAtom, "SeedApplication");
		set(DrawerIsOpenAtom, true);
	},
);

export const SeedApplicationBaselineTemplatesQueryAtom = atomWithQuery(
	(get) => {
		const polygonId = get(SeedApplicationPolygonIdAtom);
		const seedApplicationId = get(SeedApplicationIdAtom);
		return {
			queryKey: [
				"rehabilitationPolygonsGetVegetationCommunities",
				polygonId,
			],
			queryFn: async () => {
				return rehabilitationPolygonsGetVegetationCommunities(
					polygonId!,
					{
						RehabilitationPolygonId: polygonId,
						SamplingDistance: 10000,
					},
				);
			},
			enabled: !!polygonId && !seedApplicationId,
		};
	},
);

export const SeedApplicationSavedTemplatesQueryAtom = atomWithQuery((get) => {
	const polygonId = get(SeedApplicationPolygonIdAtom);
	const seedApplicationId = get(SeedApplicationIdAtom);
	return {
		queryKey: [
			"seedApplicationTemplatesGetSeedApplicationTemplateForPlan",
			polygonId,
		],
		queryFn: async () => {
			return seedApplicationTemplatesGetSeedApplicationTemplateForPlan(
				polygonId!,
				{
					RehabPolygonId: polygonId,
				},
			);
		},
		enabled: !!polygonId && !seedApplicationId,
	};
});

export const SeedApplicationTemplatesCountAtom = atom((get) => {
	const type = get(SeedAppTemplateTypeAtom);
	if (type === "veg") {
		const { data, isLoading } = get(
			SeedApplicationBaselineTemplatesQueryAtom,
		);
		if (isLoading) return 0;
		if (!data?.vegetationCommunities) return 0;
		return data.vegetationCommunities.length;
	}
	const { data, isLoading } = get(SeedApplicationSavedTemplatesQueryAtom);
	if (isLoading) return 0;
	if (!data) return 0;
	return data.length;
});

export const CreateSeedAppMutationAtom = atomWithMutation((get) => {
	const polygonId = get(SeedApplicationPolygonIdAtom);
	const { data: template } = get(SeedApplicationTemplateAtom);
	return {
		mutationFn: async () => {
			const command = getCreateSeedApplicationCommand(
				template,
				polygonId,
				undefined,
			);
			return seedApplicationsCreate(command);
		},
	};
});

type TemplateDto = ExtractAtomValue<typeof SeedApplicationTemplateAtom>["data"];

export const getCreateSeedApplicationCommand = (
	template: TemplateDto,
	rehabPolygonId?: string,
	polygonFeatureId?: string,
	seedApplicationTemplateId?: string,
) => {
	if (!rehabPolygonId && !polygonFeatureId) {
		throw new Error("No rehabPolygonId or polygonFeatureId provided");
	}
	if (!template) {
		throw new Error("No template provided");
	}
	const command: CreateSeedApplicationCommand = {
		rehabPolygonId: rehabPolygonId,
		polygonFeatureId: polygonFeatureId,
		seedApplicationTemplateId: seedApplicationTemplateId,
		vegetationCommunityId: template.id,
		startDate: undefined,
		endDate: undefined,
		name: undefined,
		method: undefined,
		seedingDepth: undefined,
		applicationNotes: undefined,
		lifeformTargetCovers: template.lifeformDtos,
		baselineQuadratIds:
			template.baselineQuadrats?.flatMap((bs) => {
				if (bs.id) {
					return [bs.id];
				} else return [];
			}) ?? [],
		analoguePolygonIds:
			"analoguePolygons" in template
				? template.analoguePolygons?.flatMap((ap) => {
						if (ap.id) {
							return [ap.id];
						} else return [];
					}) ?? []
				: [],

		targetSpecies: template.vegetationCommunityFloraSpeciesDtos?.map(
			(s): CreateTargetSpeciesDto => {
				return {
					targetFloraSpeciesId: s.floraSpeciesId,
					targetCover: s.targetCover,
					suggestedQuantity: s.estimatedQtyDetails?.estimatedQuantity,
					suggestedSeedCount:
						s.estimatedQtyDetails?.suggestedSeedCount,
					suggestedSeedQuantityError:
						s.estimatedQtyDetails?.suggestedSeedQuantityError,
					establishmentRate: s.establishmentRate,
				};
			},
		),
	};

	return command;
};

const _seedAppAllocateStageQueryAtom = atomWithQuery((get) => {
	const seedApplicationId = get(SeedApplicationIdAtom);
	const tab = get(ActiveTabAtom);
	return {
		queryKey: GetSeedApplicationQueryKeyBase(
			seedApplicationId,
			"seedApplicationsGetSeedApplicationForAllocate",
		),
		queryFn: async () => {
			return seedApplicationsGetSeedApplicationForAllocate(
				seedApplicationId!,
			);
		},
		enabled:
			!!seedApplicationId &&
			tab?.name === DrawerTabEnum.SEED_APP_ALLOCATE,
	};
});

export const SeedsToAllocateAtom = atom((get) => {
	const query = get(_seedAppAllocateStageQueryAtom);
	return {
		...query,
		data: query.data?.seedApplicationSpecies,
	};
});

export const SeedAppDetailsAtom = atom((get) => {
	const query = get(_seedAppAllocateStageQueryAtom);
	return {
		...query,
		data: query.data,
	};
});
