import { LazyErosionFeatureSummaryCard } from "./Erosion/ErosionFeatureSummaryCard/lazy-card";
import {
	LazyHealthMetricsCard,
	LazyIndividualTreeSummaryCard,
} from "./ModuleCards/Health/lazy-cards";
import {
	LazyStockpileMovementsCard,
	LazyStockpileSummaryCard,
	LazyStockpilesWeedAlertsCard,
	LazyTopsoilRequirementsCard,
	LazyTopsoilSummaryCard,
} from "./ModuleCards/Topsoil/lazy-cards";
import { LazyCompletionCriteriaCard } from "./Monitoring/CompletionCriteriaCard/lazy-completion-criteria-card";
import { LazySamplingSiteObservationsCard } from "./Monitoring/Onground/SamplingSiteObservationCard/lazy-card";
import { LazySamplingSiteSummaryCard } from "./Monitoring/Onground/SamplingSiteSummaryCard/lazy-card";
import { LazySiteInspectionSummaryCard } from "./Monitoring/Onground/SiteInspectionSummaryCard/lazy-card";
import { LazySiteDetailsCard } from "./Monitoring/SiteDetailsCard/lazy-site-details-card";
import { LazyAnaloguePolySummaryCard } from "./Polygon/AnaloguePolySummaryCard/lazy-card";
import { LazyAnaloguePolyVegCoverCard } from "./Polygon/AnaloguePolyVegCoverCard/lazy-card";
import { LazyRehabPolyCriteriaCard } from "./Polygon/RehabPolyCriteriaCard/lazy-card";
import { LazyRehabPolyErosionCard } from "./Polygon/RehabPolyErosionCard/lazy-card";
import { LazyRehabPolySummaryCard } from "./Polygon/RehabPolySummaryCard/lazy-card";
import { LazyRehabPolyTreatmentsCard } from "./Polygon/RehabPolyTreatmentsCard/lazy-card";
import { LazyRehabPolyVegCoverCard } from "./Polygon/RehabPolyVegCoverCard/lazy-card";
import {
	LazyRiparianGridCellSummaryCard,
	LazyRiparianSystemSummaryCard,
	LazyRiparianZoneSummaryCard,
} from "./Riparian/lazy-cards";
import {
	LazyRehabPolySeedCard,
	LazySeedAppCriteriaCard,
	LazySeedAppPlanCard,
	LazySeedAppSelectorCard,
	LazySeedAppTemplateCard,
	LazySeedAppVegCoverCard,
	LazySeedInventoryCard,
	LazySiteSeedDetailsCard,
} from "./Seeds/lazy-cards";
import {
	LazyWeedsGridCellSummaryCard,
	LazyWeedsGridCellVegCoversCard,
	LazyWeedsSummaryCard,
} from "./Weeds/lazy-cards";

export const CardPanelRight = () => {
	return (
		<div className="flex min-w-[296px] max-w-[296px] flex-col gap-1 p-1">
			<LazySiteInspectionSummaryCard />
			<LazySiteDetailsCard />
			{/* Erosion */}
			<LazyRehabPolyErosionCard />
			<LazyErosionFeatureSummaryCard />
			{/* Monitoring */}
			<LazySamplingSiteSummaryCard />
			<LazySamplingSiteObservationsCard />
			<LazyRehabPolySummaryCard />
			<LazyRehabPolyCriteriaCard />
			<LazyRehabPolyTreatmentsCard />
			<LazyRehabPolyVegCoverCard />
			<LazyAnaloguePolySummaryCard />
			<LazyAnaloguePolyVegCoverCard />
			<LazyCompletionCriteriaCard />
			{/* Health */}
			<LazyRiparianSystemSummaryCard />
			<LazyRiparianGridCellSummaryCard />
			<LazyRiparianZoneSummaryCard />
			<LazyIndividualTreeSummaryCard />
			<LazyHealthMetricsCard />
			{/* Weeds */}
			<LazyWeedsSummaryCard />
			<LazyWeedsGridCellSummaryCard />
			<LazyWeedsGridCellVegCoversCard />
			{/* Seeds */}
			<LazySiteSeedDetailsCard />
			<LazySeedInventoryCard />
			<LazyRehabPolySeedCard />
			{/* SeedApplication */}
			<LazySeedAppPlanCard />
			<LazySeedAppSelectorCard />
			<LazySeedAppTemplateCard />
			<LazySeedAppVegCoverCard />
			<LazySeedAppCriteriaCard />
			{/* Topsoil */}
			<LazyTopsoilSummaryCard />
			<LazyTopsoilRequirementsCard />
			<LazyStockpilesWeedAlertsCard />
			<LazyStockpileSummaryCard />
			<LazyStockpileMovementsCard />
		</div>
	);
};
