import { SiteInspectionArg } from "@/atoms/siteInspectionAtoms";
import { ColumnFilterOption } from "@/components/filtered-search-box/filter-search-box-types";
import { PredicateFilterTypeEnum } from "@/lib/gen/eis";
import { getTypeAheadResults } from "@/services/predicateBuilder.service";

export const SITE_INSPECTION_FILTER_OPTION_MINE_SITE: ColumnFilterOption<SiteInspectionArg> =
	{
		key: "mineSiteName",
		label: "Site",
		icon: "site",
		options: (searchTerm?: string) =>
			getTypeAheadResults(
				PredicateFilterTypeEnum.mineSiteName,
				searchTerm,
			),
		keywords: ["mine", "name"],
		type: "string",
	};
export const SITE_INSPECTION_FILTER_OPTION_MINING_REGION_NAME: ColumnFilterOption<SiteInspectionArg> =
	{
		key: "miningRegionName",
		label: "Region",
		icon: "region",
		options: (searchTerm?: string) =>
			getTypeAheadResults(
				PredicateFilterTypeEnum.miningRegionName,
				searchTerm,
			),
		keywords: ["mine", "name"],
		type: "string",
	};
export const SITE_INSPECTION_FILTER_OPTION_REHAB_POLYGON_NAME: ColumnFilterOption<SiteInspectionArg> =
	{
		key: "rehabilitationPolygonName",
		label: "Rehabilitation Polygon Name",
		icon: "polygon",
		options: (searchTerm?: string) =>
			getTypeAheadResults(
				PredicateFilterTypeEnum.polygonName,
				searchTerm,
			),
		type: "string",
	};

export const SITE_INSPECTION_FILTER_OPTIONS: ColumnFilterOption<SiteInspectionArg>[] =
	[
		{
			key: "inspectionName",
			label: "Name",
			icon: "site_inspection",
			options: (searchTerm?: string) =>
				getTypeAheadResults(
					PredicateFilterTypeEnum.siteInspectionName,
					searchTerm,
				),
			type: "string",
		},
		SITE_INSPECTION_FILTER_OPTION_MINE_SITE,
		SITE_INSPECTION_FILTER_OPTION_MINING_REGION_NAME,
		SITE_INSPECTION_FILTER_OPTION_REHAB_POLYGON_NAME,
		{
			key: "criterion",
			label: "Criterion",
			icon: "completion_criteria",
			options: (searchTerm?: string) =>
				getTypeAheadResults(
					PredicateFilterTypeEnum.criterion,
					searchTerm,
				),
			type: "string",
		},
		{
			key: "observation",
			label: "Observation",
			icon: "Text",
			type: "string",
		},
		{
			key: "observerName",
			label: "Observer Name",
			icon: "Text",
			type: "string",
		},
		{
			key: "recommendation",
			label: "Recommendation",
			icon: "Text",
			type: "string",
		},
		{
			key: "inspectionDate",
			label: "Inspection Date",
			icon: "Clock",
			type: "date",
		},
		{
			key: "imageCount",
			label: "Photo Count",
			icon: "FileImage",
			type: "number",
			keywords: ["picture", "image", "amount"],
		},
	];
