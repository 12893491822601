import { useMutation, useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { EditIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

import { DateFormControl } from "@/components/form/date-form-control";
import { EnumFormControl } from "@/components/form/enum-form-control";
import { ZodResolverWrapper } from "@/components/form/helper";
import { SelectFloraSpeciesFormField } from "@/components/form/select-form-fields/select-flora-species";
import { SelectProvenanceZoneFormField } from "@/components/form/select-form-fields/select-provenance-zone";
import { SelectSridFormField } from "@/components/form/select-form-fields/select-srid";
import { SelectStorageFormField } from "@/components/form/select-form-fields/select-storage";
import { SelectSupplierFormField } from "@/components/form/select-form-fields/select-supplier";
import {
	LargeTextInputFormField,
	NumberInputFormField,
	PercentInputFormField,
	TextInputFormField,
} from "@/components/form/view-or-edit-form-field";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Form } from "@/components/ui/form";
import {
	CreateSeedBatchCommand,
	createSeedBatchCommandSchema,
	ProvenanceLandformEnum,
	seedBatchesCreate,
	seedBatchesUpdate,
	SeedBatchGerminationTestCreateBatchDto,
	SeedBatchGerminationTestDto,
	SeedBatchSingleDto,
	UpdateSeedBatchCommand,
} from "@/lib/gen/eis";

type SeedBatchFormType = z.infer<typeof createSeedBatchCommandSchema>;

type Props = {
	seedBatchId?: string;
	seedBatch?: SeedBatchSingleDto;
};

export const SeedBatchForm = ({ seedBatchId, seedBatch }: Props) => {
	const queryClient = useQueryClient();
	const [mode, setMode] = useState<"view" | "edit">(
		seedBatchId ? "view" : "edit",
	);
	const form = useForm<SeedBatchFormType>({
		resolver: ZodResolverWrapper(createSeedBatchCommandSchema),
		disabled: mode === "view",
		values: parseSeedBatch(seedBatch),
	});
	const { reset } = form;
	const { mutate: editSeedBatch } = useMutation({
		mutationFn: async (data: UpdateSeedBatchCommand) => {
			return seedBatchesUpdate(data.id, data);
		},
		onSuccess: () => {
			setMode("view");
			queryClient.invalidateQueries({
				queryKey: ["seedBatchesGetSeedBatch", seedBatchId],
			});
			toast.success("Success", {
				description: "Seed Batch updated.",
			});
		},
		onError: () => {
			toast.error("Error", {
				description: "Error updating seed batch.",
			});
		},
	});
	const { mutate: createSeedBatch } = useMutation({
		mutationFn: async (data: CreateSeedBatchCommand) => {
			return seedBatchesCreate(data);
		},
	});

	useEffect(() => {
		if (!!seedBatchId && seedBatch) {
			reset(parseSeedBatch(seedBatch));
		}
	}, [reset, seedBatch, seedBatchId]);

	const onSubmit = form.handleSubmit(
		(data) => {
			console.log("data", data);
			if (!!seedBatchId && !!data?.storageLocationId) {
				editSeedBatch({
					...data,
					id: seedBatchId,
					storageLocationId: data?.storageLocationId,
				});
			} else {
				createSeedBatch(data);
			}
		},
		(error) => {
			console.log("error", error);
			toast.error("Error", {
				description: JSON.stringify(error),
			});
		},
	);

	return (
		<>
			<Form {...form}>
				<form className="flex w-[32rem] flex-1 flex-col gap-3 overflow-y-auto bg-card">
					<Card>
						<CardHeader className="">Seed Details</CardHeader>
						<CardContent className="grid grid-cols-2 gap-2 p-2">
							<SelectFloraSpeciesFormField
								name="floraSpeciesId"
								label="Species"
							/>
							<SelectProvenanceZoneFormField
								name="provenanceZoneId"
								label="Provenance Zone"
							/>
						</CardContent>
					</Card>
					<Card>
						<CardHeader className="">Order Details</CardHeader>
						<CardContent className="grid grid-cols-2 gap-2 p-2">
							<SelectSupplierFormField
								name="supplierId"
								label="Supplier"
							/>
							<TextInputFormField
								name="poNumber"
								label="PO Number"
								description="Purchase Order Number"
							/>
							<NumberInputFormField
								name="initialQuantity"
								label="Quantity Received"
								affix="suffix"
							>
								g
							</NumberInputFormField>
							<NumberInputFormField
								name="cost"
								label="Purchase Cost"
								affix="prefix"
							>
								$
							</NumberInputFormField>
							<TextInputFormField
								name="seedSupplierIdentifier"
								label="Supplier Batch ID"
							/>
						</CardContent>
					</Card>
					<Card>
						<CardHeader className="">Storage Details</CardHeader>
						<CardContent className="grid grid-cols-2 gap-2 p-2">
							<SelectStorageFormField
								name="storageLocationId"
								label="Storage Location"
							/>
							<TextInputFormField
								name="shelfLocation"
								label="Shelf Location"
							/>
						</CardContent>
					</Card>
					<Card>
						<CardHeader className="">
							Seed Testing Details
						</CardHeader>
						<CardContent className="grid grid-cols-4 gap-2 p-2">
							<TextInputFormField
								name="germinationTest.conductedBy"
								label="Tested By"
								className="col-span-2"
							/>
							<DateFormControl
								name="germinationTest.testDate"
								label="Test Date"
								className="col-span-2"
							/>
							<PercentInputFormField
								name="germinationTest.purity"
								label="Purity"
							/>
							<PercentInputFormField
								name="germinationTest.viability"
								label="Viability"
							/>
							<PercentInputFormField
								name="germinationTest.germinationRate"
								label="Germination Rate"
							/>
							<NumberInputFormField
								name="germinationTest.seedsPerGram"
								label="Seeds Per Gram"
							/>
							<TextInputFormField
								name="germinationTest.note"
								label="Note"
								className="col-span-2"
							/>
							<TextInputFormField
								name="germinationTest.seedTestIdentifier"
								label="Seed Test Identifier"
								className="col-span-2"
							/>
						</CardContent>
					</Card>
					<Card>
						<CardHeader className="">Collection Details</CardHeader>
						<CardContent className="grid grid-cols-4 gap-2 p-2">
							<EnumFormControl
								name="provenanceLandforms"
								label="Provenance Landforms"
								enumValue={ProvenanceLandformEnum}
								className="col-span-2"
								multi
							/>
							<DateFormControl
								name="collectionDate"
								label="Collection Date"
								className="col-span-2"
							/>
							<SelectSridFormField
								name="collectionPoint.srid"
								label="Projection System"
								description="SRID"
								className="col-span-2"
							/>
							<NumberInputFormField
								name="collectionPoint.x"
								label="X"
								description="eg. Longitude"
							/>
							<NumberInputFormField
								name="collectionPoint.y"
								label="Y"
								description="eg. Latitude"
							/>
						</CardContent>
					</Card>
					<Card>
						<CardHeader className="">
							Additional Information
						</CardHeader>
						<CardContent className="grid grid-cols-1 gap-2 p-2">
							<LargeTextInputFormField
								name="description"
								label="Notes"
								className="col-span-2"
							/>
						</CardContent>
					</Card>
				</form>
			</Form>
			<div className="flex justify-end gap-2">
				{mode === "view" ? (
					<Button
						variant="outline"
						className="h-fit gap-2 px-2 py-1"
						onClick={() => setMode("edit")}
					>
						<EditIcon size={16} />
						<span>Edit</span>
					</Button>
				) : (
					<>
						<Button
							variant="outline"
							className="h-fit gap-2 px-2 py-1"
							onClick={() => setMode("view")}
						>
							<span>Cancel</span>
						</Button>
						<Button
							variant="outline"
							className="h-fit gap-2 px-2 py-1"
							onClick={(e) => onSubmit(e)}
						>
							<span>Submit</span>
						</Button>
					</>
				)}
			</div>
		</>
	);
};

const parseSeedBatch = (dto: SeedBatchSingleDto | null | undefined) => {
	if (!dto?.seed?.floraSpecies?.id || !dto?.seed?.provenanceZone?.id)
		return undefined;
	const command: SeedBatchFormType = {
		floraSpeciesId: dto.seed?.floraSpecies?.id,
		provenanceZoneId: dto.seed?.provenanceZone?.id,
		seedIdentificationConfirmed: dto.seedIdentificationConfirmed,
		seedBatchNo: dto.seedBatchNo,
		collectionDate: dto.collectionDate
			? format(new Date(dto.collectionDate), "yyyy-MM-dd")
			: "",
		provenanceLandforms: dto.provenanceLandforms ?? [],
		description: dto.description,
		poNumber: dto.poNumber,
		initialQuantity: dto.initialQuantity,
		cost: dto.cost,
		supplierId: dto.supplierId ?? "",
		storageLocationId: dto.storageLocationId,
		shelfLocation: dto.shelfLocation,
		seedSupplierIdentifier: dto.seedSupplierIdentifier,
		germinationTest: parseGerminationTest(dto.seedBatchGerminationTest),
		status: dto.status,
	};
	if (dto.collectionPoint) {
		command.collectionPoint = {
			srid: dto.collectionPoint?.srid,
			x: dto.collectionPoint?.x,
			y: dto.collectionPoint?.y,
		};
	}

	return command;
};

const parseGerminationTest = (
	dto: SeedBatchGerminationTestDto | null | undefined,
) => {
	if (!dto?.viability || !dto?.germinationRate) return null;
	const date = new Date(dto.testDate);
	return {
		testDate: format(date, "yyyy-MM-dd"),
		purity: dto.purity,
		viability: dto.viability,
		germinationRate: dto.germinationRate,
		conductedBy: dto.conductedBy,
		note: dto.note,
		seedTestIdentifier: dto.seedTestIdentifier,
		seedsPerGram: dto.seedsPerGram,
	} satisfies SeedBatchGerminationTestCreateBatchDto;
};
