import client from "@kubb/plugin-client/client";
import type { MineSitesCreateMutationRequest, MineSitesCreateMutationResponse } from "../../ts/MineSitesCreate.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/MineSites}
 */
export async function mineSitesCreate(data?: MineSitesCreateMutationRequest, config: Partial<RequestConfig<MineSitesCreateMutationRequest>> = {}) {
    const formData = new FormData();
    if (data) {
        Object.keys(data).forEach((key) => {
            const value = data[key as keyof typeof data];
            if (typeof key === "string" && (typeof value === "string" || value instanceof Blob)) {
                formData.append(key, value);
            }
        });
    }
    const res = await client<MineSitesCreateMutationResponse, Error, MineSitesCreateMutationRequest>({ method: "POST", url: `/api/MineSites`, data: formData, headers: { "Content-Type": "multipart/form-data", ...config.headers }, ...config });
    return res.data;
}