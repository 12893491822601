export enum AnaloguePolysOrderByEnum {
    "Area" = "Area",
    "Name" = "Name",
    "Landform" = "Landform",
    "MineSite" = "MineSite",
    "MiningRegion" = "MiningRegion",
    "Status" = "Status",
    "VegClassCoverDynamicColumn" = "VegClassCoverDynamicColumn"
}

 export type AnaloguePolysOrderBy = AnaloguePolysOrderByEnum;