import { useAtomValue } from "jotai";
import { lazy, Suspense, useMemo } from "react";

import { Perm_Riparian_Std, UserPermissionsAtom } from "@/atoms/authAtoms";
import { CurrentViewAtom } from "@/atoms/eisViewAtoms";
import { HealthSurvey1Atom } from "@/atoms/healthAtoms";
import { SelectedIndividualTreeIdAtom } from "@/atoms/individualTreeAtoms";
import {
	GetCardComponent,
	LazyCardComponent,
} from "@/components/card-components";

const IndividualTreeSummaryCard = {
	component: lazy(
		() =>
			import("@/components/ModuleCards/Health/IndividualTreeSummaryCard"),
	),
	permission: Perm_Riparian_Std,
} as const satisfies LazyCardComponent;

export const LazyIndividualTreeSummaryCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);
	const selectedIndividualTreeId = useAtomValue(SelectedIndividualTreeIdAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(IndividualTreeSummaryCard, userPerms);
		if (comp) {
			if (currentView?.name === "Health" && selectedIndividualTreeId)
				return comp;
		}
		return null;
	}, [currentView?.name, selectedIndividualTreeId, userPerms]);

	return (
		<Suspense fallback={null}>
			{Component && <Component id={"IndividualTreeSummaryCard"} />}
		</Suspense>
	);
};

const HealthMetricsCard = {
	component: lazy(
		() => import("@/components/ModuleCards/Health/HealthMetricsCard"),
	),
	permission: Perm_Riparian_Std,
} as const satisfies LazyCardComponent;

export const LazyHealthMetricsCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);
	const healthSurvey1Id = useAtomValue(HealthSurvey1Atom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(HealthMetricsCard, userPerms);
		if (comp) {
			if (currentView?.name === "Health" && healthSurvey1Id) return comp;
		}
		return null;
	}, [currentView?.name, healthSurvey1Id, userPerms]);

	return (
		<Suspense fallback={null}>
			{Component && <Component id={"HealthMetricsCard"} />}
		</Suspense>
	);
};
