import client from "@kubb/plugin-client/client";
import type { RiparianSurveyGetRiparianSurveyComparisonMutationRequest, RiparianSurveyGetRiparianSurveyComparisonMutationResponse } from "../../ts/RiparianSurveyGetRiparianSurveyComparison.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/RiparianSurvey/surveycomparison}
 */
export async function riparianSurveyGetRiparianSurveyComparison(data: RiparianSurveyGetRiparianSurveyComparisonMutationRequest, config: Partial<RequestConfig<RiparianSurveyGetRiparianSurveyComparisonMutationRequest>> = {}) {
    const res = await client<RiparianSurveyGetRiparianSurveyComparisonMutationResponse, Error, RiparianSurveyGetRiparianSurveyComparisonMutationRequest>({ method: "POST", url: `/api/RiparianSurvey/surveycomparison`, data, ...config });
    return res.data;
}