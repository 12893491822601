import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import React, { useMemo } from "react";

import {
	SelectedHealthGridCellIdAtom,
	SelectedHealthZoneIdAtom,
} from "@/atoms/healthAtoms";
import { SelectedMineSiteAtom } from "@/atoms/miningAtoms";
import VegCoverGraph from "@/components/BottomDrawer/Common/VegCoverGraph";
import {
	RiparianMetricDataTypeEnum,
	riparianSurveyGetRiparianZoneGridCellMetricsHistory,
	riparianSurveyGetRiparianZoneMetricsHistory,
	VegCoverHistoryDto,
} from "@/lib/gen/eis";

const HealthVegGraphCard: React.FC = () => {
	const cellId = useAtomValue(SelectedHealthGridCellIdAtom);
	const zoneId = useAtomValue(SelectedHealthZoneIdAtom);
	const mineSite = useAtomValue(SelectedMineSiteAtom);

	const query = useQuery({
		queryKey: [
			"riparianSurveyGetRiparianZoneMetricsHistory",
			zoneId,
			mineSite?.id,
		],
		queryFn: async () =>
			riparianSurveyGetRiparianZoneMetricsHistory({
				MineSiteId: mineSite?.id,
				RiparianZoneId: zoneId,
				RiparianMetricDataType: RiparianMetricDataTypeEnum.Percent,
			}),
		enabled: cellId == null && (!!zoneId || !!mineSite?.id),
	});

	const queryCell = useQuery({
		queryKey: [
			"riparianSurveyGetRiparianZoneGridCellMetricsHistory",
			cellId,
		],
		queryFn: async () =>
			riparianSurveyGetRiparianZoneGridCellMetricsHistory({
				RiparianZoneGridCellId: cellId,
				RiparianMetricDataType: RiparianMetricDataTypeEnum.Percent,
			}),
		enabled: !!cellId,
	});

	const data = useMemo(() => {
		const _data = cellId == null ? query.data : queryCell.data;

		if (_data == null) return [];
		const surveys = _data.reduce(
			(m, r) => {
				if (r.values == null || r.values.length <= 0) return m;

				for (const value of r.values) {
					if (value.surveyDate == null) continue;

					const history: VegCoverHistoryDto = {
						vegetationClassId: r.metricId,
						vegetationClassName: r.metricName,
						coverPercentage: value.value,
					};

					const survey = m[value.surveyDate];
					if (survey == null) m[value.surveyDate] = [history];
					else survey.push(history);
				}

				return m;
			},
			{} as { [key: string]: VegCoverHistoryDto[] },
		);

		return Object.entries(surveys).map(([date, vegCovers]) => ({
			date,
			vegCovers,
		}));
	}, [cellId, query.data, queryCell.data]);

	const vegClasses = useMemo(() => {
		if (query.data == null) return [];

		return query.data
			.sort(
				(a, b) =>
					(a.displayPosition ?? 999) - (b.displayPosition ?? 999),
			)
			.map((r) => ({
				id: r.metricId,
				name: r.metricName ?? "",
				displayColour: r.metricColour ?? undefined,
			}));
	}, [query.data]);

	const subtitle = useMemo(() => {
		if (cellId) {
			const gcName = queryCell.data?.find(
				(gc) => gc.gridCellId === cellId,
			)?.gridCellName;
			return `Grid Cell - ${gcName ?? ""}`;
		}
		if (zoneId)
			return query.data?.find((z) => z.zoneId === zoneId)?.zoneName;
		return mineSite?.mineSiteName;
	}, [cellId, mineSite?.mineSiteName, query.data, queryCell.data, zoneId]);

	if (!cellId && !zoneId && !mineSite) {
		return (
			<div className="flex size-full items-center justify-center">
				<h1 className="text-center">
					Select a Mine Site, Health Zone or Health Zone Grid Cell
				</h1>
			</div>
		);
	}

	return (
		<VegCoverGraph
			headerClassName="border-module-health-border bg-module-health-header"
			error={query.isError ? query.error : undefined}
			data={data}
			isLoading={query.isLoading}
			subtitle={subtitle}
			vegClasses={vegClasses}
		/>
	);
};

export default HealthVegGraphCard;
