import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { useMemo } from "react";
import { toast } from "sonner";

import { DataTable } from "@/components/data-table/data-table";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header";
import { DialogProps } from "@/components/dialogs/dialogs.atom";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import {
	dataManagementCreate,
	dataManagementGetTytonModules,
	TytonEisModuleEnum,
	TytonModuleDto,
} from "@/lib/gen/eis";
import { cn } from "@/lib/utils";

type ActiveButtonProps = {
	value: boolean;
	tytonEISModule: TytonEisModuleEnum;
};
const ActivationButton = ({ value, tytonEISModule }: ActiveButtonProps) => {
	const queryClient = useQueryClient();
	const { mutate } = useMutation({
		mutationFn: async () => {
			return dataManagementCreate({
				tytonEISModule: tytonEISModule,
				isActive: !value,
			});
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: ["tytonModules"],
			});
		},
		onError: (error) => {
			toast.error(`Failed to ${value ? "deactivate" : "activate"}`, {
				description: error.message,
			});
		},
	});

	return (
		<Button
			variant={value ? "destructive" : "default"}
			className={cn("size-full")}
			onClick={() => {
				mutate();
			}}
		>
			{value ? "Deactivate" : "Activate"}
		</Button>
	);
};
const columnHelper = createColumnHelper<TytonModuleDto>();
const getModuleColumns = () =>
	[
		columnHelper.accessor("tytonEISModule", {
			id: "name",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Name" />
			),
			cell: ({ getValue }) => <div>{getValue()}</div>,
		}),
		columnHelper.accessor("isActive", {
			id: "status",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Status" />
			),
			cell: ({ getValue }) => <div>{getValue()}</div>,
		}),
		columnHelper.accessor("isActive", {
			id: "updateStatus",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="" />
			),
			cell: ({ getValue, row }) => (
				<ActivationButton
					value={getValue()}
					tytonEISModule={row.original.tytonEISModule}
				/>
			),
		}),
	] as ColumnDef<TytonModuleDto>[];

const ModuleManagementDialog = ({ open, setOpen }: DialogProps) => {
	const columns = useMemo(() => {
		return getModuleColumns();
	}, []);
	const { isLoading, isError, data } = useQuery({
		queryKey: ["tytonModules"],
		queryFn: async () => dataManagementGetTytonModules(),
	});

	if (isLoading || !data) {
		return <div></div>;
	}

	if (isError) {
		return <div>Error loading Module Management page</div>;
	}

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>Module Management</DialogTitle>
					<DialogDescription />
				</DialogHeader>
				<DataTable
					title="Module Management"
					isLoading={isLoading}
					rowHeight={52.5}
					data={data.items ?? []}
					columns={columns}
				></DataTable>
			</DialogContent>
		</Dialog>
	);
};

export default ModuleManagementDialog;
