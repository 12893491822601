import { useAtomValue } from "jotai";
import { lazy, Suspense, useMemo } from "react";

import { Perm_Seeds_Std, UserPermissionsAtom } from "@/atoms/authAtoms";
import { CurrentViewAtom } from "@/atoms/eisViewAtoms";
import { SelectedRehabPolygonAtom } from "@/atoms/rehabPolyAtoms";
import {
	GetCardComponent,
	LazyCardComponent,
} from "@/components/card-components";

const SiteSeedDetailsCard = {
	component: lazy(() => import("@/components/Seeds/site-seed-card")),
	permission: Perm_Seeds_Std,
} as const satisfies LazyCardComponent;

export const LazySiteSeedDetailsCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(SiteSeedDetailsCard, userPerms);
		if (comp) {
			if (currentView?.name === "Seeds") return comp;
		}
		return null;
	}, [currentView?.name, userPerms]);

	return (
		<Suspense fallback={null}>
			{Component && <Component id={"SiteSeedDetailsCard"} />}
		</Suspense>
	);
};

const SeedInventoryCard = {
	component: lazy(() => import("@/components/Seeds/seed-inventory-card")),
	permission: Perm_Seeds_Std,
} as const satisfies LazyCardComponent;

export const LazySeedInventoryCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(SeedInventoryCard, userPerms);
		if (comp) {
			if (currentView?.name === "Seeds") return comp;
		}
		return null;
	}, [currentView?.name, userPerms]);

	return <Suspense fallback={null}>{Component && <Component />}</Suspense>;
};

const RehabPolySeedCard = {
	component: lazy(() => import("@/components/Seeds/rehab-poly-seed-card")),
	permission: Perm_Seeds_Std,
} as const satisfies LazyCardComponent;

export const LazyRehabPolySeedCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);
	const selectedRehabPoly = useAtomValue(SelectedRehabPolygonAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(RehabPolySeedCard, userPerms);
		if (comp) {
			if (currentView?.name === "Seeds" && selectedRehabPoly) return comp;
		}
		return null;
	}, [currentView?.name, selectedRehabPoly, userPerms]);

	return (
		<Suspense fallback={null}>
			{Component && <Component id={"RehabPolySeedCard"} />}
		</Suspense>
	);
};

const SeedAppPlanCard = {
	component: lazy(() => import("@/components/Seeds/seed-app-details-card")),
	permission: Perm_Seeds_Std,
} as const satisfies LazyCardComponent;

export const LazySeedAppPlanCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(SeedAppPlanCard, userPerms);
		if (comp) {
			if (currentView?.name === "SeedApplication") return comp;
		}
		return null;
	}, [currentView?.name, userPerms]);

	return <Suspense fallback={null}>{Component && <Component />}</Suspense>;
};

const SeedAppCriteriaCard = {
	component: lazy(() => import("@/components/Seeds/seed-app-criteria-card")),
	permission: Perm_Seeds_Std,
} as const satisfies LazyCardComponent;

export const LazySeedAppCriteriaCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(SeedAppCriteriaCard, userPerms);
		if (comp) {
			if (currentView?.name === "SeedApplication") return comp;
		}
		return null;
	}, [currentView?.name, userPerms]);

	return <Suspense fallback={null}>{Component && <Component />}</Suspense>;
};

const SeedAppTemplateCard = {
	component: lazy(() => import("@/components/Seeds/seed-app-template-card")),
	permission: Perm_Seeds_Std,
} as const satisfies LazyCardComponent;

export const LazySeedAppTemplateCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(SeedAppTemplateCard, userPerms);
		if (comp) {
			if (currentView?.name === "SeedApplication") return comp;
		}
		return null;
	}, [currentView?.name, userPerms]);

	return <Suspense fallback={null}>{Component && <Component />}</Suspense>;
};

const SeedAppVegCoverCard = {
	component: lazy(() => import("@/components/Seeds/seed-app-veg-cover-card")),
	permission: Perm_Seeds_Std,
} as const satisfies LazyCardComponent;

export const LazySeedAppVegCoverCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(SeedAppVegCoverCard, userPerms);
		if (comp) {
			if (currentView?.name === "SeedApplication") return comp;
		}
		return null;
	}, [currentView?.name, userPerms]);

	return <Suspense fallback={null}>{Component && <Component />}</Suspense>;
};

const SeedAppSelectorCard = {
	component: lazy(() => import("@/components/Seeds/seed-app-selector-card")),
	permission: Perm_Seeds_Std,
} as const satisfies LazyCardComponent;

export const LazySeedAppSelectorCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(SeedAppSelectorCard, userPerms);
		if (comp) {
			if (currentView?.name === "SeedApplication") return comp;
		}
		return null;
	}, [currentView?.name, userPerms]);

	return <Suspense fallback={null}>{Component && <Component />}</Suspense>;
};
