import { atom } from "jotai";
import { RESET } from "jotai/utils";
import { atomWithHash } from "jotai-location";
import { atomWithQuery } from "jotai-tanstack-query";

import { clientConfigurationGetClientLogo } from "@/lib/gen/eis";
import { HashKeys } from "@/lib/hash-keys";
import {
	ErosionClasses,
	HealthClasses,
	MonitoringClasses,
	SeedsClasses,
	TopsoilClasses,
	WeedsClasses,
} from "@/lib/styles/modules";

import { SelectedAnaloguePolygon } from "./analoguePolyAtoms";
import {
	Perm_Erosion_Std,
	Perm_Rehab_Std,
	Perm_Riparian_Std,
	Perm_Seeds_Std,
	Perm_Topsoil_Std,
	Perm_Weeds_Std,
	UserPermissionsAtom,
} from "./authAtoms";
import {
	ActiveTabAtom,
	DefaultDrawerTabs,
	DrawerTabModules,
} from "./bottomDrawerAtoms";
import { SelectedErosionFeatureIdAtom } from "./erosionAtoms";
import { SelectedIndividualTreeIdAtom } from "./individualTreeAtoms";
import { MapLayer } from "./map/layerVisibilityAtoms";
import { SelectedGeometryAtom } from "./mapAtoms";
import { SelectedSamplingSiteAtom } from "./ongroundAtoms";
import { SelectedRehabPolygonAtom } from "./rehabPolyAtoms";
import {
	SeedApplicationIdAtom,
	SeedApplicationPolygonIdAtom,
} from "./seedAppAtoms";
import { SelectedTopsoilStockpileIdAtom } from "./topsoilAtoms";
import { SelectedWeedGridCellIdAtom, WeedsSurveysAtom } from "./weedsAtoms";

export const ClientLogoQueryAtom = atomWithQuery(() => {
	return {
		queryKey: ["clientLogo"],
		queryFn: async () => {
			return clientConfigurationGetClientLogo();
		},
		staleTime: Infinity,
	};
});

export const MonitoringView = {
	name: "Monitoring",
	displayName: "Monitoring",
	iconName: "monitoring_module",
	moduleClasses: MonitoringClasses,
	permission: Perm_Rehab_Std,
	layers: [
		MapLayer.Imagery,
		MapLayer.Classification,
		MapLayer.AnalogueClassification,
		MapLayer.MineSiteVector,
		MapLayer.RehabPolygonVector,
		MapLayer.SamplingSiteVector,
		MapLayer.BareAreas,
		MapLayer.SiteInspectionVector,
		MapLayer.AnaloguePolygonVector,
	],
} as const;

export const WeedsView = {
	name: "Weeds",
	displayName: "Weeds",
	iconName: "weeds_module",
	moduleClasses: WeedsClasses,
	permission: Perm_Weeds_Std,
	layers: [
		MapLayer.Imagery,
		MapLayer.WeedPatchVector,
		MapLayer.MineSiteVector,
		MapLayer.SiteInspectionVector,
		MapLayer.WeedGrid,
	],
} as const;

export const ErosionView = {
	name: "Erosion",
	displayName: "Erosion",
	iconName: "erosion_module",
	moduleClasses: ErosionClasses,
	permission: Perm_Erosion_Std,
	layers: [
		MapLayer.Imagery,
		MapLayer.ErosionRaster,
		MapLayer.ErosionVector,
		MapLayer.MineSiteVector,
		MapLayer.RehabPolygonVector,
		MapLayer.SiteInspectionVector,
	],
} as const;

export const TopsoilView = {
	name: "Topsoil",
	displayName: "Topsoil",
	iconName: "topsoil_module",
	moduleClasses: TopsoilClasses,
	permission: Perm_Topsoil_Std,
	layers: [
		MapLayer.Imagery,
		MapLayer.Classification,
		MapLayer.MineSiteVector,
		MapLayer.TopsoilStockpileVector,
		MapLayer.RehabPolygonVector,
		MapLayer.SiteInspectionVector,
	],
} as const;

export const SeedsView = {
	name: "Seeds",
	displayName: "Seeds",
	iconName: "seeds_module",
	moduleClasses: SeedsClasses,
	permission: Perm_Seeds_Std,
	layers: [
		MapLayer.Imagery,
		MapLayer.MineSiteVector,
		MapLayer.RehabPolygonVector,
		MapLayer.SiteInspectionVector,
	],
} as const;

export const HealthView = {
	name: "Health",
	displayName: "Plant Health",
	iconName: "health_module",
	moduleClasses: HealthClasses,
	permission: Perm_Riparian_Std,
	layers: [
		MapLayer.Imagery,
		MapLayer.Classification,
		MapLayer.MSAVI,
		MapLayer.MineSiteVector,
		MapLayer.HealthZoneVector,
		MapLayer.HealthGridVector,
	],
} as const;

export const SeedApplicationView = {
	name: "SeedApplication",
	displayName: "Seed Application",
	iconName: "seeds_module",
	moduleClasses: SeedsClasses,
	permission: Perm_Seeds_Std,
	layers: [
		MapLayer.Imagery,
		MapLayer.MineSiteVector,
		MapLayer.RehabPolygonVector,
		MapLayer.SiteInspectionVector,
	],
} as const;

export const EIS_VIEWS = {
	Monitoring: MonitoringView,
	Erosion: ErosionView,
	Seeds: SeedsView,
	Topsoil: TopsoilView,
	Weeds: WeedsView,
	Health: HealthView,
	SeedApplication: SeedApplicationView,
} as const;

export type TytonEisModuleType = keyof typeof EIS_VIEWS;
export type TytonEisView = (typeof EIS_VIEWS)[TytonEisModuleType];

const _currentViewAtom = atomWithHash<TytonEisModuleType | undefined>(
	HashKeys.View,
	undefined,
);
export const CurrentViewAtom = atom(
	(get) => {
		const view = get(_currentViewAtom);
		if (view && EIS_VIEWS[view]) {
			return EIS_VIEWS[view];
		}
		const userPerms = get(UserPermissionsAtom);
		if (userPerms) {
			if (userPerms.includes(Perm_Rehab_Std)) {
				return MonitoringView;
			} else if (userPerms.includes(Perm_Riparian_Std)) {
				return HealthView;
			} else if (userPerms.includes(Perm_Seeds_Std)) {
				return SeedsView;
			} else if (userPerms.includes(Perm_Weeds_Std)) {
				return WeedsView;
			} else if (userPerms.includes(Perm_Topsoil_Std)) {
				return TopsoilView;
			} else if (userPerms.includes(Perm_Erosion_Std)) {
				return ErosionView;
			}
			throw new Error(
				"User does not have adequate permissions to view a module",
			);
		}
	},
	(get, set, value: TytonEisModuleType) => {
		set(_currentViewAtom, value);
		const activeTab = get(ActiveTabAtom);
		if (DrawerTabModules[activeTab.name] !== value) {
			set(ActiveTabAtom, {
				module: value,
				name: DefaultDrawerTabs[value],
			});
			set(WeedsSurveysAtom);
		}

		if (value !== "Monitoring" && get(SelectedAnaloguePolygon)) {
			set(SelectedGeometryAtom, undefined);
		}
		if (value !== "Monitoring" && get(SelectedSamplingSiteAtom)) {
			set(SelectedGeometryAtom, undefined);
		}
		if (
			value !== "Monitoring" &&
			value !== "Erosion" &&
			get(SelectedRehabPolygonAtom)
		) {
			set(SelectedGeometryAtom, undefined);
		}
		if (value !== "Erosion" && get(SelectedErosionFeatureIdAtom)) {
			set(SelectedGeometryAtom, undefined);
		}
		if (value !== "Weeds" && get(SelectedWeedGridCellIdAtom)) {
			set(SelectedGeometryAtom, undefined);
		}
		if (value !== "Health" && get(SelectedIndividualTreeIdAtom)) {
			set(SelectedGeometryAtom, undefined);
		}
		if (value !== "Topsoil" && get(SelectedTopsoilStockpileIdAtom)) {
			set(SelectedGeometryAtom, undefined);
		}
		if (value !== "SeedApplication") {
			set(SeedApplicationIdAtom, undefined);
			set(SeedApplicationPolygonIdAtom, RESET);
		}
	},
);
