import client from "@kubb/plugin-client/client";
import type { IndividualTreesTreeDataMutationRequest, IndividualTreesTreeDataMutationResponse, IndividualTreesTreeDataPathParams } from "../../ts/IndividualTreesTreeData.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/IndividualTrees/:id/treedata}
 */
export async function individualTreesTreeData(id: IndividualTreesTreeDataPathParams["id"], data?: IndividualTreesTreeDataMutationRequest, config: Partial<RequestConfig<IndividualTreesTreeDataMutationRequest>> = {}) {
    const formData = new FormData();
    if (data) {
        Object.keys(data).forEach((key) => {
            const value = data[key as keyof typeof data];
            if (typeof key === "string" && (typeof value === "string" || value instanceof Blob)) {
                formData.append(key, value);
            }
        });
    }
    const res = await client<IndividualTreesTreeDataMutationResponse, Error, IndividualTreesTreeDataMutationRequest>({ method: "POST", url: `/api/IndividualTrees/${id}/treedata`, data: formData, headers: { "Content-Type": "multipart/form-data", ...config.headers }, ...config });
    return res.data;
}