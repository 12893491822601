import client from "@kubb/plugin-client/client";
import type { SamplingSitesGetSpeciesSamplingMutationRequest, SamplingSitesGetSpeciesSamplingMutationResponse } from "../../ts/SamplingSitesGetSpeciesSampling.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/SamplingSites/ObservationSpeciesSampling/paginated}
 */
export async function samplingSitesGetSpeciesSampling(data: SamplingSitesGetSpeciesSamplingMutationRequest, config: Partial<RequestConfig<SamplingSitesGetSpeciesSamplingMutationRequest>> = {}) {
    const res = await client<SamplingSitesGetSpeciesSamplingMutationResponse, Error, SamplingSitesGetSpeciesSamplingMutationRequest>({ method: "POST", url: `/api/SamplingSites/ObservationSpeciesSampling/paginated`, data, ...config });
    return res.data;
}