import client from "@kubb/plugin-client/client";
import type { SiteInspectionsGetSiteInspectionForMapQueryResponse, SiteInspectionsGetSiteInspectionForMapPathParams, SiteInspectionsGetSiteInspectionForMapQueryParams } from "../../ts/SiteInspectionsGetSiteInspectionForMap.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/SiteInspections/:id/map}
 */
export async function siteInspectionsGetSiteInspectionForMap(id: SiteInspectionsGetSiteInspectionForMapPathParams["id"], params?: SiteInspectionsGetSiteInspectionForMapQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<SiteInspectionsGetSiteInspectionForMapQueryResponse, Error, unknown>({ method: "GET", url: `/api/SiteInspections/${id}/map`, params, ...config });
    return res.data;
}