import client from "@kubb/plugin-client/client";
import type { WeedsSurveysGetSingleCellComparisonQueryResponse, WeedsSurveysGetSingleCellComparisonQueryParams } from "../../ts/WeedsSurveysGetSingleCellComparison.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/WeedsSurveys/singlecellcomparison}
 */
export async function weedsSurveysGetSingleCellComparison(params?: WeedsSurveysGetSingleCellComparisonQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<WeedsSurveysGetSingleCellComparisonQueryResponse, Error, unknown>({ method: "GET", url: `/api/WeedsSurveys/singlecellcomparison`, params, ...config });
    return res.data;
}