import { useAtomValue, useSetAtom } from "jotai";
import { memo, useMemo } from "react";

import { GoToRiparianGrid, HealthSurvey2Atom } from "@/atoms/healthAtoms";
import { DataTableCustomCell } from "@/components/data-table/data-table-cells";
import { DataTableLocationPinCell } from "@/components/data-table/data-table-location-pin";
import Icon from "@/components/icons/icon";
import { HealthGridLayerSourceAtom } from "@/components/MapOpenLayers/Health/healthGridLayer";
import {
	LocationMetricResultComparisonDto,
	RiparianMetricDataTypeEnum,
} from "@/lib/gen/eis";

export const HealthLocationMetricResultCell = memo(function ({
	name,
	metricComparisons,
}: {
	name: string;
	metricComparisons: LocationMetricResultComparisonDto[];
}) {
	const survey2Atom = useAtomValue(HealthSurvey2Atom);

	const comparison = metricComparisons.find((x) => x.name === name);

	return (
		<DataTableCustomCell className="truncate font-mono">
			{!survey2Atom && (
				<div className="flex w-full items-center justify-end gap-1 truncate font-roboto">
					{comparison?.riparianMetricDataType ===
					RiparianMetricDataTypeEnum.Percent
						? `${(Number(comparison?.survey1Result ?? "") * 100).toFixed(1)}%`
						: `${Number(comparison?.survey1Result ?? "").toFixed(1)}`}
				</div>
			)}

			{survey2Atom && (
				<div className="flex w-full items-center justify-end gap-1 whitespace-nowrap font-roboto">
					<div>
						{comparison?.riparianMetricDataType ===
						RiparianMetricDataTypeEnum.Percent
							? `${(
									Number(comparison?.survey1Result ?? "") *
									100
								).toFixed(1)}%`
							: `${Number(comparison?.survey1Result ?? "").toFixed(1)}`}
					</div>
					<div className="flex items-center justify-end whitespace-nowrap font-roboto">
						{" ("}
						<div className="size-5">
							{comparison?.stability === "Rise" ? (
								<Icon
									name="riparian_stability_up"
									className="text-misc-stability-up"
								/>
							) : comparison?.stability === "Fall" ? (
								<Icon
									name="riparian_stability_down"
									className="text-misc-stability-down"
								/>
							) : (
								<Icon
									name="riparian_stability_stable"
									className="text-misc-stability-stable"
								/>
							)}
						</div>

						{comparison?.riparianMetricDataType ===
						RiparianMetricDataTypeEnum.Percent
							? `${(Number(comparison?.changeValue ?? "") * 100).toFixed(1)}%`
							: `${Number(comparison?.changeValue ?? "").toFixed(1)}`}
						{")"}
					</div>
				</div>
			)}
		</DataTableCustomCell>
	);
});

export const HealthGridLocationCell = memo(function ({ id }: { id: string }) {
	const source = useAtomValue(HealthGridLayerSourceAtom);
	const disabled = useMemo(() => {
		const f = source.source?.getFeatureById(id);
		return f == null;
	}, [id, source]);
	const goToGrid = useSetAtom(GoToRiparianGrid);

	return (
		<DataTableLocationPinCell
			GoToLocation={() => goToGrid(id)}
			disabled={disabled}
		/>
	);
});
