import client from "@kubb/plugin-client/client";
import type { MiningRegionsDeleteMutationResponse, MiningRegionsDeletePathParams } from "../../ts/MiningRegionsDelete.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/MiningRegions/:id}
 */
export async function miningRegionsDelete(id: MiningRegionsDeletePathParams["id"], config: Partial<RequestConfig> = {}) {
    const res = await client<MiningRegionsDeleteMutationResponse, Error, unknown>({ method: "DELETE", url: `/api/MiningRegions/${id}`, ...config });
    return res.data;
}