import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { DialogProps } from "@/components/dialogs/dialogs.atom";
import { FormErrors } from "@/components/form/form-errors";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import { FileInput } from "@/components/ui/FileInput";
import { Form } from "@/components/ui/form";
import { bulkSeedBatchesUploadBulkSeedBatchCsvs } from "@/lib/gen/eis";

export const BulkImportSeedsDialog = ({ open, setOpen }: DialogProps) => {
	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>Bulk Import Seeds</DialogTitle>
					<DialogDescription />
				</DialogHeader>
				<BulkImportSeedsForm />
			</DialogContent>
		</Dialog>
	);
};

const formSchema = z.object({
	file: z
		.instanceof(File)
		.refine((file) => file.type == "text/csv", "Must be a CSV file."),
});
type FormType = z.infer<typeof formSchema>;

export const BulkImportSeedsForm = () => {
	const form = useForm<FormType>({
		resolver: zodResolver(formSchema),
	});

	const { mutate, error } = useMutation({
		mutationFn: async (data: FormType) => {
			return bulkSeedBatchesUploadBulkSeedBatchCsvs({
				SeedBatchFileName: data.file.name,
				CsvFile: data.file,
			});
		},
	});

	return (
		<Form {...form}>
			<form
				className="flex flex-col gap-2"
				onSubmit={form.handleSubmit((v) => mutate(v))}
			>
				<div className="flex flex-col gap-1">
					<FormErrors error={error} />
					<div className="flex flex-col">
						<span className="text-sm font-semibold">
							Upload a CSV file containing seed batches to import.
						</span>
						<span className="text-sm text-muted-foreground">
							The format must match the following{" "}
							<a
								className="underline hover:font-semibold"
								href="/file-templates/seed-batch-template.csv"
								download
							>
								template
							</a>
							.
						</span>
					</div>
					<FileInput control={form.control} name="file" />
				</div>
				<Button type="submit">Import</Button>
			</form>
		</Form>
	);
};
