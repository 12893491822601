export enum PredicateFilterTypeEnum {
    "speciesFullName" = "speciesFullName",
    "family" = "family",
    "genus" = "genus",
    "supplierName" = "supplierName",
    "storeLocation" = "storeLocation",
    "poNumber" = "poNumber",
    "seedBatchNo" = "seedBatchNo",
    "polygonName" = "polygonName",
    "polygonTag" = "polygonTag",
    "polygonLabel" = "polygonLabel",
    "mineSite" = "mineSite",
    "mineSiteName" = "mineSiteName",
    "miningRegion" = "miningRegion",
    "miningRegionName" = "miningRegionName",
    "erosionFeatureName" = "erosionFeatureName",
    "rehabilitationSurveyReport" = "rehabilitationSurveyReport",
    "siteInspectionName" = "siteInspectionName",
    "samplingSiteName" = "samplingSiteName",
    "samplingSiteObservationName" = "samplingSiteObservationName",
    "rehabilitationOngroundSurveyName" = "rehabilitationOngroundSurveyName",
    "criterion" = "criterion",
    "analoguePolygonName" = "analoguePolygonName",
    "speciesFullNameId" = "speciesFullNameId",
    "healthZoneName" = "healthZoneName"
}

 export type PredicateFilterType = PredicateFilterTypeEnum;