import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import { SelectedHealthZoneIdAtom } from "@/atoms/healthAtoms";
import { HealthZoneLayerAtom, LayerSource } from "@/atoms/map/layerAtoms";
import { HealthZonesQueryAtom } from "@/atoms/map/mapQueryAtoms";
import { SelectedGeometryAtom } from "@/atoms/mapAtoms";
import { populateVectorLayer } from "@/components/MapOpenLayers/vector-layer-populate";

export const HealthZoneLayerSourceAtom = atom<LayerSource>({
	source: undefined,
});

const layerSourceAtomEffect = atomEffect((get, set) => {
	const source = get(HealthZoneLayerAtom).getSource();
	if (!source) return;
	const onChange = () => {
		set(HealthZoneLayerSourceAtom, { source });
	};
	source.on("change", onChange);

	return () => {
		source.un("change", onChange);
	};
});

export const HealthZoneLayerAtomEffect = atomEffect((get) => {
	get(populateAtomEffect);
	get(layerSourceAtomEffect);
});

const populateAtomEffect = atomEffect((get, set) => {
	const query = get(HealthZonesQueryAtom);
	if (query.data == null) return;

	const data = query.data;
	const layer = get(HealthZoneLayerAtom);

	populateVectorLayer({
		data,
		layer,
		source: "healthzone",
		onUnselect: () => set(SelectedGeometryAtom, undefined),
		onSelect: (data, feature) => {
			set(SelectedGeometryAtom, feature);
			set(SelectedHealthZoneIdAtom, data.id);
		},
	});
});
