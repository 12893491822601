import { atom } from "jotai";
import { atomEffect } from "jotai-effect";
import { Coordinate } from "ol/coordinate";
import { boundingExtent, Extent } from "ol/extent";
import Feature from "ol/Feature";
import { Geometry, MultiPolygon, Point, Polygon } from "ol/geom";
import MapBrowserEvent from "ol/MapBrowserEvent";
import { toast } from "sonner";

import { SelectedAnaloguePolygon } from "@/atoms/analoguePolyAtoms";
import { SelectedErosionFeatureIdAtom } from "@/atoms/erosionAtoms";
import {
	HealthGridCellsComparisonAtom,
	SelectedHealthGridCellIdAtom,
	SelectedHealthZoneIdAtom,
} from "@/atoms/healthAtoms";
import { SelectedIndividualTreeIdAtom } from "@/atoms/individualTreeAtoms";
import {
	MapAtom,
	MapViewPaddingAtom,
	SelectedGeometryAtom,
	UpdateMapViewState,
} from "@/atoms/mapAtoms";
import {
	MineSiteSummariesQueryAtom,
	SelectedMineSiteIdAtom,
} from "@/atoms/miningAtoms";
import { SelectedSamplingSiteAtom } from "@/atoms/ongroundAtoms";
import { SelectedRehabPolygonIdAtom } from "@/atoms/rehabPolyAtoms";
import { SelectedSiteInspectionAtom } from "@/atoms/siteInspectionAtoms";
import { SelectedTopsoilStockpileIdAtom } from "@/atoms/topsoilAtoms";
import { SelectedWeedGridCellIdAtom } from "@/atoms/weedsAtoms";
import {
	HOVER_SOURCES,
	MapSourceType,
} from "@/components/MapOpenLayers/map-types";
import { getFeatureByIdAndSource } from "@/helpers/map-helpers";

import {
	AnaloguePolygonQueryAtom,
	RehabPolygonBBoxQueryAtom,
} from "./mapQueryAtoms";

export const MapPointerMoveAtomEffect = atomEffect((get) => {
	const map = get(MapAtom);

	let hoverGeom: Feature<Geometry> | undefined;

	function clearHoverStates() {
		if (!hoverGeom) {
			return;
		}
		hoverGeom.set("hover", false);
		hoverGeom = undefined;
		const target = map.getTarget();
		if (target && typeof target !== "string") {
			target.style.cursor = "";
		}
	}

	function handlePointerMove(e: MapBrowserEvent<UIEvent>) {
		const features = map.getFeaturesAtPixel(e.pixel);

		if (!features || features.length === 0) {
			clearHoverStates();
			return;
		}

		const hoverable = features.find(
			(f) =>
				f.get("features") !== undefined ||
				HOVER_SOURCES.includes(f.get("source")),
		);
		if (hoverable) {
			if (hoverable.get("hover")) {
				return;
			}
			const f = hoverable as Feature;
			f.set("hover", true);
			clearHoverStates();
			hoverGeom = f;
			const target = map.getTarget();
			if (target && typeof target !== "string") {
				target.style.cursor = "pointer";
			}
			return;
		}
	}
	map.on("pointermove", handlePointerMove);

	return () => {
		map.un("pointermove", handlePointerMove);
	};
});

export const MapPointerClickAtomEffect = atomEffect((get, set) => {
	const map = get(MapAtom);
	const rehabPolyGeometriesQuery = get(RehabPolygonBBoxQueryAtom);
	const analoguePolygonsQuery = get(AnaloguePolygonQueryAtom);
	const healthGridCellsComparison = get(HealthGridCellsComparisonAtom);
	const mineSitesQuery = get(MineSiteSummariesQueryAtom);

	function handleClick(e: MapBrowserEvent<UIEvent>) {
		const features = map.getFeaturesAtPixel(e.pixel);
		const cluster = features.find((f) => f.get("features") !== undefined);
		if (cluster) {
			const features = cluster.get("features");
			if (!Array.isArray(features)) {
				return;
			}
			set(ZoomToFeaturesAtom, { features: features });
			for (const f of features) {
				if (f instanceof Feature) {
					const siteId = f.get("siteId");
					if (siteId) {
						set(SelectedMineSiteIdAtom, siteId);
						break;
					}
				}
			}
			return;
		}

		const siteInspection = features.find(
			(f) => f.get("source") === "siteinspection",
		);
		if (siteInspection) {
			const id = siteInspection.get("id");
			const siteInspDto = rehabPolyGeometriesQuery.data
				?.flatMap((rp) => rp.siteInspections ?? [])
				.find((si) => si.id === id);
			set(SelectedGeometryAtom, siteInspection);
			set(SelectedSiteInspectionAtom, siteInspDto);
			return;
		}

		const samplingSite = features.find(
			(f) => f.get("source") === "samplingsite",
		);
		if (samplingSite) {
			const id = samplingSite?.get("id");
			const samplingSiteDto = rehabPolyGeometriesQuery.data
				?.flatMap((rp) => rp.quadrats ?? [])
				.find((ss) => ss.id === id);
			set(SelectedGeometryAtom, samplingSite);
			set(SelectedSamplingSiteAtom, samplingSiteDto);
			return;
		}

		const erosionFeature = features.find(
			(f) => f.get("source") === "erosionvector",
		);
		if (erosionFeature) {
			const id = erosionFeature.get("id");
			set(SelectedGeometryAtom, erosionFeature);
			set(SelectedErosionFeatureIdAtom, id);
			return;
		}

		const rehabPoly = features.find(
			(f) => f.get("source") === "rehabpolygon",
		);
		if (rehabPoly) {
			const id = rehabPoly.get("id");
			const poly = rehabPolyGeometriesQuery.data?.find(
				(rp) => rp.id === id,
			);
			set(SelectedGeometryAtom, rehabPoly);
			set(SelectedRehabPolygonIdAtom, poly?.id);
			return;
		}

		const analoguePoly = features.find(
			(f) => f.get("source") === "analoguepolygon",
		);
		if (analoguePoly) {
			const id = analoguePoly.get("id");
			const poly = analoguePolygonsQuery.data?.find((ap) => ap.id === id);
			set(SelectedGeometryAtom, analoguePoly);
			set(SelectedAnaloguePolygon, poly);
			return;
		}

		const weedGridCell = features.find(
			(f) => f.get("source") === "weedgrid",
		);
		if (weedGridCell) {
			const id = weedGridCell.get("id");
			set(SelectedGeometryAtom, weedGridCell);
			set(SelectedWeedGridCellIdAtom, id);
			return;
		}

		const individualTree = features.find(
			(f) => f.get("source") === "individualtree",
		);
		if (individualTree) {
			const id = individualTree.get("id");
			set(SelectedGeometryAtom, individualTree);
			set(SelectedIndividualTreeIdAtom, id);
			return;
		}

		const healthGrid = features.find(
			(f) => f.get("source") === "healthgrid",
		);
		if (healthGrid) {
			const id = healthGrid.get("id");
			const hg = healthGridCellsComparison.find(
				(hg) => hg.locationId === id,
			);
			set(SelectedGeometryAtom, healthGrid);
			set(SelectedHealthGridCellIdAtom, hg?.locationId);
			return;
		}

		const healthZone = features.find(
			(f) => f.get("source") === "healthzone",
		);
		if (healthZone) {
			const id = healthZone.get("id");
			set(SelectedGeometryAtom, healthZone);
			set(SelectedHealthZoneIdAtom, id);
			return;
		}

		const stockpile = features.find(
			(f) => f.get("source") === "topsoilstockpile",
		);
		if (stockpile) {
			const id = stockpile.get("id");
			set(SelectedGeometryAtom, stockpile);
			set(SelectedTopsoilStockpileIdAtom, id);
			return;
		}

		const mineSite = features.find((f) => f.get("source") === "minesite");
		if (mineSite) {
			const id = mineSite.getId();
			const ms = mineSitesQuery.data?.items?.find((ms) => ms.id === id);
			set(SelectedGeometryAtom, mineSite);
			set(SelectedMineSiteIdAtom, ms?.id);
			set(ZoomToFeaturesAtom, {
				features: [mineSite as Feature<Geometry>],
				zoomInOnly: true,
			});
			return;
		}

		set(SelectedGeometryAtom, undefined);
	}
	map.on("click", handleClick);

	return () => {
		map.un("click", handleClick);
	};
});

export const MapViewChangeAtomEffect = atomEffect((get, set) => {
	const map = get(MapAtom);

	function handleViewChange() {
		set(UpdateMapViewState);
	}
	map.on("moveend", handleViewChange);

	return () => {
		map.un("moveend", handleViewChange);
	};
});

export const SelectAndZoomToFeatureAtom = atom(
	null,
	(
		get,
		set,
		featureId: string,
		sourceName: MapSourceType,
		zoomInOnly?: boolean,
		duration?: number,
		geometry?: Geometry | null,
	) => {
		const map = get(MapAtom);
		if (!map) return;
		const feature = getFeatureByIdAndSource(map, sourceName, featureId);
		if (feature) {
			set(SelectedGeometryAtom, feature);
			set(ZoomToFeaturesAtom, {
				features: [feature],
				zoomInOnly,
				duration,
			});
		} else if (geometry) {
			set(ZoomToExtentAtom, {
				extent: geometry.getExtent(),
				zoomInOnly,
				duration,
			});
		} else {
			toast.error(`Feature with id '${featureId}' not found.`);
		}
	},
);

export type ZoomToFeaturesEvent = {
	features: Feature<Geometry>[];
	duration?: number;
	zoomInOnly?: boolean;
};

export const ZoomToFeaturesAtom = atom(
	null,
	(get, set, event: ZoomToFeaturesEvent) => {
		const map = get(MapAtom);
		if (!map) return;
		const coords = event.features.flatMap((r) => {
			const geom = r.getGeometry();
			let ext: Coordinate[] | undefined;
			if (geom instanceof Polygon) {
				ext = geom.getCoordinates().flatMap((r) => r);
			} else if (geom instanceof MultiPolygon) {
				ext = geom
					.getCoordinates()
					.flatMap((r) => r.flatMap((r2) => r2));
			} else if (geom instanceof Point) {
				ext = [geom.getCoordinates()];
			} else if (geom) {
				ext = [geom.getExtent()];
			}
			if (ext) {
				return ext;
			}
			return [];
		});
		const extent = boundingExtent(coords);
		if (extent) {
			set(ZoomToExtentAtom, {
				extent,
				zoomInOnly: !!event.zoomInOnly,
				duration: event.duration,
			});
		}
	},
);

export type ZoomToEvent = {
	extent: Extent;
	duration?: number;
	zoomInOnly?: boolean;
};

export const ZoomToExtentAtom = atom(null, (get, _set, event: ZoomToEvent) => {
	const view = get(MapAtom)?.getView();
	if (!view) return;
	const viewPadding = get(MapViewPaddingAtom);

	if (event.zoomInOnly) {
		const extentResolution = view.getResolutionForExtent(event.extent);
		const extentZoom = view.getZoomForResolution(extentResolution);
		const currentZoom = view.getZoom();
		if (extentZoom && currentZoom && extentZoom < currentZoom) {
			return;
		}
	}

	view.fit(event.extent, {
		duration: event.duration ?? 1500,
		padding: viewPadding,
	});
});
