import client from "@kubb/plugin-client/client";
import type { AnaloguePolygonsGetAnaloguePolygonIdsQueryMutationRequest, AnaloguePolygonsGetAnaloguePolygonIdsQueryMutationResponse } from "../../ts/AnaloguePolygonsGetAnaloguePolygonIdsQuery.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/AnaloguePolygons/getids}
 */
export async function analoguePolygonsGetAnaloguePolygonIdsQuery(data: AnaloguePolygonsGetAnaloguePolygonIdsQueryMutationRequest, config: Partial<RequestConfig<AnaloguePolygonsGetAnaloguePolygonIdsQueryMutationRequest>> = {}) {
    const res = await client<AnaloguePolygonsGetAnaloguePolygonIdsQueryMutationResponse, Error, AnaloguePolygonsGetAnaloguePolygonIdsQueryMutationRequest>({ method: "POST", url: `/api/AnaloguePolygons/getids`, data, ...config });
    return res.data;
}