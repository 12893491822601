import client from "@kubb/plugin-client/client";
import type { StorageLocationsGetStorageLocationsQueryResponse, StorageLocationsGetStorageLocationsQueryParams } from "../../ts/StorageLocationsGetStorageLocations.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/Inventory/StorageLocations}
 */
export async function storageLocationsGetStorageLocations(params?: StorageLocationsGetStorageLocationsQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<StorageLocationsGetStorageLocationsQueryResponse, Error, unknown>({ method: "GET", url: `/api/Inventory/StorageLocations`, params, ...config });
    return res.data;
}