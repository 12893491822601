import {
	ColumnDef,
	ColumnSort,
	createColumnHelper,
} from "@tanstack/react-table";
import React from "react";

import { DataTableSmallTextCell } from "@/components/data-table/data-table-cells";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header";
import {
	RiparianLocationComparisonDto,
	RiparianMetricDataTypeEnum,
	RiparianSurveyComparisonOrderByEnum,
} from "@/lib/gen/eis";

import {
	HealthGridLocationCell,
	HealthLocationMetricResultCell,
} from "./health-grid-table-cells";

const columnHelper = createColumnHelper<RiparianLocationComparisonDto>();

type Props = {
	setColumnSort: React.Dispatch<React.SetStateAction<ColumnSort>>;
};

export const HealthGridColumns = ({ setColumnSort }: Props) =>
	[
		{
			accessorKey: "id",
			enableGlobalFilter: true,
			header: () => <div />,
			cell: ({ row }) => (
				<HealthGridLocationCell id={row.original.locationId} />
			),
			enableSorting: false,
			enableResizing: false,
			minSize: 26,
			maxSize: 26,
			meta: {
				title: "Location",
			},
		},

		columnHelper.accessor((row) => row.locationName, {
			id: RiparianSurveyComparisonOrderByEnum.Name,
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Name"
					columnSortSetter={setColumnSort}
				/>
			),
			cell: ({ getValue }) => (
				<DataTableSmallTextCell value={getValue() ?? "-"} />
			),
			enableHiding: false,
			enableSorting: true,
			size: 100,
			meta: {
				title: "Name",
			},
		}),
	] as ColumnDef<RiparianLocationComparisonDto>[];

export const getLocationMetricResultComparisonColumn = (
	metricName: string,
	metricType: RiparianMetricDataTypeEnum,
	setColumnSort: React.Dispatch<React.SetStateAction<ColumnSort>>,
): ColumnDef<RiparianLocationComparisonDto, unknown> => ({
	accessorKey:
		metricType === RiparianMetricDataTypeEnum.Percent
			? `${metricName}%`
			: metricName,
	header: ({ column }) => (
		<DataTableColumnHeader
			column={column}
			title={
				metricType === RiparianMetricDataTypeEnum.Percent
					? `${metricName} %`
					: `${metricName}`
			}
			columnSortSetter={setColumnSort}
			isDynamicColumn={true}
			orderById={RiparianSurveyComparisonOrderByEnum.MetricSurveyT1} // TODO: By change ordering
			rightAlign
		/>
	),
	cell: ({ row }) => (
		<HealthLocationMetricResultCell
			name={metricName}
			metricComparisons={row.original.metricComparisons ?? []}
		/>
	),
	enableSorting: true,
	meta: {
		title: metricName,
		columnGroup:
			metricType === RiparianMetricDataTypeEnum.MSAVI
				? "Condition"
				: metricType === RiparianMetricDataTypeEnum.Allometric
					? "Allometric"
					: "Structure",
	},
});
