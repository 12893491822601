export enum SlopeAspectEnum {
    "N" = "N",
    "NNE" = "N/NE",
    "NE" = "NE",
    "ENE" = "E/NE",
    "E" = "E",
    "ESE" = "E/SE",
    "SE" = "SE",
    "SSE" = "S/SE",
    "S" = "S",
    "SSW" = "S/SW",
    "SW" = "SW",
    "WSW" = "W/SW",
    "W" = "W",
    "WNW" = "W/NW",
    "NW" = "NW",
    "NNW" = "N/NW"
}

 export type SlopeAspect = SlopeAspectEnum;