import { useAtomValue } from "jotai";
import { useMemo } from "react";

import { SeedsToAllocateAtom } from "@/atoms/seedAppAtoms";
import { ItalicSpeciesName } from "@/components/italic-species-name";
import { Accordion } from "@/components/ui/accordion";
import { Separator } from "@/components/ui/separator";
import { Lifeform, SeedApplicationSpeciesAllocateDto } from "@/lib/gen/eis";

import { LifeformAccordionItem } from "./lifeform-accordion-item";
import { LifeformArray } from "./lifeforms";
import { SeedBatchAllocateTable } from "./seed-batch-allocate-table";

export const SeedAllocationList = ({ lf }: { lf: Lifeform }) => {
	const { data } = useAtomValue(SeedsToAllocateAtom);
	const species = useMemo(() => {
		if (!data) return [];
		return data.filter((s) => s.floraSpecies?.lifeform === lf);
	}, [data, lf]);

	return (
		<div className="flex max-h-full flex-1 flex-col gap-1 overflow-hidden p-3 pr-0">
			<Accordion
				type="multiple"
				className="flex flex-col gap-2 overflow-y-scroll pr-1"
				defaultValue={LifeformArray}
			>
				{species.map((s) => (
					<LifeformAccordionItem
						key={s.id}
						lf={lf}
						isChild
						headerChildren={
							<SeedAllocationListItemHeader species={s} />
						}
						className="p-2"
					>
						<SeedBatchAllocateTable species={s} />
					</LifeformAccordionItem>
				))}
			</Accordion>
		</div>
	);
};

const SeedAllocationListItemHeader = ({
	species,
}: {
	species: SeedApplicationSpeciesAllocateDto;
}) => {
	return (
		<div className="flex size-full items-center justify-between gap-1 px-2">
			<div className="flex flex-col gap-px">
				<span className="text-xs text-muted-foreground">
					Target Species
				</span>
				<ItalicSpeciesName
					className="whitespace-nowrap text-sm font-semibold"
					fullName={species.floraSpecies?.fullName}
				/>
			</div>
			<div className="grid grid-cols-4 gap-2">
				<div className="flex gap-2">
					<VerticalSeparator />
					<div className="flex flex-1 flex-col gap-px text-right">
						<span className="text-xs text-muted-foreground">
							Estimated Quantity
						</span>
						<span className="text-sm font-semibold">
							{species.estimatedQtyDetails?.estimatedQuantity?.toLocaleString(
								undefined,
								{
									maximumFractionDigits: 1,
								},
							)}{" "}
							g
						</span>
					</div>
				</div>
				<div className="flex gap-2">
					<VerticalSeparator />
					<div className="flex flex-1 flex-col gap-px text-right">
						<span className="text-xs text-muted-foreground">
							Allocated Quantity
						</span>
						<span className="text-sm font-semibold">
							{species.totalQuantity?.toLocaleString(undefined, {
								maximumFractionDigits: 1,
							})}{" "}
							g
						</span>
					</div>
				</div>
				<div className="flex gap-2">
					<VerticalSeparator />
					<div className="flex flex-1 flex-col gap-px text-right">
						<span className="text-xs text-muted-foreground">
							Target Cover
						</span>
						<span className="text-sm font-semibold">
							{species.targetCover?.toLocaleString(undefined, {
								maximumFractionDigits: 1,
								style: "percent",
							})}
						</span>
					</div>
				</div>
				<div className="flex gap-2">
					<VerticalSeparator />
					<div className="flex flex-1 flex-col gap-px text-right">
						<span className="text-xs text-muted-foreground">
							Predicted Cover
						</span>
						<span className="text-sm font-semibold">
							{species.allocatedCover?.toLocaleString(undefined, {
								maximumFractionDigits: 1,
								style: "percent",
							})}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

const VerticalSeparator = () => {
	return (
		<div className="flex items-center px-2">
			<Separator
				orientation="vertical"
				className="h-4/5 flex-1 border-l border-black/10"
			/>
		</div>
	);
};
