export enum ProvenanceLandformEnum {
    "Flat" = "Flat",
    "Crest" = "Crest",
    "Slope" = "Slope",
    "LowPlain" = "Low Plain",
    "Riverbank" = "Riverbank",
    "Outcrop" = "Outcrop",
    "LowerSlope" = "Lower Slope",
    "MidSlope" = "Mid Slope",
    "UpperSlope" = "Upper Slope",
    "Hilltop" = "Hilltop",
    "Gully" = "Gully",
    "DrainageLine" = "Drainage Line",
    "Ridge" = "Ridge",
    "Creek" = "Creek",
    "Dune" = "Dune"
}

 export type ProvenanceLandform = ProvenanceLandformEnum;