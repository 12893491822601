import client from "@kubb/plugin-client/client";
import type { TopsoilStockpilesGetStockpileSummaryQueryResponse, TopsoilStockpilesGetStockpileSummaryPathParams } from "../../ts/TopsoilStockpilesGetStockpileSummary.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/TopsoilStockpiles/:id/summary}
 */
export async function topsoilStockpilesGetStockpileSummary(id: TopsoilStockpilesGetStockpileSummaryPathParams["id"], config: Partial<RequestConfig> = {}) {
    const res = await client<TopsoilStockpilesGetStockpileSummaryQueryResponse, Error, unknown>({ method: "GET", url: `/api/TopsoilStockpiles/${id}/summary`, ...config });
    return res.data;
}