"use client";

import { Control, FieldValues, Path } from "react-hook-form";

import Icon from "@/components/icons/icon";
import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "@/components/ui/form";

import { Input } from "./input";

export type InputProps<T extends FieldValues> = {
	control: Control<T>;
	name: Path<T & { [K in Extract<Path<T>, string>]: File }>;
	title?: string;
};

const FileInput = <T extends FieldValues>({
	control,
	name,
	title,
}: InputProps<T>) => {
	return (
		<FormField
			control={control}
			name={name}
			render={({ field: { ref, onChange, value } }) => {
				const file = value as unknown;
				const fileName = FileCheck(file) ? file.name : "";
				return (
					<FormItem>
						<FormLabel>{title}</FormLabel>
						<FormControl>
							<div className="contain-content">
								<div className="flex-col rounded-md border-2 border-dashed border-core-link-text p-3 text-center">
									<Input
										ref={ref}
										className="absolute left-0 top-0 size-full opacity-0"
										type="file"
										id="fileDropRef"
										onChange={(event) => {
											onChange(event.target?.files?.[0]);
										}}
									/>
									<div className="flex flex-col items-center justify-center gap-2 text-center">
										<p className="mb-1">{fileName}</p>
										<Icon
											name="import"
											className="m-auto text-core-link-text"
										/>
										<h3 className="text-core-primary-text">
											Drag & drop your files here or{" "}
											<span className="text-core-link-text">
												Browse File
											</span>
										</h3>
									</div>
								</div>
							</div>
						</FormControl>
						<FormMessage />
					</FormItem>
				);
			}}
		/>
	);
};

const FileCheck = (file: unknown): file is File => {
	return file instanceof File;
};

export { FileInput };
