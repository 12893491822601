import { formatISO } from "date-fns";
import { atom } from "jotai";
import { atomWithQuery } from "jotai-tanstack-query";

import {
	EcologicalSurveyTypeEnum,
	mineSitesGetMineSitesSummary,
	mineSitesGetRehabSurveyMapUrls,
	MiningRegionDto,
	miningRegionsGetMiningRegions,
} from "@/lib/gen/eis";
import { EcologicalSurveyType } from "@/web-api-client";

import { Perm_Rehab_Std, UserHasPermissionsAtom } from "./authAtoms";
import { HealthSurveysAtom } from "./healthAtoms";
import { TimeSeriesDateAtom } from "./timeseriesAtoms";
import { WeedsSurveysAtom } from "./weedsAtoms";

export const SelectedMiningRegionAtom = atom<MiningRegionDto | undefined>(
	undefined,
);
const _selectedMineSiteIdAtom = atom<string | undefined>(undefined);
export const SelectedMineSiteIdAtom = atom(
	(get) => {
		return get(_selectedMineSiteIdAtom);
	},
	(_, set, value: string | undefined) => {
		set(_selectedMineSiteIdAtom, value);
		set(WeedsSurveysAtom);
		set(HealthSurveysAtom);
	},
);

export const SelectedMineSiteAtom = atom((get) => {
	const id = get(SelectedMineSiteIdAtom);
	const mineSites = get(MineSiteSummariesAtom);
	return mineSites?.find((ms) => ms.mineSiteId === id);
});

export const MineSiteSummariesAtom = atom((get) => {
	const { data } = get(MineSiteSummariesQueryAtom);
	return data?.items ?? [];
});

export const MineSiteSummariesQueryAtom = atomWithQuery((get) => {
	const { mineSitePerms } = get(UserHasPermissionsAtom);
	return {
		queryKey: ["mineSitesGetMineSitesSummary"],
		queryFn: async () => {
			return mineSitesGetMineSitesSummary({
				PageNumber: 1,
				PageSize: -1,
			});
		},
		enabled: mineSitePerms,
	};
});

export const MiningRegionSummariesAtom = atom(async (get) => {
	const { mineSitePerms } = get(UserHasPermissionsAtom);
	if (!mineSitePerms) {
		return [];
	}

	return miningRegionsGetMiningRegions().then((data) => {
		return data;
	});
});

export const SurveyUrlsQueryAtom = atomWithQuery((get) => {
	const miningRegion = get(SelectedMiningRegionAtom);
	const mineSiteId = get(SelectedMineSiteIdAtom);
	const timeSeriesDate = get(TimeSeriesDateAtom);
	const userPerms = get(UserHasPermissionsAtom);

	return {
		queryKey: [
			"rehabSurveyMapUrls",
			mineSiteId,
			miningRegion?.id,
			timeSeriesDate.toISOString(),
			EcologicalSurveyType.Rehabilitation,
		],
		queryFn: async () => {
			return mineSitesGetRehabSurveyMapUrls({
				MineSiteId: mineSiteId,
				MiningRegionId: miningRegion?.id,
				TimeSeriesDate: formatISO(timeSeriesDate.toDate(), {
					representation: "complete",
				}),
				EcologicalSurveyType: EcologicalSurveyTypeEnum.Rehabilitation,
			});
		},
		enabled: userPerms[Perm_Rehab_Std],
	};
});

export const AnalogueSurveyUrlsQueryAtom = atomWithQuery((get) => {
	const miningRegion = get(SelectedMiningRegionAtom);
	const mineSiteId = get(SelectedMineSiteIdAtom);
	const timeSeriesDate = get(TimeSeriesDateAtom);
	const userPerms = get(UserHasPermissionsAtom);

	return {
		queryKey: [
			"rehabSurveyMapUrls",
			mineSiteId,
			miningRegion?.id,
			timeSeriesDate.toISOString(),
			EcologicalSurveyType.Analogue,
		],
		queryFn: async () => {
			return mineSitesGetRehabSurveyMapUrls({
				MineSiteId: mineSiteId,
				MiningRegionId: miningRegion?.id,
				TimeSeriesDate: formatISO(timeSeriesDate.toDate(), {
					representation: "complete",
				}),
				EcologicalSurveyType: EcologicalSurveyTypeEnum.Analogue,
			});
		},
		enabled: userPerms[Perm_Rehab_Std],
	};
});

export const SelectedMiningContextSummaryAtom = atom((get) => {
	const miningRegion = get(SelectedMiningRegionAtom);
	const mineSite = get(SelectedMineSiteAtom);
	const mineSites = get(MineSiteSummariesAtom);

	if (mineSite) {
		return {
			mineSiteName: mineSite.mineSiteName,
			numberOfPolygons: mineSite.numberOfPolygons,
			polygonsTotalArea: mineSite.polygonsTotalArea,
		};
	} else if (miningRegion) {
		return {
			mineSiteName: miningRegion.name,
			numberOfPolygons: mineSites
				?.filter((ms) => ms.miningRegionId === miningRegion.id)
				.reduce<number>((a, v) => {
					return a + v.numberOfPolygons;
				}, 0),
			polygonsTotalArea: mineSites
				?.filter((ms) => ms.miningRegionId === miningRegion.id)
				.reduce<number>((a, v) => {
					return a + v.polygonsTotalArea;
				}, 0),
		};
	} else {
		return {
			mineSiteName: "All Sites",
			numberOfPolygons: mineSites?.reduce<number>((a, v) => {
				return a + v.numberOfPolygons;
			}, 0),
			polygonsTotalArea: mineSites?.reduce<number>((a, v) => {
				return a + v.polygonsTotalArea;
			}, 0),
		};
	}
});
