import client from "@kubb/plugin-client/client";
import type { SeedApplicationsRemoveSeedApplicationSpeciesMutationRequest, SeedApplicationsRemoveSeedApplicationSpeciesMutationResponse, SeedApplicationsRemoveSeedApplicationSpeciesPathParams } from "../../ts/SeedApplicationsRemoveSeedApplicationSpecies.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/Inventory/Seeds/Applications/:id/seedApplicationSpecies}
 */
export async function seedApplicationsRemoveSeedApplicationSpecies(id: SeedApplicationsRemoveSeedApplicationSpeciesPathParams["id"], data: SeedApplicationsRemoveSeedApplicationSpeciesMutationRequest, config: Partial<RequestConfig<SeedApplicationsRemoveSeedApplicationSpeciesMutationRequest>> = {}) {
    const res = await client<SeedApplicationsRemoveSeedApplicationSpeciesMutationResponse, Error, SeedApplicationsRemoveSeedApplicationSpeciesMutationRequest>({ method: "DELETE", url: `/api/Inventory/Seeds/Applications/${id}/seedApplicationSpecies`, data, ...config });
    return res.data;
}