import { zodResolver } from "@hookform/resolvers/zod";
import { DialogDescription, DialogTitle } from "@radix-ui/react-dialog";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

import { DialogProps } from "@/components/dialogs/dialogs.atom";
import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
} from "@/components/ui/dialog";
import { FileInput } from "@/components/ui/FileInput";
import { Form } from "@/components/ui/form";
import { clientConfigurationUploadClientLogo } from "@/lib/gen/eis";

const formSchema = z.object({
	file: z
		.instanceof(File)
		.refine(
			(file) => file.type == "image/png" || file.type == "image/jpeg",
			"Please upload an image file.",
		),
});
const ColourChoices = [
	"#CA3432",
	"#3998F0",
	"#19B17F",
	"#F8E21E",
	"#7F3CA8",
	"#F56BA9",
	"#7FFAD0",
	"#E59347",
	"#624122",
];

const UserSettingsDialog = ({ open, setOpen }: DialogProps) => {
	const queryClient = useQueryClient();
	const [clientColour, setClientColour] = useState<string>("");
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
	});
	const { mutate } = useMutation({
		mutationFn: async (file: File) => {
			return clientConfigurationUploadClientLogo({
				Image: file,
				Colour: clientColour,
			});
		},
		onSuccess: () => {
			toast.success("Success", {
				description: "Client logo uploaded.",
			});
			queryClient.invalidateQueries({
				queryKey: ["clientLogo"],
			});
			setOpen(false);
		},
		onError: (error) => {
			toast.error("Failed to upload client settings", {
				description: error.message,
			});
		},
	});

	const submit = async (data: z.infer<typeof formSchema>) => {
		if (!data.file) {
			return;
		}
		mutate(data.file);
	};

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>User Settings</DialogTitle>
					<DialogDescription />
				</DialogHeader>
				<Form {...form}>
					<form
						onSubmit={form.handleSubmit(submit)}
						className="flex-col space-y-3"
					>
						<FileInput
							title="Upload client logo image"
							control={form.control}
							name="file"
						/>
						<div className="grid w-fit cursor-pointer columns-3 gap-1">
							<div className="col-span-3" />
							{ColourChoices.map((c) => (
								<svg
									width={"30"}
									height={"30"}
									viewBox="-2 -2 30 30"
									fill="none"
									onClick={() => setClientColour(c)}
								>
									<circle
										cx="12"
										cy="12"
										r="12"
										fill={c}
										stroke={
											c === clientColour ? "black" : ""
										}
										strokeWidth={3}
									/>
								</svg>
							))}
						</div>
						<DialogFooter>
							<Button type="submit">Save changes</Button>
						</DialogFooter>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	);
};

export default UserSettingsDialog;
