export enum RehabPolysOrderByEnum {
    "Area" = "Area",
    "Name" = "Name",
    "Tag" = "Tag",
    "RehabilitatedDate" = "RehabilitatedDate",
    "Landform" = "Landform",
    "MineSite" = "MineSite",
    "MiningRegion" = "MiningRegion",
    "Quadrats" = "Quadrats",
    "SiteInspections" = "SiteInspections",
    "ErosionFeatures" = "ErosionFeatures",
    "ErosionMaxDepth" = "ErosionMaxDepth",
    "ErosionTotalArea" = "ErosionTotalArea",
    "ErosionTotalVolume" = "ErosionTotalVolume",
    "CompletionStatus" = "CompletionStatus",
    "Status" = "Status",
    "VegClassCoverDynamicColumn" = "VegClassCoverDynamicColumn",
    "CriterionResultDynamicColumn" = "CriterionResultDynamicColumn",
    "Label" = "Label"
}

 export type RehabPolysOrderBy = RehabPolysOrderByEnum;