import client from "@kubb/plugin-client/client";
import type { WeedsSurveysDeleteWeedsSurveyMutationResponse, WeedsSurveysDeleteWeedsSurveyPathParams } from "../../ts/WeedsSurveysDeleteWeedsSurvey.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/WeedsSurveys/:id}
 */
export async function weedsSurveysDeleteWeedsSurvey(id: WeedsSurveysDeleteWeedsSurveyPathParams["id"], config: Partial<RequestConfig> = {}) {
    const res = await client<WeedsSurveysDeleteWeedsSurveyMutationResponse, Error, unknown>({ method: "DELETE", url: `/api/WeedsSurveys/${id}`, ...config });
    return res.data;
}