import client from "@kubb/plugin-client/client";
import type { DataManagementGetTytonModulesQueryResponse } from "../../ts/DataManagementGetTytonModules.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/DataManagement/tytonmodules}
 */
export async function dataManagementGetTytonModules(config: Partial<RequestConfig> = {}) {
    const res = await client<DataManagementGetTytonModulesQueryResponse, Error, unknown>({ method: "GET", url: `/api/DataManagement/tytonmodules`, ...config });
    return res.data;
}