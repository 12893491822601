import client from "@kubb/plugin-client/client";
import type { AnaloguePolygonsGetFilteredExportMutationRequest, AnaloguePolygonsGetFilteredExportMutationResponse } from "../../ts/AnaloguePolygonsGetFilteredExport.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/AnaloguePolygons/filtered-export}
 */
export async function analoguePolygonsGetFilteredExport(data: AnaloguePolygonsGetFilteredExportMutationRequest, config: Partial<RequestConfig<AnaloguePolygonsGetFilteredExportMutationRequest>> = {}) {
    const res = await client<AnaloguePolygonsGetFilteredExportMutationResponse, Error, AnaloguePolygonsGetFilteredExportMutationRequest>({ method: "POST", url: `/api/AnaloguePolygons/filtered-export`, data, ...config });
    return res.data;
}