import client from "@kubb/plugin-client/client";
import type { AnaloguePolygonsGetVegHistoryDatesQueryResponse, AnaloguePolygonsGetVegHistoryDatesPathParams } from "../../ts/AnaloguePolygonsGetVegHistoryDates.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/AnaloguePolygons/:id/veghistorydates}
 */
export async function analoguePolygonsGetVegHistoryDates(id: AnaloguePolygonsGetVegHistoryDatesPathParams["id"], config: Partial<RequestConfig> = {}) {
    const res = await client<AnaloguePolygonsGetVegHistoryDatesQueryResponse, Error, unknown>({ method: "GET", url: `/api/AnaloguePolygons/${id}/veghistorydates`, ...config });
    return res.data;
}