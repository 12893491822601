import client from "@kubb/plugin-client/client";
import type { MonitoringScheduleGetMonitoringScheduleMutationRequest, MonitoringScheduleGetMonitoringScheduleMutationResponse } from "../../ts/MonitoringScheduleGetMonitoringSchedule.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/MonitoringSchedule/paginated}
 */
export async function monitoringScheduleGetMonitoringSchedule(data: MonitoringScheduleGetMonitoringScheduleMutationRequest, config: Partial<RequestConfig<MonitoringScheduleGetMonitoringScheduleMutationRequest>> = {}) {
    const res = await client<MonitoringScheduleGetMonitoringScheduleMutationResponse, Error, MonitoringScheduleGetMonitoringScheduleMutationRequest>({ method: "POST", url: `/api/MonitoringSchedule/paginated`, data, ...config });
    return res.data;
}