import client from "@kubb/plugin-client/client";
import type { AnaloguePolygonsGetVegetationCoverQueryResponse, AnaloguePolygonsGetVegetationCoverPathParams, AnaloguePolygonsGetVegetationCoverQueryParams } from "../../ts/AnaloguePolygonsGetVegetationCover.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/AnaloguePolygons/:id/vegetationcover}
 */
export async function analoguePolygonsGetVegetationCover(id: AnaloguePolygonsGetVegetationCoverPathParams["id"], params?: AnaloguePolygonsGetVegetationCoverQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<AnaloguePolygonsGetVegetationCoverQueryResponse, Error, unknown>({ method: "GET", url: `/api/AnaloguePolygons/${id}/vegetationcover`, params, ...config });
    return res.data;
}