import client from "@kubb/plugin-client/client";
import type { RehabilitationPolygonsGetErosionSummaryQueryResponse, RehabilitationPolygonsGetErosionSummaryQueryParams } from "../../ts/RehabilitationPolygonsGetErosionSummary.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/RehabilitationPolygons/erosionfeatureSummary}
 */
export async function rehabilitationPolygonsGetErosionSummary(params?: RehabilitationPolygonsGetErosionSummaryQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<RehabilitationPolygonsGetErosionSummaryQueryResponse, Error, unknown>({ method: "GET", url: `/api/RehabilitationPolygons/erosionfeatureSummary`, params, ...config });
    return res.data;
}