import { Loader2 } from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";

import { NumberInput, NumberInputProps } from "@/components/ui/input";
import { cn } from "@/lib/utils";

type NumberInputCellProps = {
	input: number;
	onBlurOrEnter: (value: number) => void;
	isPending?: boolean;
	isPercent?: boolean;
	maxValue?: number;
	minValue?: number;
	hasError?: boolean;
};

export const NumberInputCell = ({
	input,
	onBlurOrEnter,
	className,
	isPending,
	isPercent,
	maxValue,
	minValue,
	hasError,
	...props
}: NumberInputCellProps & NumberInputProps) => {
	const [value, setValue] = useState(input);

	const mutatedValue = useMemo(() => {
		if (isPercent) {
			return value * 100;
		}
		return value;
	}, [isPercent, value]);

	const updateValue = useCallback(
		(val: number, onChange?: boolean) => {
			let mutatedVal = isPercent ? val / 100 : val;
			const max = maxValue ?? (isPercent ? 1 : Number.MAX_VALUE);
			const min = minValue ?? (isPercent ? 0 : Number.MIN_VALUE);
			mutatedVal = Math.min(max, mutatedVal);
			mutatedVal = Math.max(min, mutatedVal);
			if (onChange) {
				setValue(mutatedVal);
			} else {
				onBlurOrEnter(mutatedVal);
			}
		},
		[isPercent, maxValue, minValue, onBlurOrEnter],
	);

	useEffect(() => {
		setValue(input);
	}, [input]);

	return (
		<div className={cn("", className)}>
			<NumberInput
				value={mutatedValue}
				onChange={(e) => {
					updateValue(e.target.valueAsNumber, true);
				}}
				onBlur={(e) => updateValue(e.target.valueAsNumber, false)}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						onBlurOrEnter(value);
					}
				}}
				className={cn(
					"h-fit bg-background px-2 py-0.5",
					hasError &&
						"border border-red-500 focus-visible:ring-red-500",
				)}
				disabled={isPending || props.disabled}
				{...props}
			>
				{props.children}
			</NumberInput>
			{isPending && (
				<div className="absolute flex size-full items-center justify-center rounded blur-sm">
					<Loader2 size={16} className="animate-spin" />
				</div>
			)}
		</div>
	);
};
