import client from "@kubb/plugin-client/client";
import type { SeedApplicationsGetSeedApplicationForAllocateQueryResponse, SeedApplicationsGetSeedApplicationForAllocatePathParams } from "../../ts/SeedApplicationsGetSeedApplicationForAllocate.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/Inventory/Seeds/Applications/:id/allocate}
 */
export async function seedApplicationsGetSeedApplicationForAllocate(id: SeedApplicationsGetSeedApplicationForAllocatePathParams["id"], config: Partial<RequestConfig> = {}) {
    const res = await client<SeedApplicationsGetSeedApplicationForAllocateQueryResponse, Error, unknown>({ method: "GET", url: `/api/Inventory/Seeds/Applications/${id}/allocate`, ...config });
    return res.data;
}