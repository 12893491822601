import client from "@kubb/plugin-client/client";
import type { SeedBatchesUpdateMutationRequest, SeedBatchesUpdateMutationResponse, SeedBatchesUpdatePathParams } from "../../ts/SeedBatchesUpdate.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/Inventory/Seeds/Batches/:id}
 */
export async function seedBatchesUpdate(id: SeedBatchesUpdatePathParams["id"], data: SeedBatchesUpdateMutationRequest, config: Partial<RequestConfig<SeedBatchesUpdateMutationRequest>> = {}) {
    const res = await client<SeedBatchesUpdateMutationResponse, Error, SeedBatchesUpdateMutationRequest>({ method: "PUT", url: `/api/Inventory/Seeds/Batches/${id}`, data, ...config });
    return res.data;
}