import { atom } from "jotai";
import { atomWithQuery } from "jotai-tanstack-query";

import { GetEnumAutoCompleteOptions } from "@/helpers/enum-helpers";
import { weatherStationsGetWeatherStations } from "@/lib/gen/eis";
import { WeatherStationProvider } from "@/web-api-client";

import { Perm_Weather_Std, UserPermissionsAtom } from "./authAtoms";

export const WeatherStationsAtom = atom((get) => {
	return get(WeatherStationsQueryAtom).data;
});

export const WeatherStationsQueryAtom = atomWithQuery((get) => {
	const userPerms = get(UserPermissionsAtom);
	return {
		queryKey: ["weatherStations"],
		queryFn: async () => {
			return weatherStationsGetWeatherStations();
		},
		enabled: userPerms?.includes(Perm_Weather_Std),
	};
});

export const WeatherStationProviders = atom(async () => {
	return GetEnumAutoCompleteOptions(WeatherStationProvider);
});
