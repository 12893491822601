import client from "@kubb/plugin-client/client";
import type { RiparianSurveyGetRiparianSpatialSurveyComparisonQueryResponse, RiparianSurveyGetRiparianSpatialSurveyComparisonQueryParams } from "../../ts/RiparianSurveyGetRiparianSpatialSurveyComparison.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/RiparianSurvey/spatialcolourcomparison}
 */
export async function riparianSurveyGetRiparianSpatialSurveyComparison(params?: RiparianSurveyGetRiparianSpatialSurveyComparisonQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<RiparianSurveyGetRiparianSpatialSurveyComparisonQueryResponse, Error, unknown>({ method: "GET", url: `/api/RiparianSurvey/spatialcolourcomparison`, params, ...config });
    return res.data;
}