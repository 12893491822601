import { RehabPolygonArg } from "@/atoms/rehabPolyAtoms";
import { ColumnFilterOption } from "@/components/filtered-search-box/filter-search-box-types";
import { IconName } from "@/components/icons";
import {
	CompletionCriterionGroup,
	CompletionCriterionResultEnum,
	PolygonLandformTypeEnum,
	PolygonOverallResultEnum,
	PredicateFilterTypeEnum,
	RehabilitationPolygonStatusEnum,
	VegetationClassDto,
} from "@/lib/gen/eis";
import { getTypeAheadResults } from "@/services/predicateBuilder.service";

export const FILTER_OPTION_MINE_SITE_NAME: ColumnFilterOption<RehabPolygonArg> =
	{
		key: "mineSiteName",
		label: "Site",
		icon: "site",
		options: (searchTerm?: string) =>
			getTypeAheadResults(
				PredicateFilterTypeEnum.mineSiteName,
				searchTerm,
			),
		keywords: ["mine", "name"],
	};
export const FILTER_OPTION_MINING_REGION_NAME: ColumnFilterOption<RehabPolygonArg> =
	{
		key: "miningRegionName",
		label: "Region",
		icon: "region",
		options: (searchTerm?: string) =>
			getTypeAheadResults(
				PredicateFilterTypeEnum.miningRegionName,
				searchTerm,
			),
		keywords: ["mine", "name"],
	};

export const REHAB_POLYGON_FILTER_OPTION_CRITERIA_OUTCOME: ColumnFilterOption<RehabPolygonArg> =
	{
		key: "polygonOverallResult",
		label: "Criteria Outcome",
		icon: "completion_criteria",
		options: Object.values(PolygonOverallResultEnum).map((value) => ({
			value,
		})),
		type: "enum",
		keywords: ["result"],
	};

export const REHAB_POLYGON_FILTER_OPTIONS: ColumnFilterOption<RehabPolygonArg>[] =
	[
		{
			key: "polygonName",
			label: "Polygon",
			icon: "polygon",
			options: (searchTerm?: string) =>
				getTypeAheadResults(
					PredicateFilterTypeEnum.polygonName,
					searchTerm,
				),
			keywords: ["name"],
		},
		{
			key: "polygonTag",
			label: "Polygon Tag",
			icon: "tag",
			options: (searchTerm?: string) =>
				getTypeAheadResults(
					PredicateFilterTypeEnum.polygonTag,
					searchTerm,
				),
		},
		FILTER_OPTION_MINING_REGION_NAME,
		FILTER_OPTION_MINE_SITE_NAME,
		REHAB_POLYGON_FILTER_OPTION_CRITERIA_OUTCOME,
		{
			key: "quadrats",
			label: "Sampling Sites",
			icon: "quadrat",
			type: "number",
			keywords: ["quadrats"],
		},
		{
			key: "siteInspections",
			label: "Site Inspections",
			icon: "site_inspection",
			type: "number",
		},
		{
			key: "erosionFeatures",
			label: "Erosion Features",
			icon: "erosion",
			type: "number",
		},
		{
			key: "landform",
			label: "Landform",
			icon: "landform",
			options: Object.values(PolygonLandformTypeEnum).map((value) => ({
				value,
			})),
			type: "enum",
		},
		{
			key: "status",
			label: "Status",
			icon: "check",
			options: Object.values(RehabilitationPolygonStatusEnum).map(
				(value) => ({
					value,
				}),
			),
			type: "enum",
		},
		{
			key: "area",
			label: "Area",
			icon: "total_area",
			type: "area",
			sourceUnits: { area: "hectares" },
		},
		{
			key: "rehabilitatedDate",
			label: "Rehabilitation Date",
			icon: "Clock",
			type: "date",
		},
	];

export function getRehabPolygonDynamicFilterVegClassCover(
	vegClassCover: VegetationClassDto,
): ColumnFilterOption<RehabPolygonArg> {
	return {
		key: "vegClassCoverQuery",
		subKey: vegClassCover.id,
		label: `${vegClassCover.name} %`,
		icon: "circle_filled",
		iconColor: vegClassCover.displayColour ?? undefined,
		type: "percentage",
		group: "Classification Covers",
	};
}

export function getRehabPolygonDynamicFilterCriteriaStatus(
	key: string,
	completionCriteriaGroup?: CompletionCriterionGroup,
): ColumnFilterOption<RehabPolygonArg> {
	let icon: IconName = "remote_sensing";
	let iconClassName = "text-card-remotesensing-text";
	switch (completionCriteriaGroup) {
		case "On-Ground":
			icon = "onground";
			iconClassName = "text-card-onground-text";
			break;
		case "Erosion":
			icon = "erosion";
			iconClassName = "text-card-erosion-text";
			break;
	}

	return {
		key: "criteriaStatusQuery",
		subKey: key,
		label: key,
		icon: icon,
		iconClassName: iconClassName,
		options: Object.values(CompletionCriterionResultEnum).map((value) => ({
			value,
		})),
		type: "enum",
		group: "Completion Criteria",
	};
}
