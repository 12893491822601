export enum SamplingsiteObservationsOrderByEnum {
    "ObservationName" = "ObservationName",
    "SamplingSiteName" = "SamplingSiteName",
    "ObservationOnGroundCampaignName" = "ObservationOnGroundCampaignName",
    "VegetationCondition" = "VegetationCondition",
    "ObservationDate" = "ObservationDate",
    "SpeciesCount" = "SpeciesCount",
    "ObserverName" = "ObserverName",
    "Comments" = "Comments"
}

 export type SamplingsiteObservationsOrderBy = SamplingsiteObservationsOrderByEnum;