import dayjs from "dayjs";
import { atom } from "jotai";
import { atomWithQuery } from "jotai-tanstack-query";
import { SetStateAction } from "react";

import {
	EcologicalSurveyTypeEnum,
	rehabilitationSurveysGetRehabilitationSurveysWithPagination,
} from "@/lib/gen/eis";

import { Perm_Rehab_Std, UserPermissionsAtom } from "./authAtoms";
import { SelectedMineSiteIdAtom } from "./miningAtoms";

const _timeSeriesDateAtom = atom<dayjs.Dayjs | undefined>(undefined);
export const TimeSeriesDateAtom = atom((get) => {
	return get(_timeSeriesDateAtom) ?? dayjs().startOf("day");
});
export const SetTimeSeriesDateAtom = atom(
	null,
	(_get, set, setter: SetStateAction<dayjs.Dayjs | undefined>) => {
		set(_timeSeriesDateAtom, setter);
	},
);

export const RemoteSensingSurveysQueryAtom = atomWithQuery((get) => {
	const userPerms = get(UserPermissionsAtom);
	return {
		queryKey: ["remoteSensingSurveys"],
		queryFn: async () => {
			return rehabilitationSurveysGetRehabilitationSurveysWithPagination({
				PageSize: -1,
				PageNumber: 1,
			});
		},
		enabled: userPerms?.includes(Perm_Rehab_Std),
	};
});

export const TimeSeriesMarkersAtom = atom((get) => {
	const mineSiteId = get(SelectedMineSiteIdAtom);
	const { data: surveys } = get(RemoteSensingSurveysQueryAtom);
	return (
		surveys?.items?.filter((rs) => {
			return (
				rs.ecologicalSurveyType ===
					EcologicalSurveyTypeEnum.Rehabilitation &&
				(!mineSiteId || rs.mineSiteId === mineSiteId)
			);
		}) ?? []
	);
});
