import { useAtom, useAtomValue } from "jotai";
import { startTransition } from "react";
import React from "react";

import {
	AutoSelectMetricAtomEffect,
	HealthMetricsAtom,
	SelectedHealthMetricAtom,
	StructureOrConditionSelectionAtom,
} from "@/atoms/healthAtoms";
import {
	HealthGridVisibility,
	IndividualTreeVisibility,
} from "@/atoms/map/layerVisibilityAtoms";
import Icon from "@/components/icons/icon";
import { ClassificationLayerVisibility } from "@/components/MapOpenLayers/classification-layer";
import { MsaviLayerVisibility } from "@/components/MapOpenLayers/Health/msavi-layer";
import { Button } from "@/components/ui/button";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { Tooltip } from "@/components/ui/tooltip";
import { RiparianMetricDataTypeEnum } from "@/lib/gen/eis";

const HealthNavBarControls: React.FC = () => {
	const { data: metrics } = useAtomValue(HealthMetricsAtom);
	const [selectedMetric, setSelectedMetric] = useAtom(
		SelectedHealthMetricAtom,
	);
	const [structureOrCondition, setStructureOrCondition] = useAtom(
		StructureOrConditionSelectionAtom,
	);
	const [classificationVisibility, setClassificationVisibility] = useAtom(
		ClassificationLayerVisibility,
	);
	const [healthGridVisibility, setHealthGridVisibility] =
		useAtom(HealthGridVisibility);
	const [msaviVisibility, setMsaviVisibility] = useAtom(MsaviLayerVisibility);
	useAtom(AutoSelectMetricAtomEffect);
	const [individualTreeVisibility, setIndividualTreeVisibility] = useAtom(
		IndividualTreeVisibility,
	);

	return (
		<div className="-ml-32 flex flex-row gap-1 py-1">
			<div>
				<Tooltip tip="Hide/Show Classification layer">
					<Button
						variant={
							classificationVisibility ? "default" : "outline"
						}
						size={"icon"}
						onClick={() => {
							setClassificationVisibility(
								!classificationVisibility,
							);
						}}
					>
						<Icon name="layer_classification" />
					</Button>
				</Tooltip>
			</div>
			{metrics?.some(
				(m) =>
					m.riparianMetricDataType ===
					RiparianMetricDataTypeEnum.MSAVI,
			) && (
				<div>
					<Tooltip tip="Hide/Show MSAVI layer">
						<Button
							variant={msaviVisibility ? "default" : "outline"}
							size={"icon"}
							onClick={() => {
								setMsaviVisibility(!msaviVisibility);
							}}
						>
							<Icon name="layer_msavi_raster" />
						</Button>
					</Tooltip>
				</div>
			)}
			<div>
				<Tooltip tip="Hide/Show Health Grid layer">
					<Button
						variant={healthGridVisibility ? "default" : "outline"}
						size={"icon"}
						onClick={() => {
							setHealthGridVisibility(!healthGridVisibility);
						}}
					>
						<Icon name="grid_toggle" />
					</Button>
				</Tooltip>
			</div>
			<div className="border-r pr-1">
				<Tooltip tip="Hide/Show Individual Trees layer">
					<Button
						variant={
							individualTreeVisibility ? "default" : "outline"
						}
						size={"icon"}
						onClick={() => {
							setIndividualTreeVisibility(
								!individualTreeVisibility,
							);
						}}
					>
						<Icon name="treeid" />
					</Button>
				</Tooltip>
			</div>

			<div className="w-[150px] bg-core-primary-background">
				<Select
					defaultValue={RiparianMetricDataTypeEnum.Percent}
					value={structureOrCondition}
					onValueChange={(e) =>
						setStructureOrCondition(e as RiparianMetricDataTypeEnum)
					}
				>
					<SelectTrigger className="h-8 border-2 border-core-primary-border bg-core-primary-background">
						<SelectValue />
					</SelectTrigger>
					<SelectContent position="popper">
						<SelectItem value={RiparianMetricDataTypeEnum.Percent}>
							<div className="flex gap-2">
								<Icon
									name="total_area"
									className="text-module-health-primary"
								/>
								Structure
							</div>
						</SelectItem>
						{metrics?.some(
							(m) =>
								m.riparianMetricDataType ===
								RiparianMetricDataTypeEnum.MSAVI,
						) && (
							<SelectItem
								value={RiparianMetricDataTypeEnum.MSAVI}
							>
								<div className="flex gap-2">
									<Icon
										name="health"
										className="text-module-health-primary"
									/>
									Condition
								</div>
							</SelectItem>
						)}
						<SelectItem
							value={RiparianMetricDataTypeEnum.Allometric}
						>
							<div className="flex gap-2">
								<Icon
									name="health"
									className="text-module-health-primary"
								/>
								Allometric
							</div>
						</SelectItem>
					</SelectContent>
				</Select>
			</div>

			<div className="w-[180px] bg-core-primary-background">
				<Select
					defaultValue={selectedMetric?.metricId}
					value={selectedMetric?.metricId}
					onValueChange={(e) => {
						const metric = metrics?.find((m) => m.metricId === e);
						startTransition(() => {
							setSelectedMetric(metric);
						});
					}}
				>
					<SelectTrigger className="h-8 border-2 border-core-primary-border bg-core-primary-background">
						<SelectValue />
					</SelectTrigger>
					<SelectContent position="popper">
						{metrics
							?.filter(
								(m) =>
									structureOrCondition ===
									m.riparianMetricDataType,
							)
							.map((m) => (
								<SelectItem key={m.metricId} value={m.metricId}>
									<div className="flex items-center gap-2">
										<Icon
											name="circle_filled"
											color={m.displayColour ?? ""}
											size={16}
										/>
										{m.displayName}
									</div>
								</SelectItem>
							))}
					</SelectContent>
				</Select>
			</div>
		</div>
	);
};

export default HealthNavBarControls;
