import {
	ColumnDef,
	ColumnSort,
	createColumnHelper,
} from "@tanstack/react-table";

import { DataTableSmallTextCell } from "@/components/data-table/data-table-cells";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header";
import {
	RiparianLocationComparisonDto,
	RiparianSurveyComparisonOrderByEnum,
} from "@/lib/gen/eis";

import { HealthZoneLocationCell } from "./health-zone-table-cells";

const columnHelper = createColumnHelper<RiparianLocationComparisonDto>();

type Props = {
	setColumnSort: React.Dispatch<React.SetStateAction<ColumnSort>>;
};

export const HealthZoneColumns = ({ setColumnSort }: Props) =>
	[
		{
			accessorKey: "id",
			enableGlobalFilter: true,
			header: () => <div />,
			cell: ({ row }) => (
				<HealthZoneLocationCell id={row.original.locationId} />
			),
			enableSorting: false,
			enableResizing: false,
			minSize: 26,
			maxSize: 26,
			meta: {
				title: "Location",
			},
		},

		columnHelper.accessor((row) => row.locationName, {
			id: RiparianSurveyComparisonOrderByEnum.Name,
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Name"
					columnSortSetter={setColumnSort}
				/>
			),
			cell: ({ getValue }) => (
				<DataTableSmallTextCell value={getValue() ?? "-"} />
			),
			enableHiding: false,
			enableSorting: true,
			size: 100,
			meta: {
				title: "Name",
			},
		}),
	] as ColumnDef<RiparianLocationComparisonDto>[];
