import { Accordion } from "@/components/ui/accordion";

import {
	LifeformAccordionItem,
	TargetLifeformAndCoverHeader,
} from "./lifeform-accordion-item";
import { LifeformArray } from "./lifeforms";
import { SeedAllocationList } from "./seed-allocation-list";

export const SeedApplicationAllocateDrawer = () => {
	return (
		<div className="flex max-h-full flex-1 flex-col gap-1 overflow-hidden p-3 pr-0">
			<Accordion
				type="multiple"
				className="flex flex-col gap-2 overflow-y-scroll pr-1"
				defaultValue={LifeformArray}
			>
				{LifeformArray.map((lf) => (
					<LifeformAccordionItem
						key={lf}
						lf={lf}
						headerChildren={
							<TargetLifeformAndCoverHeader lf={lf} />
						}
					>
						<SeedAllocationList lf={lf} />
					</LifeformAccordionItem>
				))}
			</Accordion>
		</div>
	);
};
