import * as DialogPrimitive from "@radix-ui/react-dialog";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "sonner";

import { FloraSpeciesForSeedApplicationReplaceFiltersAtom } from "@/atoms/floraSpeciesAtoms";
import {
	GetSeedApplicationQueryKeyBase,
	SeedApplicationIdAtom,
	SeedApplicationTemplateAtom,
} from "@/atoms/seedAppAtoms";
import { ConfirmationDialogAtom } from "@/components/dialogs/confirmation-dialog";
import { ItalicSpeciesName } from "@/components/italic-species-name";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { autoSetFilter } from "@/helpers/filter-helper";
import { useFilters } from "@/helpers/filter-hook";
import {
	ComparatorEnum,
	FloraSpeciesDto,
	seedApplicationsReplaceTargetSpecies,
	SeedApplicationsReplaceTargetSpeciesMutationRequest,
	VegetationCommunityFloraSpeciesDto,
} from "@/lib/gen/eis";
import {
	FLORA_SPECIES_FILTER_OPTIONS_DISTANCE,
	FLORA_SPECIES_FILTER_OPTIONS_LIFEFORM,
	FLORA_SPECIES_FILTER_OPTIONS_QTY,
} from "@/types/filters/flora-species-filters";

import { FloraSpeciesForSeedAppTable } from "./flora-species-for-seed-app-table";

type Props = {
	floraSpecies: VegetationCommunityFloraSpeciesDto;
	children: React.ReactNode;
} & DialogPrimitive.DialogProps;

export const ReplaceSpeciesDialog = ({
	floraSpecies,
	children,
	...props
}: Props) => {
	const [open, setOpen] = useState(false);
	const seedApplicationId = useAtomValue(SeedApplicationIdAtom);

	return (
		<Dialog open={open} onOpenChange={setOpen} {...props}>
			<DialogTrigger asChild disabled={seedApplicationId == null}>
				{children}
			</DialogTrigger>
			<DialogContent className="w-full max-w-xl">
				<DialogHeader>
					<DialogTitle>Replace Species</DialogTitle>
					<DialogDescription />
				</DialogHeader>
				{open && (
					<ReplaceSpeciesForm
						floraSpecies={floraSpecies}
						setOpen={setOpen}
					/>
				)}
			</DialogContent>
		</Dialog>
	);
};

type FormProps = {
	floraSpecies: VegetationCommunityFloraSpeciesDto;
	setOpen: (open: boolean) => void;
};

const ReplaceSpeciesForm = ({ floraSpecies, setOpen }: FormProps) => {
	const queryClient = useQueryClient();
	const [filters, setFilters] = useFilters(
		FloraSpeciesForSeedApplicationReplaceFiltersAtom,
	);
	const seedApplicationId = useAtomValue(SeedApplicationIdAtom);
	const { data: template } = useAtomValue(SeedApplicationTemplateAtom);
	const targetCover = useMemo(() => {
		return floraSpecies.targetCover.toLocaleString(undefined, {
			minimumFractionDigits: 0,
			maximumFractionDigits: 2,
			style: "percent",
		});
	}, [floraSpecies.targetCover]);
	const confirm = useSetAtom(ConfirmationDialogAtom);
	const { mutate } = useMutation({
		mutationFn: async (
			data: SeedApplicationsReplaceTargetSpeciesMutationRequest,
		) => {
			return seedApplicationsReplaceTargetSpecies(data);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: GetSeedApplicationQueryKeyBase(seedApplicationId),
			});
			setOpen(false);
		},
		onError: (error) => {
			toast.error("Replace Species Failed", {
				description: error.message,
			});
		},
	});

	const onSpeciesSelected = useCallback(
		(selected: FloraSpeciesDto) => {
			confirm({
				title: "Confirm Replacement",
				description: (
					<>
						Are you sure you want to replace '
						<ItalicSpeciesName fullName={floraSpecies.fullName} />'
						with '<ItalicSpeciesName fullName={selected.fullName} />
						'?
					</>
				),
				confirmText: "Replace",
			}).then((confirmed) => {
				if (confirmed) {
					if (!seedApplicationId) {
						return;
					}
					mutate({
						id: seedApplicationId,
						oldFloraSpeciesId: floraSpecies.id,
						newFloraSpeciesId: selected.id,
					});
				}
			});
		},
		[
			confirm,
			floraSpecies.fullName,
			floraSpecies.id,
			mutate,
			seedApplicationId,
		],
	);

	useEffect(() => {
		autoSetFilter(
			setFilters,
			FLORA_SPECIES_FILTER_OPTIONS_LIFEFORM,
			floraSpecies.lifeform,
		);
		autoSetFilter(
			setFilters,
			FLORA_SPECIES_FILTER_OPTIONS_QTY,
			"0",
			ComparatorEnum.GREATER_THAN,
		);
		autoSetFilter(
			setFilters,
			FLORA_SPECIES_FILTER_OPTIONS_DISTANCE,
			[{ value: "10000", label: "10km" }],
			ComparatorEnum.LESS_THAN,
		);
	}, [floraSpecies.lifeform, setFilters]);

	return (
		<div className="flex flex-col gap-3 overflow-hidden">
			<div className="flex items-center justify-between rounded border p-3">
				<div className="flex flex-col gap-1">
					<span className="text-xs uppercase">Target Species</span>
					<div className="text-sm font-semibold">
						<ItalicSpeciesName fullName={floraSpecies.fullName} />
					</div>
				</div>
				<div className="flex flex-col gap-1">
					<span className="text-xs uppercase">Provenance Zone</span>
					<span className="text-sm font-semibold">
						{template?.provenanceZone}
					</span>
				</div>
				<div className="flex flex-col gap-1">
					<span className="text-xs uppercase">Target Cover</span>
					<span className="text-sm font-semibold">{targetCover}</span>
				</div>
			</div>
			<FloraSpeciesForSeedAppTable
				title="Replace Species"
				onSpeciesSelected={(dto) => onSpeciesSelected(dto)}
				filters={filters}
				setFilters={setFilters}
			/>
		</div>
	);
};
