import client from "@kubb/plugin-client/client";
import type { WeedsSurveysGetWeedGridCellsPaginatedMutationRequest, WeedsSurveysGetWeedGridCellsPaginatedMutationResponse } from "../../ts/WeedsSurveysGetWeedGridCellsPaginated.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/WeedsSurveys/paginated}
 */
export async function weedsSurveysGetWeedGridCellsPaginated(data: WeedsSurveysGetWeedGridCellsPaginatedMutationRequest, config: Partial<RequestConfig<WeedsSurveysGetWeedGridCellsPaginatedMutationRequest>> = {}) {
    const res = await client<WeedsSurveysGetWeedGridCellsPaginatedMutationResponse, Error, WeedsSurveysGetWeedGridCellsPaginatedMutationRequest>({ method: "POST", url: `/api/WeedsSurveys/paginated`, data, ...config });
    return res.data;
}