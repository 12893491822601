import client from "@kubb/plugin-client/client";
import type { WeedsSurveysCreateEditWeedsSurveyMutationRequest, WeedsSurveysCreateEditWeedsSurveyMutationResponse } from "../../ts/WeedsSurveysCreateEditWeedsSurvey.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/WeedsSurveys}
 */
export async function weedsSurveysCreateEditWeedsSurvey(data: WeedsSurveysCreateEditWeedsSurveyMutationRequest, config: Partial<RequestConfig<WeedsSurveysCreateEditWeedsSurveyMutationRequest>> = {}) {
    const res = await client<WeedsSurveysCreateEditWeedsSurveyMutationResponse, Error, WeedsSurveysCreateEditWeedsSurveyMutationRequest>({ method: "POST", url: `/api/WeedsSurveys`, data, ...config });
    return res.data;
}