import client from "@kubb/plugin-client/client";
import type { MineSitesUpdateMutationRequest, MineSitesUpdateMutationResponse, MineSitesUpdatePathParams } from "../../ts/MineSitesUpdate.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/MineSites/:id}
 */
export async function mineSitesUpdate(id: MineSitesUpdatePathParams["id"], data?: MineSitesUpdateMutationRequest, config: Partial<RequestConfig<MineSitesUpdateMutationRequest>> = {}) {
    const formData = new FormData();
    if (data) {
        Object.keys(data).forEach((key) => {
            const value = data[key as keyof typeof data];
            if (typeof key === "string" && (typeof value === "string" || value instanceof Blob)) {
                formData.append(key, value);
            }
        });
    }
    const res = await client<MineSitesUpdateMutationResponse, Error, MineSitesUpdateMutationRequest>({ method: "PUT", url: `/api/MineSites/${id}`, data: formData, headers: { "Content-Type": "multipart/form-data", ...config.headers }, ...config });
    return res.data;
}