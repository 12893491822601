import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import {
	HealthGridCellsComparisonAtom,
	SelectedHealthGridCellIdAtom,
} from "@/atoms/healthAtoms";
import { HealthGridLayerAtom, LayerSource } from "@/atoms/map/layerAtoms";
import { HealthGridVisibility } from "@/atoms/map/layerVisibilityAtoms";
import { SelectedGeometryAtom } from "@/atoms/mapAtoms";
import { populateVectorLayer } from "@/components/MapOpenLayers/vector-layer-populate";

export const HealthGridLayerSourceAtom = atom<LayerSource>({
	source: undefined,
});

const layerSourceAtomEffect = atomEffect((get, set) => {
	const source = get(HealthGridLayerAtom).getSource();
	if (!source) return;
	const onChange = () => {
		set(HealthGridLayerSourceAtom, { source });
	};
	source.on("change", onChange);

	return () => {
		source.un("change", onChange);
	};
});

export const HealthGridLayerAtomEffect = atomEffect((get) => {
	get(populateAtomEffect);
	get(layerSourceAtomEffect);
});

const populateAtomEffect = atomEffect((get, set) => {
	const visible = get(HealthGridVisibility);
	if (!visible) return;

	const healthGrids = get(HealthGridCellsComparisonAtom);
	if (healthGrids == null) return;

	const data = healthGrids.map((d) => ({
		id: d.locationId,
		name: d.locationName,
		...d,
	}));
	const layer = get(HealthGridLayerAtom);

	populateVectorLayer({
		data,
		layer,
		source: "healthgrid",
		onUnselect: () => set(SelectedGeometryAtom, undefined),
		onSelect: (data, feature) => {
			set(SelectedGeometryAtom, feature);
			set(SelectedHealthGridCellIdAtom, data.locationId);
		},
		properties: {
			colour: "colourHexString",
		},
	});
});
