import {
	CompletionCriterionClassEnum,
	CompletionCriterionGroupEnum,
} from "@/lib/gen/eis";

export function GetEnumAutoCompleteOptions<T extends Record<string, string>>(
	enumObj: T,
) {
	return Object.entries(enumObj).map((res) => {
		return { name: res[1], id: res[1] };
	});
}

/** Quick copy of `GetCompletionCriteriaGroup` from backend */
export const GetCompletionCriterionGroup = (
	completionCriterionClass: CompletionCriterionClassEnum,
): CompletionCriterionGroupEnum => {
	switch (completionCriterionClass) {
		case CompletionCriterionClassEnum.SpeciesRichness:
			return CompletionCriterionGroupEnum.OnGround;
		case CompletionCriterionClassEnum.Erosion:
			return CompletionCriterionGroupEnum.Erosion;
		default:
			return CompletionCriterionGroupEnum.RemoteSensing;
	}
};
