import client from "@kubb/plugin-client/client";
import type { BareAreasGetBareAreasByExtentQueryResponse, BareAreasGetBareAreasByExtentQueryParams } from "../../ts/BareAreasGetBareAreasByExtent.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/BareAreas/search}
 */
export async function bareAreasGetBareAreasByExtent(params?: BareAreasGetBareAreasByExtentQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<BareAreasGetBareAreasByExtentQueryResponse, Error, unknown>({ method: "GET", url: `/api/BareAreas/search`, params, ...config });
    return res.data;
}