import client from "@kubb/plugin-client/client";
import type { IndividualTreesGetIndividualTreeQueryResponse, IndividualTreesGetIndividualTreeQueryParams } from "../../ts/IndividualTreesGetIndividualTree.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/IndividualTrees/singletree}
 */
export async function individualTreesGetIndividualTree(params?: IndividualTreesGetIndividualTreeQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<IndividualTreesGetIndividualTreeQueryResponse, Error, unknown>({ method: "GET", url: `/api/IndividualTrees/singletree`, params, ...config });
    return res.data;
}