import { useAtomValue } from "jotai";
import { lazy, Suspense, useMemo } from "react";

import { Perm_Topsoil_Std, UserPermissionsAtom } from "@/atoms/authAtoms";
import { CurrentViewAtom } from "@/atoms/eisViewAtoms";
import { SelectedTopsoilStockpileIdAtom } from "@/atoms/topsoilAtoms";
import {
	GetCardComponent,
	LazyCardComponent,
} from "@/components/card-components";

const TopsoilSummaryCard = {
	component: lazy(
		() => import("@/components/ModuleCards/Topsoil/TopsoilSummaryCard"),
	),
	permission: Perm_Topsoil_Std,
} as const satisfies LazyCardComponent;

export const LazyTopsoilSummaryCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);
	const selectedStockpileId = useAtomValue(SelectedTopsoilStockpileIdAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(TopsoilSummaryCard, userPerms);
		if (comp) {
			if (currentView?.name === "Topsoil" && !selectedStockpileId)
				return comp;
		}
		return null;
	}, [currentView?.name, selectedStockpileId, userPerms]);

	return (
		<Suspense fallback={null}>
			{Component && <Component id={"TopsoilSummaryCard"} />}
		</Suspense>
	);
};

const StockpilesWeedAlertsCard = {
	component: lazy(
		() =>
			import("@/components/ModuleCards/Topsoil/StockpilesWeedAlertsCard"),
	),
	permission: Perm_Topsoil_Std,
} as const satisfies LazyCardComponent;

export const LazyStockpilesWeedAlertsCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);
	const selectedStockpileId = useAtomValue(SelectedTopsoilStockpileIdAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(StockpilesWeedAlertsCard, userPerms);
		if (comp) {
			if (currentView?.name === "Topsoil" && !selectedStockpileId)
				return comp;
		}
		return null;
	}, [currentView?.name, selectedStockpileId, userPerms]);

	return (
		<Suspense fallback={null}>
			{Component && <Component id={"StockpilesWeedAlertsCard"} />}
		</Suspense>
	);
};

const TopsoilRequirementsCard = {
	component: lazy(
		() =>
			import("@/components/ModuleCards/Topsoil/TopsoilRequirementsCard"),
	),
	permission: Perm_Topsoil_Std,
} as const satisfies LazyCardComponent;

export const LazyTopsoilRequirementsCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);
	const selectedStockpileId = useAtomValue(SelectedTopsoilStockpileIdAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(TopsoilRequirementsCard, userPerms);
		if (comp) {
			if (currentView?.name === "Topsoil" && !selectedStockpileId)
				return comp;
		}
		return null;
	}, [currentView?.name, selectedStockpileId, userPerms]);

	return (
		<Suspense fallback={null}>
			{Component && <Component id={"TopsoilRequirementsCard"} />}
		</Suspense>
	);
};

const StockpileMovementsCard = {
	component: lazy(
		() =>
			import("@/components/ModuleCards/Topsoil/StockpilesMovementsCard"),
	),
	permission: Perm_Topsoil_Std,
} as const satisfies LazyCardComponent;

export const LazyStockpileMovementsCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(StockpileMovementsCard, userPerms);
		if (comp) {
			if (currentView?.name === "Topsoil") return comp;
		}
		return null;
	}, [currentView?.name, userPerms]);

	return (
		<Suspense fallback={null}>
			{Component && <Component id={"StockpileMovementsCard"} />}
		</Suspense>
	);
};

const StockpileSummaryCard = {
	component: lazy(
		() => import("@/components/ModuleCards/Topsoil/StockpileSummaryCard"),
	),
	permission: Perm_Topsoil_Std,
} as const satisfies LazyCardComponent;

export const LazyStockpileSummaryCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);
	const selectedStockpileId = useAtomValue(SelectedTopsoilStockpileIdAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(StockpileSummaryCard, userPerms);
		if (comp) {
			if (currentView?.name === "Topsoil" && selectedStockpileId)
				return comp;
		}
		return null;
	}, [currentView?.name, selectedStockpileId, userPerms]);

	return (
		<Suspense fallback={null}>
			{Component && <Component id={"StockpileSummaryCard"} />}
		</Suspense>
	);
};
