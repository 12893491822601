import client from "@kubb/plugin-client/client";
import type { ErosionGetErosionFeautureIdsQueryMutationRequest, ErosionGetErosionFeautureIdsQueryMutationResponse } from "../../ts/ErosionGetErosionFeautureIdsQuery.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/Erosion/getids}
 */
export async function erosionGetErosionFeautureIdsQuery(data: ErosionGetErosionFeautureIdsQueryMutationRequest, config: Partial<RequestConfig<ErosionGetErosionFeautureIdsQueryMutationRequest>> = {}) {
    const res = await client<ErosionGetErosionFeautureIdsQueryMutationResponse, Error, ErosionGetErosionFeautureIdsQueryMutationRequest>({ method: "POST", url: `/api/Erosion/getids`, data, ...config });
    return res.data;
}