import {
	FloraSpeciesArg,
	FloraSpeciesForSeedApplicationArg,
} from "@/atoms/floraSpeciesAtoms";
import { ColumnFilterOption } from "@/components/filtered-search-box/filter-search-box-types";
import {
	LifeformEnum,
	LongevityEnum,
	NaturalisedStatusEnum,
	PredicateFilterTypeEnum,
} from "@/lib/gen/eis";
import { getTypeAheadResults } from "@/services/predicateBuilder.service";

export const FLORA_SPECIES_FILTER_OPTIONS_LIFEFORM = {
	key: "lifeform",
	label: "Lifeform",
	icon: "Tree",
	options: Object.values(LifeformEnum).map((value) => ({
		value,
	})),
	type: "enum",
} satisfies ColumnFilterOption<FloraSpeciesArg>;

export const FLORA_SPECIES_FILTER_OPTIONS_QTY = {
	key: "availableQuantity",
	label: "Available Qty",
	icon: "Bean",
	type: "number",
} satisfies ColumnFilterOption<FloraSpeciesArg>;

export const FLORA_SPECIES_FILTER_OPTIONS_DISTANCE = {
	key: "samplingDistance",
	label: "Sampling Distance",
	icon: "Ruler",
	type: "distance",
} satisfies ColumnFilterOption<FloraSpeciesForSeedApplicationArg>;

export const FLORA_SPECIES_FILTER_OPTIONS: ColumnFilterOption<FloraSpeciesArg>[] =
	[
		{
			key: "speciesFullName",
			label: "Flora Species",
			icon: "polygon",
			options: (searchTerm?: string) =>
				getTypeAheadResults(
					PredicateFilterTypeEnum.speciesFullName,
					searchTerm,
				),
			keywords: ["name"],
		},
		{
			key: "family",
			label: "Family",
			icon: "tag",
			options: (searchTerm?: string) =>
				getTypeAheadResults(PredicateFilterTypeEnum.family, searchTerm),
		},
		{
			key: "genus",
			label: "Genus",
			icon: "tag",
			options: (searchTerm?: string) =>
				getTypeAheadResults(PredicateFilterTypeEnum.genus, searchTerm),
		},
		{
			key: "naturalisedStatus",
			label: "Naturalised Status",
			icon: "landform",
			options: Object.values(NaturalisedStatusEnum).map((value) => ({
				value,
			})),
			type: "enum",
		},
		FLORA_SPECIES_FILTER_OPTIONS_LIFEFORM,
		{
			key: "longevity",
			label: "Longevity",
			icon: "Tree",
			options: Object.values(LongevityEnum).map((value) => ({
				value,
			})),
			type: "enum",
		},
		FLORA_SPECIES_FILTER_OPTIONS_QTY,
	];

export const FLORA_SPECIES_SEED_APP_FILTER_OPTIONS: ColumnFilterOption<FloraSpeciesForSeedApplicationArg>[] =
	[...FLORA_SPECIES_FILTER_OPTIONS];
