import client from "@kubb/plugin-client/client";
import type { ImageryCapturesGetImageryCapturesWithPaginationQueryResponse, ImageryCapturesGetImageryCapturesWithPaginationQueryParams } from "../../ts/ImageryCapturesGetImageryCapturesWithPagination.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/ImageryCaptures}
 */
export async function imageryCapturesGetImageryCapturesWithPagination(params?: ImageryCapturesGetImageryCapturesWithPaginationQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<ImageryCapturesGetImageryCapturesWithPaginationQueryResponse, Error, unknown>({ method: "GET", url: `/api/ImageryCaptures`, params, ...config });
    return res.data;
}