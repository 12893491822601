import { SetStateAction } from "jotai";

import {
	ColumnFilter,
	ColumnFilterOption,
} from "@/components/filtered-search-box/filter-search-box-types";
import { ComparatorEnum, ValueWithLabel } from "@/lib/gen/eis";

export function autoSetFilter<T extends string>(
	set: (prev: SetStateAction<ColumnFilter<T>[]>) => void,
	filter: ColumnFilterOption<T>,
	value?: ValueWithLabel[] | string | null,
	comparator: ComparatorEnum = ComparatorEnum.EQUALS,
) {
	const values = typeof value === "string" ? [{ value }] : value ?? [];
	set((prev) => {
		if (
			values.length > 0 &&
			prev.findIndex(
				(f) =>
					f.filter.key === filter.key &&
					f.filter.subKey === filter.subKey &&
					f.values.length === values.length &&
					f.values.map((v) => v.value ?? "").join(",") ===
						values.map((v) => v.value ?? "").join(",") &&
					f.comparator === comparator,
			) !== -1
		)
			return prev;

		if (values.length > 0) {
			return [
				...prev.filter(
					(f) =>
						!(
							f.filter.key === filter.key &&
							f.filter.subKey === filter.subKey
						),
				),
				{
					filter,
					values,
					comparator: comparator,
				},
			];
		} else {
			return [
				...prev.filter(
					(f) =>
						!(
							f.filter.key === filter.key &&
							f.filter.subKey === filter.subKey
						),
				),
			];
		}
	});
}
