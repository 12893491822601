import { useAtomValue } from "jotai";
import { lazy, Suspense, useMemo } from "react";

import { Perm_Riparian_Std, UserPermissionsAtom } from "@/atoms/authAtoms";
import { CurrentViewAtom } from "@/atoms/eisViewAtoms";
import {
	SelectedHealthGridCellIdAtom,
	SelectedHealthZoneIdAtom,
} from "@/atoms/healthAtoms";
import {
	GetCardComponent,
	LazyCardComponent,
} from "@/components/card-components";

const RiparianSystemSummaryCard = {
	component: lazy(
		() => import("@/components/Riparian/RiparianSystemSummaryCard"),
	),
	permission: Perm_Riparian_Std,
} as const satisfies LazyCardComponent;

export const LazyRiparianSystemSummaryCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(RiparianSystemSummaryCard, userPerms);
		if (comp) {
			if (currentView?.name === "Health") return comp;
		}
		return null;
	}, [currentView?.name, userPerms]);

	return (
		<Suspense fallback={null}>
			{Component && <Component id={"RiparianSystemSummaryCard"} />}
		</Suspense>
	);
};

const RiparianGridCellSummaryCard = {
	component: lazy(
		() => import("@/components/Riparian/RiparianGridCellSummaryCard"),
	),
	permission: Perm_Riparian_Std,
} as const satisfies LazyCardComponent;

export const LazyRiparianGridCellSummaryCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);
	const selectedHealthGridCell = useAtomValue(SelectedHealthGridCellIdAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(RiparianGridCellSummaryCard, userPerms);
		if (comp) {
			if (currentView?.name === "Health" && selectedHealthGridCell)
				return comp;
		}
		return null;
	}, [currentView?.name, selectedHealthGridCell, userPerms]);

	return (
		<Suspense fallback={null}>
			{Component && <Component id={"RiparianGridCellSummaryCard"} />}
		</Suspense>
	);
};

const RiparianZoneSummaryCard = {
	component: lazy(
		() => import("@/components/Riparian/RiparianZoneSummaryCard"),
	),
	permission: Perm_Riparian_Std,
} as const satisfies LazyCardComponent;

export const LazyRiparianZoneSummaryCard = () => {
	const userPerms = useAtomValue(UserPermissionsAtom);
	const currentView = useAtomValue(CurrentViewAtom);
	const selectedHealthZoneId = useAtomValue(SelectedHealthZoneIdAtom);
	const selectedHealthGridCell = useAtomValue(SelectedHealthGridCellIdAtom);

	const Component = useMemo(() => {
		const comp = GetCardComponent(RiparianZoneSummaryCard, userPerms);
		if (comp) {
			if (
				currentView?.name === "Health" &&
				selectedHealthZoneId &&
				!selectedHealthGridCell
			)
				return comp;
		}
		return null;
	}, [
		currentView?.name,
		selectedHealthGridCell,
		selectedHealthZoneId,
		userPerms,
	]);

	return (
		<Suspense fallback={null}>
			{Component && <Component id={"RiparianZoneSummaryCard"} />}
		</Suspense>
	);
};
