import client from "@kubb/plugin-client/client";
import type { IndividualTreesGetIndividualTreeGeometriesMutationRequest, IndividualTreesGetIndividualTreeGeometriesMutationResponse } from "../../ts/IndividualTreesGetIndividualTreeGeometries.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/IndividualTrees/geometries}
 */
export async function individualTreesGetIndividualTreeGeometries(data: IndividualTreesGetIndividualTreeGeometriesMutationRequest, config: Partial<RequestConfig<IndividualTreesGetIndividualTreeGeometriesMutationRequest>> = {}) {
    const res = await client<IndividualTreesGetIndividualTreeGeometriesMutationResponse, Error, IndividualTreesGetIndividualTreeGeometriesMutationRequest>({ method: "POST", url: `/api/IndividualTrees/geometries`, data, ...config });
    return res.data;
}