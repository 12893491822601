import client from "@kubb/plugin-client/client";
import type { TopsoilStockpilesGetStockpileStatusesQueryResponse, TopsoilStockpilesGetStockpileStatusesQueryParams } from "../../ts/TopsoilStockpilesGetStockpileStatuses.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/TopsoilStockpiles/status}
 */
export async function topsoilStockpilesGetStockpileStatuses(params?: TopsoilStockpilesGetStockpileStatusesQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<TopsoilStockpilesGetStockpileStatusesQueryResponse, Error, unknown>({ method: "GET", url: `/api/TopsoilStockpiles/status`, params, ...config });
    return res.data;
}