import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { useCallback, useMemo } from "react";

import {
	GetSeedApplicationQueryKeyBase,
	SeedApplicationIdAtom,
} from "@/atoms/seedAppAtoms";
import { NumberInputCell } from "@/components/data-table/input-cells";
import { ShowAxiosErrorToast } from "@/helpers/error-helper";
import {
	AllocationOrderBatchAllocateDto,
	seedApplicationsUpdateBatchAllocation,
	SeedApplicationsUpdateBatchAllocationMutationRequest,
	seedApplicationsUpdateSeedApplicationSpeciesTarget,
	SeedApplicationsUpdateSeedApplicationSpeciesTargetMutationRequest,
	VegetationCommunityFloraSpeciesDto,
} from "@/lib/gen/eis";

type Props = {
	floraSpecies: VegetationCommunityFloraSpeciesDto;
};

export const EditTargetCoverCell = ({ floraSpecies }: Props) => {
	const queryClient = useQueryClient();
	const seedApplicationId = useAtomValue(SeedApplicationIdAtom);
	const { mutate, isPending, isError } = useMutation({
		mutationFn: async (
			data: SeedApplicationsUpdateSeedApplicationSpeciesTargetMutationRequest,
		) => {
			return seedApplicationsUpdateSeedApplicationSpeciesTarget(data);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: GetSeedApplicationQueryKeyBase(seedApplicationId),
			});
		},
		onError: (error) => {
			ShowAxiosErrorToast(error, "Updating Target Cover Failed");
		},
	});

	const updateTargetCover = useCallback(
		(value: number) => {
			mutate({
				seedApplicationSpeciesId: floraSpecies.id,
				updatedTargetCover: value,
			});
		},
		[floraSpecies.id, mutate],
	);

	return (
		<NumberInputCell
			input={floraSpecies.targetCover}
			onBlurOrEnter={updateTargetCover}
			isPending={isPending}
			className="flex w-full justify-end [&>div]:max-w-24"
			disabled={seedApplicationId == null}
			isPercent
			hasError={isError}
		>
			%
		</NumberInputCell>
	);
};

export const EditEstablishmentRateCell = ({ floraSpecies }: Props) => {
	const queryClient = useQueryClient();
	const seedApplicationId = useAtomValue(SeedApplicationIdAtom);
	const { mutate, isPending, isError } = useMutation({
		mutationFn: async (
			data: SeedApplicationsUpdateSeedApplicationSpeciesTargetMutationRequest,
		) => {
			return seedApplicationsUpdateSeedApplicationSpeciesTarget(data);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: GetSeedApplicationQueryKeyBase(seedApplicationId),
			});
		},
		onError: (error) => {
			ShowAxiosErrorToast(error, "Updating Establishment Rate Failed");
		},
	});

	const updateEstablishmentRate = useCallback(
		(value: number) => {
			mutate({
				seedApplicationSpeciesId: floraSpecies.id,
				updatedEstablishmentRate: value,
			});
		},
		[floraSpecies.id, mutate],
	);

	return (
		<NumberInputCell
			input={floraSpecies.establishmentRate}
			onBlurOrEnter={updateEstablishmentRate}
			isPending={isPending}
			className="flex w-full justify-end [&>div]:max-w-24"
			disabled={seedApplicationId == null}
			isPercent
			hasError={isError}
		>
			%
		</NumberInputCell>
	);
};

export const EditBatchAllocationCell = ({
	sasId,
	batch,
}: {
	sasId: string;
	batch: AllocationOrderBatchAllocateDto;
}) => {
	const queryClient = useQueryClient();
	const seedApplicationId = useAtomValue(SeedApplicationIdAtom);
	const { mutate, isPending, isError } = useMutation({
		mutationFn: async (
			data: SeedApplicationsUpdateBatchAllocationMutationRequest,
		) => {
			return seedApplicationsUpdateBatchAllocation(data);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: GetSeedApplicationQueryKeyBase(seedApplicationId),
			});
		},
		onError: (error) => {
			ShowAxiosErrorToast(error, "Updating Allocation Failed");
		},
	});

	const maxValue = useMemo(() => {
		if (!batch.seedBatch) return 0;
		return batch.seedBatch.availableQuantity + batch.quantity;
	}, [batch]);

	const updateBatch = useCallback(
		(value: number) => {
			if (!batch.seedBatch) return;
			if (value === batch.quantity) return;
			mutate({
				seedApplicationSpeciesId: sasId,
				seedBatchId: batch.seedBatch.id,
				previousAllocationQty: batch.quantity,
				allocationQty: value,
			});
		},
		[batch.quantity, batch.seedBatch, mutate, sasId],
	);

	return (
		<NumberInputCell
			input={batch.quantity}
			onBlurOrEnter={updateBatch}
			isPending={isPending}
			className="flex w-full justify-end [&>div]:max-w-24"
			disabled={seedApplicationId == null}
			maxValue={maxValue}
			minValue={0}
			hasError={isError}
		>
			g
		</NumberInputCell>
	);
};
