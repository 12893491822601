import client from "@kubb/plugin-client/client";
import type { MiningRegionsCreateMutationRequest, MiningRegionsCreateMutationResponse } from "../../ts/MiningRegionsCreate.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/MiningRegions}
 */
export async function miningRegionsCreate(data?: MiningRegionsCreateMutationRequest, config: Partial<RequestConfig<MiningRegionsCreateMutationRequest>> = {}) {
    const res = await client<MiningRegionsCreateMutationResponse, Error, MiningRegionsCreateMutationRequest>({ method: "POST", url: `/api/MiningRegions`, data, ...config });
    return res.data;
}