import client from "@kubb/plugin-client/client";
import type { RiparianSurveyGetRiparianZoneGridCellMetricsHistoryQueryResponse, RiparianSurveyGetRiparianZoneGridCellMetricsHistoryQueryParams } from "../../ts/RiparianSurveyGetRiparianZoneGridCellMetricsHistory.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/RiparianSurvey/gridmetricshistory}
 */
export async function riparianSurveyGetRiparianZoneGridCellMetricsHistory(params?: RiparianSurveyGetRiparianZoneGridCellMetricsHistoryQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<RiparianSurveyGetRiparianZoneGridCellMetricsHistoryQueryResponse, Error, unknown>({ method: "GET", url: `/api/RiparianSurvey/gridmetricshistory`, params, ...config });
    return res.data;
}