import { useAtomValue, useSetAtom } from "jotai";
import { memo, useMemo } from "react";

import { GoToRiparianZone } from "@/atoms/healthAtoms";
import { DataTableLocationPinCell } from "@/components/data-table/data-table-location-pin";
import { HealthZoneLayerSourceAtom } from "@/components/MapOpenLayers/Health/healthZoneLayer";

export const HealthZoneLocationCell = memo(function ({ id }: { id: string }) {
	const source = useAtomValue(HealthZoneLayerSourceAtom);
	const disabled = useMemo(() => {
		const f = source.source?.getFeatureById(id);
		return f == null;
	}, [id, source]);
	const goToZone = useSetAtom(GoToRiparianZone);

	return (
		<DataTableLocationPinCell
			GoToLocation={() => goToZone(id)}
			disabled={disabled}
		/>
	);
});
