import { AxiosError } from "axios";
import { toast } from "sonner";

export const ParseAxiosErrorForValidationErrors = (error: Error) => {
	if (!(error instanceof AxiosError)) {
		return;
	}
	const validationErrors = error.response?.data?.errors as Record<
		string,
		string[]
	>;
	const title = error.response?.data?.title as string;
	if (!validationErrors || !title) {
		return;
	}
	return { validationErrors, title };
};

export const ShowAxiosErrorToast = (error: Error, header?: string) => {
	const err = ParseAxiosErrorForValidationErrors(error);
	if (!err) {
		if (!(error instanceof AxiosError)) {
			toast.error(header ?? error.name, {
				description: error.message,
			});
		} else {
			toast.error(header ?? error.response?.data?.title ?? error.name, {
				description: error.response?.data?.detail,
			});
		}
	} else {
		toast.error(err.title, {
			description: (
				<AxiosErrorToastDescription errors={err.validationErrors} />
			),
			duration: Infinity,
		});
	}
};

const AxiosErrorToastDescription = ({
	errors,
}: {
	errors: Record<string, string[]>;
}) => {
	return (
		<ul>
			{Object.entries(errors).map(([key, value]) => (
				<li key={key}>
					{key}: {value.join(", ")}
				</li>
			))}
		</ul>
	);
};
