import client from "@kubb/plugin-client/client";
import type { SeedApplicationTemplatesGetSeedApplicationTemplateForPlanQueryResponse, SeedApplicationTemplatesGetSeedApplicationTemplateForPlanPathParams, SeedApplicationTemplatesGetSeedApplicationTemplateForPlanQueryParams } from "../../ts/SeedApplicationTemplatesGetSeedApplicationTemplateForPlan.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/Inventory/Seeds/Applications/Templates/:id/plan}
 */
export async function seedApplicationTemplatesGetSeedApplicationTemplateForPlan(id: SeedApplicationTemplatesGetSeedApplicationTemplateForPlanPathParams["id"], params?: SeedApplicationTemplatesGetSeedApplicationTemplateForPlanQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<SeedApplicationTemplatesGetSeedApplicationTemplateForPlanQueryResponse, Error, unknown>({ method: "GET", url: `/api/Inventory/Seeds/Applications/Templates/${id}/plan`, params, ...config });
    return res.data;
}