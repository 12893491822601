import client from "@kubb/plugin-client/client";
import type { RehabilitationPolygonsGetErosionVolumeHistoryQueryResponse, RehabilitationPolygonsGetErosionVolumeHistoryQueryParams } from "../../ts/RehabilitationPolygonsGetErosionVolumeHistory.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/RehabilitationPolygons/erosionvolumehistory}
 */
export async function rehabilitationPolygonsGetErosionVolumeHistory(params?: RehabilitationPolygonsGetErosionVolumeHistoryQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<RehabilitationPolygonsGetErosionVolumeHistoryQueryResponse, Error, unknown>({ method: "GET", url: `/api/RehabilitationPolygons/erosionvolumehistory`, params, ...config });
    return res.data;
}