import { SamplingSiteArg } from "@/atoms/rehabPolyAtoms";
import { ColumnFilterOption } from "@/components/filtered-search-box/filter-search-box-types";
import {
	PredicateFilterTypeEnum,
	SamplingSiteLandformTypeEnum,
	SamplingSiteTypeEnum,
	SiteSamplingMethodEnum,
	SlopeAspectEnum,
} from "@/lib/gen/eis";
import { getTypeAheadResults } from "@/services/predicateBuilder.service";

export const SAMPLING_SITE_FILTER_OPTION_MINE_SITE: ColumnFilterOption<SamplingSiteArg> =
	{
		key: "mineSiteName",
		label: "Site",
		icon: "site",
		options: (searchTerm?: string) =>
			getTypeAheadResults(
				PredicateFilterTypeEnum.mineSiteName,
				searchTerm,
			),
		keywords: ["mine", "name"],
		type: "string",
	};
export const SAMPLING_SITE_FILTER_OPTION_MINING_REGION_NAME: ColumnFilterOption<SamplingSiteArg> =
	{
		key: "miningRegionName",
		label: "Region",
		icon: "region",
		options: (searchTerm?: string) =>
			getTypeAheadResults(
				PredicateFilterTypeEnum.miningRegionName,
				searchTerm,
			),
		keywords: ["mine", "name"],
	};
export const SAMPLING_SITE_FILTER_OPTION_REHAB_POLYGON_NAME: ColumnFilterOption<SamplingSiteArg> =
	{
		key: "rehabilitationPolygonName",
		label: "Rehabilitation Polygon Name",
		icon: "polygon",
		options: (searchTerm?: string) =>
			getTypeAheadResults(
				PredicateFilterTypeEnum.polygonName,
				searchTerm,
			),
		type: "string",
	};

export const SAMPLING_SITE_FILTER_OPTIONS: ColumnFilterOption<SamplingSiteArg>[] =
	[
		{
			key: "samplingSiteName",
			label: "Sampling Site Name",
			icon: "quadrat",
			options: (searchTerm?: string) =>
				getTypeAheadResults(
					PredicateFilterTypeEnum.samplingSiteName,
					searchTerm,
				),
			type: "string",
		},
		{
			key: "samplingSiteType",
			label: "Sampling Type",
			icon: "Filter",
			options: Object.values(SamplingSiteTypeEnum).map((value) => ({
				value,
			})),
			type: "enum",
		},
		{
			key: "siteSamplingMethod",
			label: "Sampling Method",
			icon: "Filter",
			options: Object.values(SiteSamplingMethodEnum).map((value) => ({
				value,
			})),
			type: "enum",
		},
		{
			key: "slopeAspect",
			label: "Slope Aspect",
			icon: "Filter",
			options: Object.values(SlopeAspectEnum).map((value) => ({
				value,
			})),
			type: "enum",
		},
		{
			key: "samplingSiteLandformType",
			label: "Landform",
			icon: "landform",
			options: Object.values(SamplingSiteLandformTypeEnum).map(
				(value) => ({
					value,
				}),
			),
			type: "enum",
		},
		{
			key: "area",
			label: "Area",
			icon: "total_area",
			type: "area",
		},
		SAMPLING_SITE_FILTER_OPTION_MINE_SITE,
		SAMPLING_SITE_FILTER_OPTION_MINING_REGION_NAME,
		SAMPLING_SITE_FILTER_OPTION_REHAB_POLYGON_NAME,
	];
