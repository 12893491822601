import client from "@kubb/plugin-client/client";
import type { AnaloguePolygonsGetPaginatedMutationRequest, AnaloguePolygonsGetPaginatedMutationResponse } from "../../ts/AnaloguePolygonsGetPaginated.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/AnaloguePolygons/paginated}
 */
export async function analoguePolygonsGetPaginated(data: AnaloguePolygonsGetPaginatedMutationRequest, config: Partial<RequestConfig<AnaloguePolygonsGetPaginatedMutationRequest>> = {}) {
    const res = await client<AnaloguePolygonsGetPaginatedMutationResponse, Error, AnaloguePolygonsGetPaginatedMutationRequest>({ method: "POST", url: `/api/AnaloguePolygons/paginated`, data, ...config });
    return res.data;
}