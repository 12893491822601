import client from "@kubb/plugin-client/client";
import type { MineSitesGetTopsoilDashboardSummaryQueryResponse, MineSitesGetTopsoilDashboardSummaryQueryParams } from "../../ts/MineSitesGetTopsoilDashboardSummary.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/MineSites/topsoil}
 */
export async function mineSitesGetTopsoilDashboardSummary(params?: MineSitesGetTopsoilDashboardSummaryQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<MineSitesGetTopsoilDashboardSummaryQueryResponse, Error, unknown>({ method: "GET", url: `/api/MineSites/topsoil`, params, ...config });
    return res.data;
}