import { useSetAtom } from "jotai";
import { EllipsisIcon, Import } from "lucide-react";

import { DialogsAtom } from "@/components/dialogs/dialogs.atom";
import { Button } from "@/components/ui/button";
import {
	Menubar,
	MenubarContent,
	MenubarItem,
	MenubarMenu,
	MenubarTrigger,
} from "@/components/ui/menubar";

import { BulkImportSeedsDialog } from "./bulk-import-seeds-dialog";

export const SeedActionMenu = () => {
	const openDialog = useSetAtom(DialogsAtom);

	return (
		<Menubar>
			<MenubarMenu>
				<MenubarTrigger asChild>
					<Button
						onClick={(e) => {
							e.stopPropagation();
						}}
						variant="gentleghost"
						className="flex h-6 gap-1 p-2"
					>
						<EllipsisIcon
							size={20}
							className="shrink-0 text-white"
						/>
					</Button>
				</MenubarTrigger>
				<MenubarContent className="bg-core-primary-background">
					<MenubarItem
						className="gap-1"
						onClick={() => {
							openDialog(BulkImportSeedsDialog);
						}}
					>
						<Import size={16} className="shrink-0" />
						<span>Import Seed Batches</span>
					</MenubarItem>
				</MenubarContent>
			</MenubarMenu>
		</Menubar>
	);
};
