import client from "@kubb/plugin-client/client";
import type { RehabilitationPolygonsGetRehabilitationPolygonsByExtentMutationRequest, RehabilitationPolygonsGetRehabilitationPolygonsByExtentMutationResponse } from "../../ts/RehabilitationPolygonsGetRehabilitationPolygonsByExtent.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/RehabilitationPolygons/search}
 */
export async function rehabilitationPolygonsGetRehabilitationPolygonsByExtent(data: RehabilitationPolygonsGetRehabilitationPolygonsByExtentMutationRequest, config: Partial<RequestConfig<RehabilitationPolygonsGetRehabilitationPolygonsByExtentMutationRequest>> = {}) {
    const res = await client<RehabilitationPolygonsGetRehabilitationPolygonsByExtentMutationResponse, Error, RehabilitationPolygonsGetRehabilitationPolygonsByExtentMutationRequest>({ method: "POST", url: `/api/RehabilitationPolygons/search`, data, ...config });
    return res.data;
}