import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { useAtomValue } from "jotai";
import { ChevronDown, PlusIcon } from "lucide-react";
import { useMemo } from "react";

import { SeedApplicationTemplateAtom } from "@/atoms/seedAppAtoms";
import Icon from "@/components/icons/icon";
import { AccordionContent, AccordionItem } from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { Lifeform } from "@/lib/gen/eis";
import { cn } from "@/lib/utils";

import { AddSpeciesDialog } from "./add-species-dialog";
import { LifeformClasses } from "./lifeforms";

type Props = {
	lf: Lifeform;
	isChild?: boolean;
	children: React.ReactNode;
	headerChildren?: React.ReactNode;
	buttonChildren?: React.ReactNode;
	className?: string;
};

export const LifeformAccordionItem = ({
	lf,
	isChild,
	children,
	headerChildren,
	buttonChildren,
	className,
}: Props) => {
	const classes = LifeformClasses[lf];

	return (
		<AccordionItem
			value={lf}
			className={cn(
				"flex w-full flex-1 flex-col rounded-l-sm border-l-[6px]",
				isChild ? classes.border : classes.thickBorder,
			)}
		>
			<AccordionPrimitive.Header asChild>
				<div
					className={cn(
						"flex h-12 w-full items-center justify-between gap-1 rounded-r-sm border border-l-0 pl-2 data-[state=open]:rounded-br-none",
						classes.border,
						isChild ? classes.childBackground : classes.background,
					)}
				>
					{headerChildren}
					<div className="flex h-full">
						{buttonChildren}
						<AccordionPrimitive.Trigger
							asChild
							className={cn(
								"rounded-l-none border-l data-[state=open]:rounded-br-none [&[data-state=open]_svg]:rotate-0",
								classes.border,
							)}
						>
							<Button variant="ghost" className="h-full p-4">
								<ChevronDown
									size={16}
									className="shrink-0 -rotate-90 text-misc-tyton-green-primary transition-transform duration-200"
								/>
							</Button>
						</AccordionPrimitive.Trigger>
					</div>
				</div>
			</AccordionPrimitive.Header>
			<AccordionContent
				className={cn(
					"rounded-br-sm border-b border-r",
					classes.border,
					className,
				)}
			>
				{children}
			</AccordionContent>
		</AccordionItem>
	);
};

export const AddSpeciesButton = ({ lf }: { lf: Lifeform }) => {
	const classes = LifeformClasses[lf];

	return (
		<AddSpeciesDialog lf={lf}>
			<Button
				variant="ghost"
				className={cn(
					"flex h-full items-center gap-1 rounded-none border-l text-misc-tyton-green-primary",
					classes.border,
				)}
			>
				<PlusIcon size={16} />
				<span className="text-sm font-semibold">Add Species</span>
			</Button>
		</AddSpeciesDialog>
	);
};

export const TargetLifeformAndCoverHeader = ({ lf }: { lf: Lifeform }) => {
	const classes = LifeformClasses[lf];
	const { data } = useAtomValue(SeedApplicationTemplateAtom);

	const targetCover = useMemo(() => {
		if (!data) return 0;
		const cover =
			data.lifeformDtos?.find((dto) => dto.lifeform === lf)
				?.targetCover ?? 0;
		return cover.toLocaleString(undefined, {
			style: "percent",
			maximumFractionDigits: 1,
		});
	}, [data, lf]);

	return (
		<div className="grid grid-cols-2 gap-2">
			<div className="flex min-w-48 items-center gap-2">
				<Icon name={lf} className={classes.text} size={20} />
				<span className="font-semibold">{lf}</span>
			</div>
			<div className="flex items-center gap-2">
				<span className="text-xs text-muted-foreground">
					Target Lifeform Cover:
				</span>
				<span className="text-sm font-semibold">{targetCover}</span>
			</div>
		</div>
	);
};
