import client from "@kubb/plugin-client/client";
import type { RehabilitationPolygonsGetVegetationCoverQueryResponse, RehabilitationPolygonsGetVegetationCoverPathParams, RehabilitationPolygonsGetVegetationCoverQueryParams } from "../../ts/RehabilitationPolygonsGetVegetationCover.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/RehabilitationPolygons/:id/vegetationcover}
 */
export async function rehabilitationPolygonsGetVegetationCover(id: RehabilitationPolygonsGetVegetationCoverPathParams["id"], params?: RehabilitationPolygonsGetVegetationCoverQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<RehabilitationPolygonsGetVegetationCoverQueryResponse, Error, unknown>({ method: "GET", url: `/api/RehabilitationPolygons/${id}/vegetationcover`, params, ...config });
    return res.data;
}