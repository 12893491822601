import client from "@kubb/plugin-client/client";
import type { MineSitesGetMineSitesSummaryQueryResponse, MineSitesGetMineSitesSummaryQueryParams } from "../../ts/MineSitesGetMineSitesSummary.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/MineSites}
 */
export async function mineSitesGetMineSitesSummary(params?: MineSitesGetMineSitesSummaryQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<MineSitesGetMineSitesSummaryQueryResponse, Error, unknown>({ method: "GET", url: `/api/MineSites`, params, ...config });
    return res.data;
}