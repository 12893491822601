import client from "@kubb/plugin-client/client";
import type { RiparianSurveyGetRiparianZoneMetricsHistoryQueryResponse, RiparianSurveyGetRiparianZoneMetricsHistoryQueryParams } from "../../ts/RiparianSurveyGetRiparianZoneMetricsHistory.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/RiparianSurvey/zonemetricshistory}
 */
export async function riparianSurveyGetRiparianZoneMetricsHistory(params?: RiparianSurveyGetRiparianZoneMetricsHistoryQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<RiparianSurveyGetRiparianZoneMetricsHistoryQueryResponse, Error, unknown>({ method: "GET", url: `/api/RiparianSurvey/zonemetricshistory`, params, ...config });
    return res.data;
}