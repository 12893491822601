import client from "@kubb/plugin-client/client";
import type { MiningRegionsGetMiningRegionsQueryResponse, MiningRegionsGetMiningRegionsQueryParams } from "../../ts/MiningRegionsGetMiningRegions.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/MiningRegions}
 */
export async function miningRegionsGetMiningRegions(params?: MiningRegionsGetMiningRegionsQueryParams, config: Partial<RequestConfig> = {}) {
    const res = await client<MiningRegionsGetMiningRegionsQueryResponse, Error, unknown>({ method: "GET", url: `/api/MiningRegions`, params, ...config });
    return res.data;
}