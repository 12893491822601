export enum SeedOrderByEnum {
    "Name" = "Name",
    "Status" = "Status",
    "Provenance" = "Provenance",
    "Family" = "Family",
    "Lifeform" = "Lifeform",
    "Cost" = "Cost",
    "MinimumBeforeReorder" = "MinimumBeforeReorder",
    "QuantityInStock" = "QuantityInStock",
    "AvailableQuantity" = "AvailableQuantity"
}

 export type SeedOrderBy = SeedOrderByEnum;