import { icons as lucideIconList } from "lucide-react";

import { Lifeform } from "@/lib/gen/eis";

const lifeformIcons = {
	Tree: "/icons/lifeform-tree.svg",
	Shrub: "/icons/lifeform-shrub.svg",
	"Hummock Grass": "/icons/lifeform-hummock.svg",
	"Other Grass": "/icons/lifeform-tussock.svg",
	Herb: "/icons/lifeform-herb.svg",
	// TODO add sedge icon
	Sedge: "/icons/lifeform-herb.svg",
} satisfies Record<Lifeform, string>;

export const localIcons = {
	analogue_polygon: "icons/analoguepolygon.svg",
	add: "/icons/add.svg",
	all_modules: "/icons/all_modules.svg",
	bare_areas: "/icons/bare_areas.svg",
	bell: "/icons/bell.svg",
	biodiversity: "/icons/biodiversity.svg",
	calendar: "/icons/calendar.svg",
	carbon_module: "/icons/carbon_module.svg",
	check: "/icons/check.svg",
	circle_filled: "/icons/circle_filled.svg",
	column_settings: "/icons/column_settings.svg",
	completion_criteria_colours: "/icons/completion_criteria_colours.svg",
	completion_criteria: "/icons/completion_criteria.svg",
	cond_mon: "/icons/cond_mon.svg",
	conditioning: "/icons/conditioning.svg",
	config: "/icons/config.svg",
	climate: "/icons/climate.svg",
	criterion: "/icons/criterion.svg",
	custom_views: "/icons/custom_views.svg",
	data_export: "/icons/data_export.svg",
	disturbed_polygon: "/icons/disturbed_polygon.svg",
	erosion_avg_depth: "/icons/erosion_avg_depth.svg",
	erosion_avg_width: "/icons/erosion_avg_width.svg",
	erosion_features: "/icons/erosion_features.svg",
	erosion_max_depth: "/icons/erosion_max_depth.svg",
	erosion_max_width: "/icons/erosion_max_width.svg",
	erosion_module: "/icons/erosion_module.svg",
	erosion: "/icons/erosion.svg",
	fauna_module: "/icons/fauna_module.svg",
	fertiliser: "/icons/fertiliser.svg",
	flora: "/icons/flora.svg",
	greater_than: "/icons/greater_than.svg",
	hierarchy: "/icons/hierarchy.svg",
	house: "/icons/house.svg",
	human: "/icons/human.svg",
	import: "/icons/import.svg",
	landform: "/icons/landform.svg",
	layer_aerial_imagery: "/icons/layer_aerial_imagery.svg",
	layer_classification: "/icons/layer_classification.svg",
	layer_erosion_raster: "/icons/layer_erosion_raster.svg",
	layer_msavi_raster: "/icons/layer_msavi_raster.svg",
	layers: "/icons/layers.svg",
	less_than: "/icons/less_than.svg",
	location_on: "/icons/location_on.svg",
	monitoring_module: "/icons/monitoring_module.svg",
	no_data: "/icons/no_data.svg",
	observation: "/icons/observation.svg",
	onground: "/icons/onground.svg",
	open_drawer: "/icons/open_drawer.svg",
	organisation: "/icons/organisation.svg",
	photos: "/icons/photos.svg",
	polygon: "/icons/polygon.svg",
	quadrat: "/icons/quadrat.svg",
	react: "/icons/react.svg",
	recent: "/icons/recent.svg",
	recommendation: "/icons/recommendation.svg",
	region: "/icons/region.svg",
	rehabilitation: "/icons/rehabilitation.svg",
	remote_sensing: "/icons/remote_sensing.svg",
	reporting: "/icons/reporting.svg",
	riparian_stability_down: "/icons/riparian_stability_down.svg",
	riparian_stability_stable: "/icons/riparian_stability_stable.svg",
	riparian_stability_up: "/icons/riparian_stability_up.svg",
	riparian_system: "/icons/riparian_system.svg",
	ripping: "/icons/ripping.svg",
	rock_armour: "/icons/rock_armour.svg",
	search: "/icons/search.svg",
	seeds_module: "/icons/seed_module.svg",
	seed: "/icons/seed.svg",
	settings: "/icons/settings.svg",
	site_inspection_module: "/icons/site_inspection_module.svg",
	site_inspection: "/icons/site_inspection.svg",
	site: "/icons/site.svg",
	slider: "/icons/slider.svg",
	smart_analytics: "/icons/smart_analytics.svg",
	species_found: "/icons/species_found.svg",
	species_native: "/icons/species_native.svg",
	tag: "/icons/tag.svg",
	topsoil_module: "/icons/topsoil_module.svg",
	topsoil: "/icons/topsoil.svg",
	total_area: "/icons/total_area.svg",
	treatments: "/icons/treatments.svg",
	tyton_eis_yellow: "/icons/tyton_eis_yellow.svg",
	tyton_eis: "/icons/tyton_eis.svg",
	health_module: "/icons/veg_condition_module.svg",
	health: "/icons/veg_condition.svg",
	vegcover: "/icons/vegcover.svg",
	volume: "/icons/volume.svg",
	weed_cover: "/icons/weed_cover.svg",
	weeds_module: "/icons/weed_module.svg",
	weed_patches: "/icons/weed_patches.svg",
	weeds: "/icons/weeds.svg",
	world: "/icons/world.svg",
	zone: "/icons/zone.svg",
	grid_toggle: "icons/grid_toggle.svg",
	...lifeformIcons,
	treeid: "icons/treeid.svg",
	diameter: "icons/diameter.svg",
	maxtreeheight: "icons/maxtreeheight.svg",
	meantreeheight: "icons/meantreeheight.svg",
	stockpiles: "icons/stockpiles.svg",
	volume_topsoil: "icons/volume_topsoil.svg",
	allocated: "icons/allocated.svg",
	deficit: "icons/deficit.svg",
	topsoil_applications: "icons/topsoil_applications.svg",
	topsoil_requirements: "icons/topsoil_requirements.svg",
	stockpile_movements: "icons/stockpile_movements.svg",
	topsoil_available: "icons/topsoil_available.svg",
};

export const allIcons = {
	...localIcons,
	...lucideIconList,
};

export type LocalIconName = keyof typeof localIcons;
export type IconName = keyof typeof allIcons;
export type LucideIconName = keyof typeof lucideIconList;

export function isLocalIconName(
	value: string | undefined | null,
): value is LocalIconName {
	if (!value) return false;
	return Object.keys(localIcons).includes(value);
}
export function isIconName(
	value: string | undefined | null,
): value is IconName {
	if (!value) return false;
	return Object.keys(allIcons).includes(value);
}

export const IconList = Object.keys(allIcons) as IconName[];
