export enum ComparatorEnum {
    "EQUALS" = "EQUALS",
    "NOT_EQUALS" = "NOT_EQUALS",
    "GREATER_THAN" = "GREATER_THAN",
    "LESS_THAN" = "LESS_THAN",
    "GREATER_THAN_OR_EQUAL" = "GREATER_THAN_OR_EQUAL",
    "LESS_THAN_OR_EQUAL" = "LESS_THAN_OR_EQUAL",
    "IN" = "IN",
    "NOT_IN" = "NOT_IN",
    "CONTAINS" = "CONTAINS",
    "DOES_NOT_CONTAIN" = "DOES_NOT_CONTAIN",
    "STARTS_WITH" = "STARTS_WITH",
    "DOES_NOT_START_WITH" = "DOES_NOT_START_WITH",
    "ENDS_WITH" = "ENDS_WITH",
    "DOES_NOT_END_WITH" = "DOES_NOT_END_WITH"
}

 export type Comparator = ComparatorEnum;