import client from "@kubb/plugin-client/client";
import type { SeedBatchesGetSeedBatchQueryResponse, SeedBatchesGetSeedBatchPathParams } from "../../ts/SeedBatchesGetSeedBatch.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/Inventory/Seeds/Batches/:id}
 */
export async function seedBatchesGetSeedBatch(id: SeedBatchesGetSeedBatchPathParams["id"], config: Partial<RequestConfig> = {}) {
    const res = await client<SeedBatchesGetSeedBatchQueryResponse, Error, unknown>({ method: "GET", url: `/api/Inventory/Seeds/Batches/${id}`, ...config });
    return res.data;
}