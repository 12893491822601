import client from "@kubb/plugin-client/client";
import type { RehabilitationSurveysUpdateMutationRequest, RehabilitationSurveysUpdateMutationResponse, RehabilitationSurveysUpdatePathParams } from "../../ts/RehabilitationSurveysUpdate.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";
import { rehabilitationSurveysUpdateMutationResponseSchema } from "../../zod/rehabilitationSurveysUpdateSchema.ts";

 /**
 * {@link /api/RehabilitationSurveys/:id}
 */
export async function rehabilitationSurveysUpdate(id: RehabilitationSurveysUpdatePathParams["id"], data: RehabilitationSurveysUpdateMutationRequest, config: Partial<RequestConfig<RehabilitationSurveysUpdateMutationRequest>> = {}) {
    const res = await client<RehabilitationSurveysUpdateMutationResponse, Error, RehabilitationSurveysUpdateMutationRequest>({ method: "PUT", url: `/api/RehabilitationSurveys/${id}`, data, ...config });
    return rehabilitationSurveysUpdateMutationResponseSchema.parse(res.data);
}