import client from "@kubb/plugin-client/client";
import type { SamplingSitesGetFilteredExportMutationRequest, SamplingSitesGetFilteredExportMutationResponse } from "../../ts/SamplingSitesGetFilteredExport.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/SamplingSites/export}
 */
export async function samplingSitesGetFilteredExport(data: SamplingSitesGetFilteredExportMutationRequest, config: Partial<RequestConfig<SamplingSitesGetFilteredExportMutationRequest>> = {}) {
    const res = await client<SamplingSitesGetFilteredExportMutationResponse, Error, SamplingSitesGetFilteredExportMutationRequest>({ method: "POST", url: `/api/SamplingSites/export`, data, ...config });
    return res.data;
}