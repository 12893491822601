import client from "@kubb/plugin-client/client";
import type { SiteInspectionsGetRehabPolySiteInspectionsMutationRequest, SiteInspectionsGetRehabPolySiteInspectionsMutationResponse } from "../../ts/SiteInspectionsGetRehabPolySiteInspections.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/SiteInspections/paginated}
 */
export async function siteInspectionsGetRehabPolySiteInspections(data: SiteInspectionsGetRehabPolySiteInspectionsMutationRequest, config: Partial<RequestConfig<SiteInspectionsGetRehabPolySiteInspectionsMutationRequest>> = {}) {
    const res = await client<SiteInspectionsGetRehabPolySiteInspectionsMutationResponse, Error, SiteInspectionsGetRehabPolySiteInspectionsMutationRequest>({ method: "POST", url: `/api/SiteInspections/paginated`, data, ...config });
    return res.data;
}