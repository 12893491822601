export enum FloraSpeciesOrderByEnum {
    "FullName" = "FullName",
    "Family" = "Family",
    "Genus" = "Genus",
    "Lifeform" = "Lifeform",
    "InfraRank" = "InfraRank",
    "InfraSpecies" = "InfraSpecies",
    "NaturalisedStatus" = "NaturalisedStatus",
    "Longevity" = "Longevity",
    "AvailableQuantity" = "AvailableQuantity"
}

 export type FloraSpeciesOrderBy = FloraSpeciesOrderByEnum;