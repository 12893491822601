import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { InfoIcon } from "lucide-react";
import { useMemo } from "react";

import { DataTable } from "@/components/data-table/data-table";
import {
	DataTableNumberCell,
	DataTableSmallTextCell,
	DataTableTimeSinceCell,
} from "@/components/data-table/data-table-cells";
import { DataTableColumnHeader } from "@/components/data-table/data-table-column-header";
import { Tooltip } from "@/components/ui/tooltip";
import {
	AllocationOrderBatchAllocateDto,
	SeedApplicationSpeciesAllocateDto,
	SeedBatchGerminationTestDto,
} from "@/lib/gen/eis";

import { EditBatchAllocationCell } from "./seed-app-input-cells";

export const SeedBatchAllocateTable = ({
	species,
}: {
	species: SeedApplicationSpeciesAllocateDto;
}) => {
	const columns = useMemo(() => {
		return getSeedBatchColumns(species.id);
	}, [species.id]);

	const rows = useMemo(() => {
		const dtos = species.seedBatches ?? [];
		return dtos;
	}, [species.seedBatches]);

	return (
		<>
			{rows.length > 0 ? (
				<DataTable
					title={"Seed Application Allocate"}
					columns={columns}
					data={rows}
					isLoading={false}
					hideTotalRows
					rowHeight={36}
					serverSide={false}
				/>
			) : (
				<div>No Seed Batches Available</div>
			)}
		</>
	);
};

const columnHelper = createColumnHelper<AllocationOrderBatchAllocateDto>();
const getSeedBatchColumns = (sasId: string) => {
	return [
		columnHelper.accessor("seedBatch.seedBatchNo", {
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Ref.No" />
			),
			cell: ({ getValue }) => (
				<DataTableSmallTextCell value={getValue()} />
			),
			enableSorting: true,
			size: 130,
		}),
		columnHelper.accessor((row) => row.seedBatch?.collectionDate, {
			id: "collectionDate",
			header: ({ column }) => (
				<DataTableColumnHeader column={column} title="Age" />
			),
			cell: ({ getValue }) => (
				<DataTableTimeSinceCell value={getValue()} />
			),
			enableSorting: true,
			size: 100,
		}),
		columnHelper.accessor(
			(row) => getPureDUs(row.seedBatch?.seedBatchGerminationTest),
			{
				id: "pureDUs",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Pure DUs/g"
						rightAlign
					/>
				),
				cell: ({ getValue, row }) => (
					<DataTableNumberCell
						title=""
						value={getValue()}
						isInteger
						affix="prefix"
					>
						<Tooltip
							tip={
								<PureDUsTooltip
									pureDUs={getValue()}
									germinationTest={
										row.original.seedBatch
											?.seedBatchGerminationTest
									}
								/>
							}
						>
							<InfoIcon
								className="text-muted-foreground"
								size={12}
							/>
						</Tooltip>
					</DataTableNumberCell>
				),
				enableSorting: true,
				size: 150,
			},
		),
		columnHelper.accessor("seedBatch.availableQuantity", {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Available (g)"
					rightAlign
				/>
			),
			cell: ({ getValue }) => (
				<DataTableNumberCell isInteger value={getValue()} />
			),
			enableSorting: true,
			size: 150,
		}),
		columnHelper.accessor("quantity", {
			header: ({ column }) => (
				<DataTableColumnHeader
					column={column}
					title="Allocated (g)"
					rightAlign
				/>
			),
			cell: ({ row }) => (
				<EditBatchAllocationCell batch={row.original} sasId={sasId} />
			),
			enableSorting: true,
			size: 150,
		}),
		columnHelper.accessor(
			(row) => row.quantity * (row.seedBatch?.costPerGram ?? 0),
			{
				id: "cost",
				header: ({ column }) => (
					<DataTableColumnHeader
						column={column}
						title="Cost"
						rightAlign
					/>
				),
				cell: ({ getValue }) => (
					<DataTableNumberCell value={getValue()} affix="prefix">
						$
					</DataTableNumberCell>
				),
				enableSorting: true,
				size: 150,
			},
		),
	] as ColumnDef<AllocationOrderBatchAllocateDto>[];
};

const PureDUsTooltip = ({
	germinationTest,
	pureDUs,
}: {
	germinationTest: SeedBatchGerminationTestDto | null | undefined;
	pureDUs: number | null | undefined;
}) => {
	return (
		<div className="flex flex-col gap-1">
			<div className="flex items-center gap-1">
				<span className="text-xs text-muted-foreground">
					Pure DUs/g
				</span>
				<span className="text-sm font-semibold">
					{pureDUs?.toLocaleString(undefined, {
						maximumFractionDigits: 1,
					})}
				</span>
			</div>
			<div className="flex items-center gap-1">
				<span className="text-xs text-muted-foreground">
					Seeds Per Gram
				</span>
				<span className="text-sm font-semibold">
					{germinationTest?.seedsPerGram?.toLocaleString(undefined, {
						maximumFractionDigits: 1,
					})}
				</span>
			</div>
			<div className="flex items-center gap-1">
				<span className="text-xs text-muted-foreground">Viability</span>
				<span className="text-sm font-semibold">
					{germinationTest?.viability?.toLocaleString(undefined, {
						style: "percent",
					})}
				</span>
			</div>
			<div className="flex items-center gap-1">
				<span className="text-xs text-muted-foreground">Purity</span>
				<span className="text-sm font-semibold">
					{germinationTest?.purity?.toLocaleString(undefined, {
						style: "percent",
					})}
				</span>
			</div>
		</div>
	);
};

const getPureDUs = (
	germinationTest: SeedBatchGerminationTestDto | null | undefined,
) => {
	if (!germinationTest) return;
	const pureDUs =
		germinationTest.seedsPerGram *
		(germinationTest.viability ?? 1) *
		germinationTest.purity;
	return pureDUs;
};
