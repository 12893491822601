import client from "@kubb/plugin-client/client";
import type { MineSitesDeleteMutationResponse, MineSitesDeletePathParams } from "../../ts/MineSitesDelete.ts";
import type { RequestConfig } from "@kubb/plugin-client/client";

 /**
 * {@link /api/MineSites/:id}
 */
export async function mineSitesDelete(id: MineSitesDeletePathParams["id"], config: Partial<RequestConfig> = {}) {
    const res = await client<MineSitesDeleteMutationResponse, Error, unknown>({ method: "DELETE", url: `/api/MineSites/${id}`, ...config });
    return res.data;
}